import {
  AddressModel,
  ConstructionDiaryState,
  DefectState,
  IssueState,
  LocationModel,
  OrganizationModel,
  PlanSchemaMetadata,
  ResourceTemplateType,
  TeamConfigMetadata,
  WorkpackageState,
} from '@app/api';
import { CheckboxState, ConfigState, DocumentPosition, IndividualPrivilege } from '@app/shared/components';

export interface PartialProjectConfigFormGroupModel {
  general?: Partial<PcfGeneral>;
  metadata?: Partial<PcfMetadata>;
  plan?: Partial<PcfPlan>;
  bim?: Partial<PcfBim>;
  defect?: Partial<PcfDefect>;
  diary?: Partial<PcfDiary>;
  lean?: Partial<PcfLean>;
  roles?: Partial<PcfRoles>;
  msTeams?: Partial<PcfMsTeams>;
  structure?: Partial<PcfStructure>;
}

export interface ProjectConfigFormGroupModel {
  general: PcfGeneral;
  metadata: PcfMetadata;
  plan: PcfPlan;
  bim: PcfBim;
  defect: PcfDefect;
  diary: PcfDiary;
  lean: PcfLean;
  roles: PcfRoles;
  msTeams: PcfMsTeams;
  structure: PcfStructure;
}

export interface PcfPlan {
  selectedSchema: PlanSchemaMetadata;
  qrPosition: DocumentPosition;
  qrPrintEnabled: boolean;
  qrSize: number;
  qrOffsetVertical: number;
  qrOffsetHorizontal: number;
  qrScaling?: boolean;
}

export interface PcfBim extends PcfStatesGroup<IssueState> {
  selectedSchema: PlanSchemaMetadata;
  deadlineNotifications: boolean;
}

export interface PcfDefect extends PcfStatesGroup<DefectState> {
  deadlineNotifications: boolean;
}

export interface PcfDiary extends PcfStatesGroup<ConstructionDiaryState> {
  defaultReportResource: string;
  defaultReportPath: string;
}

export interface PcfLean extends PcfStatesGroup<WorkpackageState> {
  weekCount: string | number;
}

export interface PcfStatesGroup<T> {
  states: ConfigState<T>[];
}

export interface PcfGeneral {
  number: string | number;
  name: string;
  template: TeamConfigMetadata;
  organization: OrganizationModel;
  code: string;
  description: string;
  isBimModuleEnabled: boolean;
  isDefectModuleEnabled: boolean;
  isDiaryModuleEnabled: boolean;
  isGalleryModuleEnabled: boolean;
  isLeanModuleEnabled: boolean;
  isPlanModuleEnabled: boolean;
  isRoomBookModuleEnabled: boolean;
  logo: string;
  logoSmall: string;
  isNameComputed: boolean;
}

export interface PcfMetadata {
  administration: string;
  externalId: string;
  externalSystemName: string;
  start: Date;
  end: Date;
  address: AddressModel;
  location: LocationModel;
  mainClient: OrganizationModel;
}

export interface PcfRoles {
  roles: PcfRole[];
}

export interface PcfMsTeams {
  isTeamPublic: boolean;
  supportSharePointGroupPrivileges: boolean;
}

export interface PcfStructure {
  msTeamsChannels: PcfChannel[];
  portalChannels: PcfChannel[];
}

export interface PcfChannel extends PcfDirectory {
  id: string;
  templateType: ResourceTemplateType;
  // isReadOnly: boolean;
  // channelType: ChannelType;
  isHidden: boolean;
  tabs: PcfChannelTab[];
}

export interface PcfFolder extends PcfDirectory {
  // if this gets an id field then structure-node breaks => see StructureNode.isChannelForm()
  oldPath: string;
}

export interface PcfDirectory {
  path: string;
  folders: PcfFolder[];
  individualPrivileges: IndividualPrivilege[];
}

export interface PcfRole {
  id: string;
  name: string;
  description: string;
  privilegeGroups: PcfPrivilegeGroup[];
  roles: string[];
  users: string[];
  isReadOnly: boolean;
  isHidden: boolean;
  isSystemRole: boolean;
}

export interface PcfPrivilegeGroup {
  privileges: PcfRolePrivilege[];
}

export interface PcfRolePrivilege {
  number: number;
  isSet: CheckboxState;
}

export interface PcfChannelTab {
  displayName: string;
  entityId: string;
  appId: string;
  webSiteUrl: string;
  contentUrl: string;
  hideNavigation: boolean;
}
