import { Directive } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/shared/components';
import { FormState } from './form-state-tracker';
import { FormComponent } from './form-component';

@Directive()
export abstract class FormStateComponent extends FormComponent implements FormState {
  constructor(protected dialog: MatDialog) {
    super();
  }

  get isDirty(): boolean {
    if (!this.form) return false;
    return this.form.dirty;
  }

  async canLeave() {
    if (this.isDirty) {
      const hasConfirmed = await this.getLeaveConfirmation();

      if (hasConfirmed) {
        this.form.markAsPristine();
      }
    }

    return !this.isDirty;
  }

  protected async getLeaveConfirmation() {
    const dialogResult = await this.dialog
      .open(ConfirmDialogComponent, {
        data: { title: 'general.warning', description: 'general.unsavedChangesConfirmation' },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    return dialogResult;
  }
}
