import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-placeholder',
  templateUrl: './icon-placeholder.component.html',
  styleUrls: ['./icon-placeholder.component.scss'],
})
export class IconPlaceholderComponent {
  @Input() icon: string;
  @Input() iconFontSizeRem = 10;
}
