import { Component } from '@angular/core';
import { ProjectConfigService } from '@app/shared/services/project-config';

@Component({
  selector: 'app-metadata-config',
  templateUrl: './metadata-config.component.html',
  styleUrls: ['./metadata-config.component.scss'],
})
export class MetadataConfigComponent {
  constructor(private configService: ProjectConfigService) {}

  get form() {
    return this.configService.metadataForm;
  }

  get isTemplate() {
    return this.configService.isTemplate;
  }

  get isExistingProject() {
    return !this.configService.isTemplate && !this.configService.isNew;
  }

  get projectOrganizations() {
    return this.configService.projectOrganizations;
  }
}
