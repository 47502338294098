import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  LeanPhaseModel,
  LeanWorkpackageSequenceModel,
  LeanWorkpackageSequenceWorkpackageModel,
  LeanWorkpackageTemplateModel,
  TimeSpanModel,
} from '@app/api';
import {
  ApiService,
  CanLeave,
  FormState,
  GlobalFormStateTrackerService,
  TranslationManagementService,
  Utils,
  nameof,
} from '@app/core';
import { WorkpackageTemplatesHelper } from '@app/core/utils/workpackage-templates-helper';
import { LaneModel, overlappingEventSorter } from '@app/shared/components/bryntum-scheduler';
import { ContrastColorPipe } from '@app/shared/pipes';
import { CustomizationService, UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TimeSpanHelper } from '@app/shared/utils/time-span-helper';
import { DragHelper, Model, SchedulerProConfig, ViewPreset } from '@bryntum/schedulerpro';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular';
import * as moment from 'moment';
import {
  PhaseLaneModel,
  SequenceEditorLaneType,
  SequenceLaneModel,
  TemplateEventModel,
  TypedResourceModel,
} from './sequence-editor.models';
import { ConfirmDialogComponent } from '@app/shared/components/dialogs';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

const DUMMY_ID = 'dummy';
const tickWidth = 84;
const tickHeight = 50;
const numberOfWeeks = 50;
const startOfScheduler = '19000101';

@Component({
  selector: 'app-sequence-editor',
  templateUrl: './sequence-editor.component.html',
  styleUrls: ['./sequence-editor.component.scss'],
})
export class SequenceEditorComponent implements OnInit, OnDestroy, CanLeave, FormState {
  @ViewChild('scheduler', { static: true }) scheduler: BryntumSchedulerProComponent;
  private _projectId: string;
  @Input() get projectId(): string {
    return this._projectId;
  }
  set projectId(value: string) {
    this._projectId = value;
    this.updateData();
  }

  get schedulerInstance() {
    return this.scheduler.instance;
  }

  isDragging: boolean;
  isWorkpackageToolbarActive = false;

  mainLoader: Busy = {
    isBusy: true,
  };

  templatesLoader: Busy = {
    isBusy: true,
  };
  workpackagePhases: LeanPhaseModel[] = [];

  public workpackageTemplates: LeanWorkpackageTemplateModel[] = [];
  private eventDragHelper: DragHelper;
  private sequenceDragHelper: DragHelper;
  private phases: LeanPhaseModel[] = [];
  isDirty = false;

  get dropTargetSelector(): string {
    return 'b-grid-row';
  }

  presets: ViewPreset[] = [
    new ViewPreset({
      id: 'calenderWeek',
      name: 'Kalenderwoche',
      tickWidth,
      tickHeight,
      displayDateFormat: 'HH:mm',
      shiftIncrement: 1,
      shiftUnit: 'week',
      mainUnit: 'week',
      timeResolution: {
        unit: 'week',
        increment: 1,
      },
      headers: [
        {
          unit: 'week',
          renderer: this.calendarWeekRenderer.bind(this),
        },
      ],
    }),
    new ViewPreset({
      id: 'day',
      name: 'Tagesansicht',
      tickWidth,
      tickHeight,
      displayDateFormat: 'HH:mm',
      shiftIncrement: 1,
      shiftUnit: 'day',
      mainUnit: 'day',
      timeResolution: {
        unit: 'day',
        increment: 1,
      },
      headers: [
        {
          unit: 'week',
          renderer: this.calendarWeekRenderer.bind(this),
        },
        {
          unit: 'day',
          renderer: (startDate: Date) =>
            this.headerRenderer(
              moment(startDate).weekday() + 1,
              this.translationService.instant('general.date.abbreviationDay')
            ),
        },
      ],
      columnLinesFor: 1,
    }),
  ];

  config: Partial<SchedulerProConfig> = {
    rowHeight: 40,
    barMargin: 0,
    resourceMargin: 5,
    createEventOnDblClick: false,
    multiEventSelect: true,
    startDate: moment(startOfScheduler).toDate(),
    endDate: moment(startOfScheduler).add(numberOfWeeks, 'weeks').toDate(),
    zoomOnTimeAxisDoubleClick: false,
    zoomOnMouseWheel: true,
    zoomKeepsOriginalTimespan: true,
    overlappingEventSorter: overlappingEventSorter,
    resourceStore: {
      sorters: ['name'],
    },

    features: {
      tree: true,
      eventEdit: false,
      scheduleMenu: false,
      taskEdit: false,
      columnRename: false,
      eventDragCreate: false,
      headerZoom: false,
      timeAxisHeaderMenu: false,
      headerMenu: false,
      dependencies: false,
      resourceMenu: false,
      scheduleTooltip: false,
      eventMenu: {
        items: {
          unassignEvent: false,
          editEvent: false,
          copyEvent: false,
          pasteEvent: false,
          cutEvent: false,
          duplicateEvent: false,
          splitEvent: false,
          deleteEvent: {
            text: this.translationService.instant('scheduler.actions.deleteEvent'),
            icon: 'mdi mdi-delete',
            weight: 500, // Add the item to the bottom
          },
        },
      },

      eventDrag: {
        tooltipTemplate: this.tooltipRenderer.bind(this),
      },
      eventTooltip: {
        template: this.tooltipRenderer.bind(this),
      },
    },
    columns: [
      {
        type: 'tree',
        text: 'Phase',
        width: 240,
        field: nameof<LaneModel>('name'),
        cls: 'hide-cell-border',
        cellCls: 'hide-cell-border',
      },
      {
        type: 'action',
        align: 'right',
        text: '',
        actions: [
          {
            cls: 'mdi mdi-plus-box',
            tooltip: this.translationService.instant('workpackageSequences.add'),
            visible: d => {
              const model = d.record as TypedResourceModel;
              return model.type === SequenceEditorLaneType.Phase;
            },
            onClick: d => {
              this.addNewSequence(d.record as PhaseLaneModel);
            },
          },
        ],
      },
    ],

    eventRenderer: ({ eventRecord, renderData }) => {
      const event = eventRecord;
      const cls = [this.contrastColorPipe.transform(event.eventColor, 'class')];

      renderData.eventStyle = 'plain';

      return `<div class="workpackage-label-container ${cls.join(' ')}">
          <span class="label">${event.name}</span>
        </div>`;
    },

    onDataChange: e => {
      this.isDirty = true;
    },
  };

  constructor(
    private contrastColorPipe: ContrastColorPipe,
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private translationService: TranslationManagementService,
    private dialog: MatLegacyDialog,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private customizationService: CustomizationService
  ) {}

  async canLeave() {
    if (this.isDirty) {
      const hasConfirmed = await this.dialog
        .open(ConfirmDialogComponent, {
          data: { title: 'general.warning', description: 'general.unsavedChangesConfirmation' },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      if (!hasConfirmed) {
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    this.globalFormStateTracker.trackFormState(this);
    this.initDrag();
    this.updateData();
  }

  ngAfterViewInit() {
    this.schedulerInstance.features.eventCopyPaste.generateNewName = record => record.name;
  }

  ngOnDestroy(): void {
    this.globalFormStateTracker.untrackFormState(this);
    if (this.sequenceDragHelper != null) this.sequenceDragHelper.destroy();
    if (this.eventDragHelper != null) this.eventDragHelper.destroy();
  }

  toggleTemplatePane() {
    this.isWorkpackageToolbarActive = !this.isWorkpackageToolbarActive;
  }

  async updateData() {
    await this.loadSequences();
    await this.loadWorkpackages();
    this.isDirty = false;
  }

  getSequences() {
    const lanes = this.schedulerInstance.resourceStore.allRecords;
    const phases = lanes.filter((lane: TypedResourceModel) => lane.type === SequenceEditorLaneType.Phase) as PhaseLaneModel[];
    const resultSequences: LeanWorkpackageSequenceModel[] = [];

    for (let phase of phases) {
      if (!Array.isArray(phase.children)) continue;

      const sequences = phase.children.filter(
        (lane: TypedResourceModel) => lane.type === SequenceEditorLaneType.Sequence
      ) as SequenceLaneModel[];

      for (let sequence of sequences) {
        const data = sequence.sequence;

        const templates = sequence.events
          .map(event => event as TemplateEventModel)
          .orderBy(event => event.startDate as Date)
          .map(
            (event, index) =>
              new LeanWorkpackageSequenceWorkpackageModel({
                craftHexColor: event.eventColor,
                templateId: event.templateId,
                start: TimeSpanHelper.getTimeSpanFromDate(event.startDate as Date),
                end: TimeSpanHelper.getTimeSpanFromDate(event.endDate as Date),
                sequence: index,
              })
          );

        this.normalizeSequence(templates);
        let result = new LeanWorkpackageSequenceModel({
          id: data.id,
          name: sequence.name,
          phaseId: phase.phase?.id,
          templates: templates,
          derrivedId: data.derrivedId,
        });

        resultSequences.push(result);
      }
    }

    return resultSequences;
  }

  private headerRenderer(n: number, prefix: string) {
    return `<div class="">${prefix} ${n}<div>`;
  }

  private calendarWeekRenderer(startDate: Date) {
    return this.headerRenderer(moment(startDate).week(), this.translationService.instant('general.date.abbreviationWeek'));
  }

  private tooltipRenderer({ eventRecord }: { eventRecord: TemplateEventModel }) {
    return `<div>
      <div style="font-weight: 500">${eventRecord.name}</div>
    </div>`;
  }

  private initDrag() {
    this.eventDragHelper = new DragHelper({
      cloneTarget: true,
      targetSelector: '.drag-item-for-scheduler',
      dropTargetSelector: `.${this.dropTargetSelector},.b-timeline-subgrid .b-grid-row:not(.b-tree-parent-row),.b-sch-event,.workpackage-drop-target`,
    });

    this.sequenceDragHelper = new DragHelper({
      cloneTarget: true,
      targetSelector: '.b-grid-row:not(.b-tree-parent-row)',
      dropTargetSelector: '.b-grid-row',
    });

    this.eventDragHelper.on({
      dragstart: () => {
        this.isDragging = true;
      },
      drop: ({ context }) => {
        try {
          this.isDragging = false;
          if (!context.valid) return;

          const row = this.schedulerInstance.resolveRowRecord(context.target) as SequenceLaneModel;
          if (Utils.isNullOrUndefined(row.sequence)) return;

          const data = context.grabbed.dataset;
          const template = this.workpackageTemplates.find(t => t.id == data.entityId);
          const dateFromCoordinate = this.schedulerInstance.getDateFromCoordinate(context.clientX, 'floor', false);
          if (Utils.isNullOrUndefined(dateFromCoordinate)) return;

          let start = moment(dateFromCoordinate).startOf('week');
          let end = moment(start).add(1, 'week');

          const newEvent = new TemplateEventModel({
            name: template.name,
            startDate: start.toDate(),
            endDate: end.toDate(),
            eventColor:
              '#' +
              (Utils.isNullOrWhitespace(template.craftHexColor)
                ? this.customizationService.primaryHexColor
                : template.craftHexColor),
            resourceId: row.id,
            templateId: template.id,
          });

          this.schedulerInstance.eventStore.add(newEvent);

          context.finalize();
        } catch (error) {
          // do nothing
        }
      },
    });

    this.sequenceDragHelper.on({
      dragstart: () => {
        this.isDragging = true;
      },
      drop: async ({ context }) => {
        try {
          this.isDragging = false;
          if (!context.valid) return;

          let row: Model = this.schedulerInstance.resolveRowRecord(context.target);
          let phase = (row as PhaseLaneModel).phase;

          if (!phase) {
            row = row.parent;
            phase = (row as PhaseLaneModel).phase;
          }

          const sequence = this.schedulerInstance.resourceStore.getById(context.grabbed.dataset.id) as SequenceLaneModel;
          sequence.parentId = phase?.id ?? DUMMY_ID;
        } catch (error) {
          // do nothing
        }
      },
    });
  }

  private async loadSequences() {
    await using(new BusyScope(this.mainLoader), async _ => {
      const [phases, sequences] = await Promise.all([
        await Utils.enhanceException(this.apiService.getPhases(), 'phases'),
        await Utils.enhanceException(this.apiService.getSequences(), 'sequences'),
      ]);

      this.phases = phases;

      const dummyLane = new PhaseLaneModel({
        id: DUMMY_ID,
        name: this.translationService.instant('workpackageTemplates.dummyPhase'),
        expanded: true,
        readOnly: true,
        children: sequences
          .filter(sequence => Utils.isNullOrUndefined(sequence.phaseId))
          .map(sequence => new SequenceLaneModel({ id: sequence.id, name: sequence.name, sequence: sequence })),
      });
      const lanes = [dummyLane];

      lanes.push(
        ...phases.map(
          phase =>
            new PhaseLaneModel({
              id: phase.id,
              name: phase.name,
              expanded: true,
              readOnly: true,
              phase: phase,
              children: sequences
                .filter(s => s.phaseId === phase.id)
                .map(
                  s =>
                    new SequenceLaneModel({
                      id: s.id,
                      name: s.name,
                      sequence: s,
                    })
                ),
            })
        )
      );

      const events = sequences
        .map(sequence =>
          sequence.templates.map(t => {
            return new TemplateEventModel({
              templateId: t.templateId,
              name: t.name,
              startDate: TimeSpanHelper.getDateFromModel(t.start).toDate(),
              endDate: TimeSpanHelper.getDateFromModel(t.end).toDate(),
              craftName: t.craftName,
              eventColor:
                '#' + (Utils.isNullOrWhitespace(t.craftHexColor) ? this.customizationService.primaryHexColor : t.craftHexColor),
              resourceId: sequence.id,
            });
          })
        )
        .flat();

      await this.schedulerInstance.resourceStore.loadDataAsync(lanes);

      this.schedulerInstance.eventStore.removeAll();
      events.forEach(e => this.schedulerInstance.eventStore.add(e));
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + (e.translationKey ?? 'workpackageSequences'),
        e
      );
    });
  }

  private async loadWorkpackages() {
    await using(new BusyScope(this.templatesLoader), async _ => {
      const templates = await this.apiService.getWorkpackageTemplates();

      this.workpackageTemplates = WorkpackageTemplatesHelper.getSortedTemplates(templates);
      this.workpackagePhases = WorkpackageTemplatesHelper.getAvailablePhases(templates, this.phases);
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + (e.translationKey ?? 'workpackageTemplates'),
        e
      );
    });
  }

  private addNewSequence(phase: PhaseLaneModel) {
    const sequence = new SequenceLaneModel({
      sequence: new LeanWorkpackageSequenceModel({
        name: '',
        phaseId: phase?.phase?.id,
        templates: [],
      }),
      parentId: phase?.id ?? DUMMY_ID,
    });

    phase.insertChild(sequence);
    this.schedulerInstance.startEditing(sequence);
  }

  private normalizeSequence(items: LeanWorkpackageSequenceWorkpackageModel[]) {
    let offset: TimeSpanModel = new TimeSpanModel({
      days: -1,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    let offsetSeconds = -1;

    items.forEach(item => {
      if (offsetSeconds === -1 || TimeSpanHelper.getTotalSecondsFromTimeSpan(item.start) < offsetSeconds) {
        offset = new TimeSpanModel(item.start);
        offsetSeconds = TimeSpanHelper.getTotalSecondsFromTimeSpan(item.start);
      }
    });

    if (offsetSeconds === -1) return;

    items.forEach(item => {
      item.start.days -= offset.days;
      item.start.hours -= offset.hours;
      item.start.minutes -= offset.minutes;
      item.start.seconds -= offset.seconds;

      item.end.days -= offset.days;
      item.end.hours -= offset.hours;
      item.end.minutes -= offset.minutes;
      item.end.seconds -= offset.seconds;
    });
  }
}
