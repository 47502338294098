import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  IGeoJSONFeatureCollection,
  LeanPhaseModel,
  LeanSimulationFeaturesRequest,
  LeanSimulationPlan,
  LeanSimulationPlansRequest,
  ProjectModel,
} from '@app/api';
import { ApiService, ApiUrlService, BaseSubscriptionComponent, ProjectService, Utils } from '@app/core';
import { FileExtension } from '@app/core/enumerations';
import { LongRunningTaskService, UserNotificationService, Week } from '@app/shared/services';
import { PlanConversionStatus, PlanSource, convertPlan } from '@app/shared/services/long-running-tasks/convertPlan';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { delay } from '@app/shared/utils/functions';
import jsPDF from 'jspdf';
import * as L from 'leaflet';
import * as moment from 'moment';
import { BehaviorSubject, Observable, filter, take } from 'rxjs';
import { LeafletPlansPageCombiner } from '../geojson-editor/LeafletPlansPageCombiner';
import { GeojsonEditorComponent } from '../geojson-editor/geojson-editor.component';

export interface IGeoJSONEditorPrintDialogData {
  projectId: string;

  week: Week;
  phases: LeanPhaseModel[];
}

interface PrintProgress {
  progress: number;
  step: string;
  params?: any;
}

@Component({
  selector: 'app-geojson-print-dialog',
  templateUrl: './geojson-print-dialog.component.html',
  styleUrls: ['./geojson-print-dialog.component.scss'],
})
export class GeojsonPrintDialogComponent extends BaseSubscriptionComponent implements OnInit, Busy {
  public selectedPlanConversion$: Observable<PlanConversionStatus | null>;
  public selectedPlanMarkers$ = new BehaviorSubject<IGeoJSONFeatureCollection | null>(null);
  public selectedPlan$ = new BehaviorSubject<PlanSource | null>(null);
  public startWeek$: BehaviorSubject<Week>;
  public endWeek$: BehaviorSubject<Week>;
  public phases: LeanPhaseModel[] = [];

  public status$ = new BehaviorSubject<PrintProgress>(null);
  public isBusy = false;

  private currentProject: ProjectModel;
  private selectedPhase: LeanPhaseModel;

  @ViewChildren(GeojsonEditorComponent) editorQueryList: QueryList<GeojsonEditorComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IGeoJSONEditorPrintDialogData,
    private dialogRef: MatDialogRef<GeojsonPrintDialogComponent>,
    private apiService: ApiService,
    private apiUrlService: ApiUrlService,
    private projectService: ProjectService,
    private userNotification: UserNotificationService,
    lrTaskService: LongRunningTaskService
  ) {
    super();

    dialogRef.disableClose = true;

    this.selectedPlanConversion$ = this.selectedPlan$.pipe(convertPlan(apiService, apiUrlService, lrTaskService));

    const week = Utils.getWeek(data.week.startOf);

    this.endWeek$ = new BehaviorSubject(week);
    this.startWeek$ = new BehaviorSubject(week);
    this.phases = data.phases ?? [];
  }

  ngOnInit(): void {
    this.subscribe(this.projectService.projectId$, async projectId => {
      this.currentProject = null;

      if (projectId) {
        this.currentProject = new ProjectModel({ id: projectId });

        await using(new BusyScope(this), async _ => {
          this.currentProject = await this.apiService.getProject(projectId);
        }).catch(e => {
          this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.project', e);
          this.dialogRef.close();
        });
      }
    });
  }

  fireClose() {
    this.dialogRef.close();
  }

  fireWeekChange(obs: BehaviorSubject<Week>, count: number) {
    const week = obs.value;
    const m = moment(week.startOf);

    m.add(count, 'week');

    const next = Utils.getWeek(m);

    obs.next(next);

    if (obs == this.endWeek$ && next.startOf < this.startWeek$.value.startOf) {
      this.startWeek$.next(next);
    } else if (obs == this.startWeek$ && this.endWeek$.value.startOf < next.startOf) {
      this.endWeek$.next(next);
    }
  }

  firePhaseChange(phase: LeanPhaseModel) {
    this.selectedPhase = phase;
  }

  async fireExport() {
    await using(new BusyScope(this), async _ => {
      var untilWeek = this.endWeek$.value;
      var startWeek = this.startWeek$.value;

      this.status$.next({
        progress: -1,
        step: 'dialogs.simulation.export.stepWaitingOnLeaflet',
        params: {
          week: startWeek.number,
          year: startWeek.startOf.year(),
        },
      });

      const project = await this.apiService.getProject(this.data.projectId);

      const jspdf = new jsPDF({
        format: 'a3',
        orientation: 'landscape',
      });

      var combiner = new LeafletPlansPageCombiner(jspdf);

      try {
        await this.createPagesForPlans(startWeek, untilWeek, combiner, project);

        combiner.addStep();

        this.status$.next({
          progress: 100,
          step: 'dialogs.simulation.export.stepSave',
        });

        await delay(250); // progress update in ui

        const filename = Utils.getFileName(this.currentProject?.name, 'Simulation', FileExtension.PDF);

        jspdf.save(filename);

        this.dialogRef.close();
      } catch ($err) {
        console.error($err);

        this.userNotification.notify('dialogs.simulation.export.stepError', { error: $err });

        this.status$.next(null);
      }
    });
  }

  private async createPagesForPlans(
    startWeek: Week,
    untilWeek: Week,
    combiner: LeafletPlansPageCombiner,
    project: ProjectModel
  ) {
    const fullTimeRangeRequest = new LeanSimulationPlansRequest({
      endDate: untilWeek.endOf.toLocalDate(),
      startDate: startWeek.startOf.toLocalDate(),

      phaseIds: this.selectedPhase ? [this.selectedPhase.id] : [],
    });

    const fullTimeRange = await this.apiService.getSimulationPlans(fullTimeRangeRequest).toPromise();

    // fullTimeRangeRequest.withGeoJson = true;

    const numberOfPlans = fullTimeRange.plans.length;
    const numberOfWeeksBetween = untilWeek.number - startWeek.number;

    const numberOfSteps = (numberOfWeeksBetween + 1) * 2 * numberOfPlans;

    combiner.setStepCount(numberOfSteps + 1);

    for (let i = startWeek.number; i <= startWeek.number + numberOfWeeksBetween; i++) {
      const currentWeek = Utils.getWeek(startWeek.startOf.clone().week(i));

      for (const simulationPlan of fullTimeRange.plans) {
        combiner.addPlan();

        const editorPromise = new Promise<GeojsonEditorComponent>(resolve => {
          this.editorQueryList.changes
            .pipe(
              filter((list: QueryList<GeojsonEditorComponent>) => list.length > 0),
              take(1)
            )
            .subscribe((list: QueryList<GeojsonEditorComponent>) => {
              resolve(list.first);
            });
        });

        this.selectedPlan$.next({
          projectId: project.id,
          metadataId: simulationPlan.driveItemMetadataId,
        });

        const editor = await editorPromise;
        combiner.addStep();

        this.status$.next({
          progress: combiner.getStepProgress(),
          step: 'dialogs.simulation.export.stepLoadingWeek',
          params: {
            plan: numberOfPlans - (combiner.getPlan() % numberOfPlans),
            week: currentWeek.number,
            year: currentWeek.endOf.year(),
          },
        });

        const endDate = moment(currentWeek.endOf);
        const startDate = moment(currentWeek.startOf);

        fullTimeRangeRequest.endDate = endDate.toLocalDate();
        fullTimeRangeRequest.startDate = startDate.toLocalDate();

        const week = currentWeek.number;
        const year = currentWeek.startOf.year();

        const snapshotArea = await this.apiService.simulationGetSnapshot(simulationPlan.driveItemMetadataId, year, week);

        if (snapshotArea != null) {
          const bounds = L.latLngBounds([snapshotArea.a.lat, snapshotArea.a.lng], [snapshotArea.b.lat, snapshotArea.b.lng]);
          editor.fitBounds(bounds);
        } else {
          combiner.addStep();

          continue; // skip with week, no snapshot defined
        }

        await delay(250);

        combiner.addStep();

        this.status$.next({
          progress: combiner.getStepProgress(),
          step: 'dialogs.simulation.export.stepRenderingWeek',
          params: {
            plan: numberOfPlans - (combiner.getPlan() % numberOfPlans),
            week: currentWeek.number,
            year: currentWeek.endOf.year(),
          },
        });

        const currentPlans = await this.apiService.getSimulationPlans(fullTimeRangeRequest).toPromise();
        const currentPlan = currentPlans.plans?.find(plan => plan.driveItemMetadataId === simulationPlan.driveItemMetadataId);
        if (currentPlan != null) {
          const featureCollection = await this.apiService.getSimulationPlanFeatures(
            new LeanSimulationFeaturesRequest({
              planId: currentPlan.driveItemMetadataId,
              startDate: currentWeek.startOf.toLocalDate(),
              endDate: currentWeek.endOf.toLocalDate(),
            })
          );
          this.selectedPlanMarkers$.next(featureCollection);
        } else {
          this.selectedPlanMarkers$.next(null);

          continue; // skip with week, no snapshot defined
        }

        await delay(250);

        const target = document.querySelector('#simulation-print-source') as HTMLDivElement;

        await combiner.addPage(target, currentWeek, project);
      }
    }
  }
}
