import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input } from '@angular/core';
import { PlanListComponent } from '../plan-list/plan-list.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService, AppRoutingData, BaseSubscriptionComponent, ProjectService, Utils, pathFragmentsTo } from '@app/core';
import { CustomViewTabService } from '@app/shared/services';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { TranslateService } from '@ngx-translate/core';
import {
  DriveItemIdentifierModel,
  MetadataDriveItemModel,
  ModuleType,
  PrivilegeEnum,
  ProjectModel,
  PropertyBagType,
  ShareDriveModel,
  TextFormatType,
} from '@app/api';
import { PlanSchemaDialogComponent } from '../plan-schema-dialog/plan-schema-dialog.component';
import { Router } from '@angular/router';
import { FileExtension } from '@app/core/enumerations';
import { LocalizedDateFormat, LocalizedDatePipe } from '@app/shared/pipes';
import { MailNotificationDialogComponent } from '../../dialogs/mail-notification-dialog/mail-notification-dialog.component';
import { GridComponent, GridViewManager } from '../../grid';
import { FilterMetadata } from 'primeng/api';

interface HeaderData {
  label?: string;
  icon?: string;
}

@Component({
  selector: 'app-plan-parent',
  templateUrl: './plan-parent.component.html',
  styleUrls: ['./plan-parent.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanParentComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() moduleType: ModuleType;
  ModuleType = ModuleType;
  label: string;
  icon: string;

  @ViewChild(PlanListComponent) plan: PlanListComponent;
  @ViewChild('planningModule', { static: true }) modulePage: ElementRef;
  schemaAtrribute: string = 'planschema';
  appBody: any;
  bodyAttribute: string;
  currentProject: ProjectModel;
  addFileAllowed: boolean = false;
  arePlansSelected: boolean = false;
  canDownload: boolean = false;
  canSharePrivilege: boolean = false;
  canCompare: boolean = false;
  toggleState: boolean = true;
  isFilterSet: boolean = false;
  isBusy: boolean = false;

  constructor(
    private apiService: ApiService,
    private datePipe: LocalizedDatePipe,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private tabService: CustomViewTabService,
    private translateService: TranslateService,
    private router: Router
  ) {
    super();
  }

  private _selectedItems: any[];
  get selectedItems(): any[] {
    return this._selectedItems;
  }

  set selectedItems(value: any[]) {
    this._selectedItems = value;
    this.arePlansSelected = value && value.length > 0;
    this.canCompare = value && value.length == 2;
  }

  private get headerData(): HeaderData {
    const moduleType = this.moduleType;

    switch (moduleType) {
      case ModuleType.Bim:
        return { label: this.getLabel(moduleType), icon: AppRoutingData.ifc.icon };
      case ModuleType.Plan:
        return { label: this.getLabel(moduleType), icon: AppRoutingData.planning.icon };
      default:
        return {};
    }
  }

  private get propertyBagType(): PropertyBagType {
    switch (this.moduleType) {
      case ModuleType.Bim:
        return PropertyBagType.IfcViews;
      case ModuleType.Plan:
        return PropertyBagType.PlanViews;
      default:
        return null;
    }
  }

  async ngOnInit() {
    const headerData = this.headerData;
    this.label = headerData.label;
    this.icon = headerData.icon;

    this.tabService.updateDefaultTab(
      CustomViewTabService.getUpdateDataSort<MetadataDriveItemModel>('lastModifiedDateTime', true)
    );

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.currentProject = projectId ? await this.apiService.getProject(projectId) : null;

      const privileges = projectId ? await this.apiService.getUserPrivileges() : [];
      if (this.moduleType == ModuleType.Plan) {
        this.canDownload = privileges.indexOf(PrivilegeEnum.ReadPlan) >= 0;
        this.canSharePrivilege = privileges.indexOf(PrivilegeEnum.PlanShareMember) >= 0;
      } else if (this.moduleType == ModuleType.Bim) {
        this.canDownload = privileges.indexOf(PrivilegeEnum.ReadWriteRestrictedBim) >= 0;
        this.canSharePrivilege = privileges.indexOf(PrivilegeEnum.BimShareMember) >= 0;
      }

      const propertyBagType = this.propertyBagType;
      if (propertyBagType) await this.tabService.loadViews(propertyBagType, new GridViewManager(this));
    });
  }

  get grid(): GridComponent {
    return this.plan.list;
  }

  gridFilterChanged(filters: Record<string, FilterMetadata>) {
    this.isFilterSet = Object.keys(filters).length > 0;
  }

  async toggleView(viewAll: boolean) {
    this.label = this.getLabel(this.moduleType, viewAll);
    await this.plan.toggleShownPlans(viewAll);
  }

  syncToggleBtn(toggleState: boolean) {
    this.toggleState = toggleState;
    this.label = this.getLabel(this.moduleType, !toggleState);
  }

  saveView() {
    this.tabService.createOrEditView();
  }

  clearFilter() {
    this.grid.filterTable({});
  }

  async showSchema() {
    this.appBody = document.getElementById('APP_Body');
    this.bodyAttribute = this.appBody.getAttribute('page');
    this.appBody.setAttribute('page', this.schemaAtrribute);
    const dialogResult = await this.dialog
      .open(PlanSchemaDialogComponent, { data: { plan: this.plan.planDef, seperator: this.plan.seperator } })
      .afterClosed()
      .toPromise();
    this.appBody.setAttribute('page', this.bodyAttribute);
  }

  async exportCsv() {
    const [listHead, listData] = this.getRefinedGridData();
    if (!listData) return;
    const csv = [];
    let row = [];
    listHead.forEach(h => {
      row.push(h.header);
    });
    csv.push(row);
    listData.forEach(d => {
      row = [];
      listHead.forEach(h => {
        row.push(d[h.dataKey]);
      });
      csv.push(row);
    });
    let csvresult = '';
    csv.forEach(r => {
      csvresult += r.join(';') + '\r\n';
    });
    const filename = Utils.getFileName(
      this.currentProject?.name,
      (this.moduleType == ModuleType.Bim ? 'Ifc' : '') + 'Plans',
      FileExtension.CSV
    );
    await Utils.saveCSVLocal(csvresult, filename);
  }

  async exportPdf() {
    const [listHead, listData] = this.getRefinedGridData();
    if (!listData) return;
    const cstyle: any = {};
    // header filename date etc.:
    const date = new Date().getTime();
    const headerDate = this.datePipe.transform(date, LocalizedDateFormat.short);
    const name = this.currentProject?.name ?? this.tabService.activeTab?.label;
    const filename = Utils.getFileName(
      this.currentProject?.name,
      (this.moduleType == ModuleType.Bim ? 'Ifc' : '') + 'Plans',
      FileExtension.PDF
    );
    const pdfTitle = this.translateService.instant('planning.titlePdf', { subject: name, datetime: headerDate }); // `${headerPrefix} ${name} ${headerOpt} ${headerDate} ${headerSuffix}`;
    const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: [297, 210] });
    autoTable(doc, {
      margin: { top: 13, bottom: 10, left: 10, right: 10 },
      tableWidth: 277,
      body: listData,
      columns: listHead,
      styles: { cellPadding: 0.5, fontSize: 6, overflow: 'linebreak' }, //ellipsize
      columnStyles: cstyle,
      didDrawPage: function (data) {
        doc.setFontSize(8);
        doc.setTextColor(0);
        doc.text(pdfTitle, 10, 10);
      },
    });
    await Utils.saveJsPDFLocal(doc, filename);
  }

  async zipUrl() {
    const selectedPlans = [];
    const count = this.plan.planData.data.length;
    for (let i = 0; i < count; i++) {
      const plan = this.plan.planData.data[i];
      if (plan.selected) {
        selectedPlans.push(plan);
      }
    }
    if (await this.plan.generateZipDownloadUrl(selectedPlans.map(plan => plan.id))) {
      this.plan.deselectAll();
    }
  }

  async shareSelected() {
    const selectedRowIds = [];
    const selectedRowNames = [];
    const count = this.plan.planData.data.length;
    for (let i = 0; i < count; i++) {
      const f = this.plan.planData.data[i];
      if (f.selected) {
        selectedRowNames.push(f.name);
        selectedRowIds.push(
          new DriveItemIdentifierModel({
            driveItemId: f.id,
            resource: f.resourceIdentifier.key.name,
          })
        );
      }
    }

    const sendNotification = async model => {
      return await this.apiService.shareFileNotifaction(
        new ShareDriveModel({
          to: model.to,
          message: model.message ?? '',
          subject: model.subject ?? '',
          format: TextFormatType.Text,
          items: selectedRowIds,
        })
      );
    };

    await this.dialog
      .open(MailNotificationDialogComponent, {
        data: {
          itemNames: selectedRowNames,
          userEvent: true,
          isCustomMailAddressSupported: true,
          sendNotification,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();
  }

  compareSelected() {
    this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.planning.path, 'viewer'), {
      queryParams: { planId: this.selectedItems[0].id, compareId: this.selectedItems[1].id },
    });
  }

  updateHeaderState(addFileAllowed: boolean) {
    this.addFileAllowed = addFileAllowed;
  }

  refineForPrint(data: any[]) {
    let result = data.map(x => ({ ...x }));
    this.plan.list.gridDefModel.cols.forEach((col, index) => {
      if (col.pipe) {
        result.forEach(row => {
          row[col.field] = col.pipeArg ? col.pipe.transform(row[col.field], col.pipeArg) : col.pipe.transform(row[col.field]);
        });
      }
      if (col.isRange) {
        result.forEach(row => {
          row[col.field] = row[Utils.rangePrefix + col.field];
        });
      }
    });
    return result;
  }

  private getRefinedGridData(removeSuffix: boolean = false) {
    const hidecol = ['select', 'contextmenu'];
    const usedCols = [];
    this.plan.list.gridDefModel.cols.forEach(c => {
      if (!c.hidden) usedCols.push(c);
    });
    const listHead = usedCols.map(col => ({
      header: col.noTranslate ? col.header : this.translateService.instant('grid.' + col.header),
      dataKey: col.field,
      cw: col.absWidth,
    }));

    if (removeSuffix) listHead.shift(); // remove 1(suffix) entry

    let currentData = [];
    if (this.plan.planData.dataReady) {
      currentData =
        this.plan.list.dataTable.filteredValue && this.plan.list.dataTable.filteredValue.length > 0
          ? this.plan.list.dataTable.filteredValue
          : this.plan.list.source;
    }

    return [
      listHead.filter(l => hidecol.indexOf(l.dataKey) === -1 && l.header),
      !currentData || currentData.length < 1 ? null : this.refineForPrint(currentData),
    ];
  }

  private getLabel(moduleType: ModuleType, viewAll: boolean = false): string {
    switch (moduleType) {
      case ModuleType.Bim:
        return viewAll ? 'ifc.header.overallView' : 'ifc.header.latestView';
      case ModuleType.Plan:
        return viewAll ? 'planning.header.overallView' : 'planning.header.latestView';
      default:
        return '';
    }
  }
}
