import { Directory } from '@capacitor/filesystem';
import { Injectable, OnDestroy } from '@angular/core';
import {
  AddOrUpdateDefectModel,
  AreaModel,
  C4ApiFilterDefinition,
  ChangeSetModel,
  CommentModel,
  ConfigType,
  ConflictBehavior,
  CraftModel,
  ProjectDefectClient,
  DefectModel,
  DefectReasonModel,
  DefectTypeModel,
  DriveActionMetadata,
  DriveClientExtended,
  DriveItemModel,
  DriveItemPrivilegeModel,
  DriveItemType,
  DriveUploadResultModel,
  FileParameter,
  FilePreviewSpec,
  FileResponse,
  FloorModel,
  IdentityModel,
  IdentitySetModel,
  OrganizationModel,
  PlanSchemaDefinition,
  PlanSchemaModel,
  PropertyBagModel,
  RoomModel,
  SettingsModel,
  SyncAreaModel,
  SyncClient,
  SyncProjectOrganizationCraftModel,
  SyncProjectOrganizationModel,
  SyncProjectUserModel,
  SyncCraftModel,
  SyncDefectModel,
  SyncDefectTypeModel,
  SyncDriveItemModel,
  SyncFloorModel,
  SyncOrganizationModel,
  SyncReasonModel,
  SyncRegionModel,
  SyncRoomModel,
  SyncSchemaModel,
  SyncUserModel,
  TeamRole,
  UserModel,
  SyncDefectUserAssignmentModel,
  PrivilegeEnum,
  ProjectModel,
  SyncProjectModel,
  RegionModel,
  ModuleType,
  ResourceIdentifier,
  ISyncDefectModel,
  ZoneGroupModel,
  SyncZoneGroupModel,
  ZoneModel,
  SyncZoneModel,
  DefectStatePermission,
  TenantInfoModel,
  CustomizationModel,
  UserSessionModel,
  GeoJSONFeatureCollection,
  GeoJSONRelationType,
  GeoJSONType,
  MetadataDriveItemsModel,
  ProjectUserModel,
} from '@app/api';
import { MigrationStatus, SQLiteTables } from './sqlite/utils';
import { BehaviorSubject, catchError, interval, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { DBFilter } from './SQLStatements';
import { HttpClient } from '@angular/common/http';
import {
  getProjectMetadataKey,
  IPSComment,
  IPSDefect,
  IPSDefectComment,
  IPSDriveItem,
  IPSMetadata,
  IPSRoom,
  IPSZone,
  MetadataKey,
} from './definitions';
import { OfflineStatusManager } from './offline-status-manager';
import { OfflineServiceFileHandler } from './OfflineServiceFileHandler';
import { ActivityStepError } from './stepWithProgress';
import { IQueryWrapper, SQLiteQueryWrapper } from './offline-query-wrapper';
import { Http } from '@capacitor-community-http';
import { Utils } from '@app/core/utils';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApiUrlService, DriveItemsResult } from '../api';
import { ProjectService } from '../globals/project.service';
import { LogService } from '../log/log.service';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { ConnectionStatus, Network } from '@capacitor/network';
import { getDirectoryPath, getResourceIdentifiers, OfflineSyncService, safeDate, SyncEngine } from './offline-sync-service';
import { environment } from '@env/environment';
import { AppConfigService } from '../app-config';

const IS_DEBUG_OFFLINE_KEY = 'isDebugOffline';
const USE_DEBUG_OFFLINE = true;

@Injectable({
  providedIn: 'root',
})
export class OfflineService implements OnDestroy {
  static OriginalSuffix: string = '/Original.bin';

  private statusManager = new OfflineStatusManager();
  isOffline$ = this.statusManager.isOffline$.asObservable();
  projectStatus$ = this.statusManager.projectStatus$.asObservable();
  projectSynced$ = new Observable<string>();

  private syncService: OfflineSyncService;
  private syncEngine: SyncEngine;

  private get projectId() {
    return this.projectService.projectId$.value;
  }

  private get database(): IQueryWrapper {
    return this.syncEngine.database;
  }

  constructor(
    private api: SyncClient,
    private apiUrlService: ApiUrlService,
    private logService: LogService,
    public httpClient: HttpClient,
    public driveClient: DriveClientExtended,
    public defectClient: ProjectDefectClient,
    public authenticationService: AuthenticationService,
    public projectService: ProjectService
  ) {
    this.syncEngine = {
      api,
      apiUrlService,
      authenticationService,
      defectClient,
      driveClient,
      database: new SQLiteQueryWrapper(api, logService),
      fileHandler: new OfflineServiceFileHandler(),
    };

    this.initialize();
  }

  ngOnDestroy(): void {
    this.syncService?.dispose();
  }

  get offlineModules() {
    return [ModuleType.Defect];
  }

  get isOffline() {
    return this.statusManager.isOffline$.value;
  }

  isProjectOffline(projectId: string = this.projectId) {
    return this.statusManager.isOffline(projectId);
  }

  private stopHealthCheck = new Subject<void>();
  async switchOfflineDueToError() {
    this.stopHealthCheck.next();
    await this.offlineStateChanged(true);

    interval(1000 * AppConfigService.settings.offline.healthCheckIntervalInSeconds)
      .pipe(
        takeUntil(this.stopHealthCheck),
        switchMap(() =>
          this.httpClient.get(`${this.apiUrlService.apiUrl}/health`).pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
          )
        )
      )
      .subscribe(hasInternetAccess => {
        if (!hasInternetAccess) return;

        this.stopHealthCheck.next();
        this.offlineStateChanged(false);
      });
  }

  async isAutoSyncEnabled(projectId: string) {
    return this.syncService?.isAutoSyncEnabled(projectId) ?? false;
  }

  async enableSync(projectId: string) {
    return await this.syncService.enableAutoSync(projectId);
  }

  async disableSync(projectId: string) {
    return await this.syncService.disableAutoSync(projectId);
  }

  async syncProject(projectId: string) {
    return await this.syncService.syncProject(projectId);
  }

  async syncAutoSyncedProjects() {
    await this.syncService.syncAutoSyncedProjects();
  }

  async getSyncErrors(): Promise<ActivityStepError[]> {
    const data = await this.database.getSyncErrors(this.projectId);
    const result = new Array<ActivityStepError>();

    for (const item of data) {
      const parameters: ActivityStepError = JSON.parse(item.parameters);

      result.push(parameters);
    }

    return result;
  }

  goOffline(projectId = this.projectId) {
    return this.syncService.createStepsForProjectDownload(projectId);
  }

  goOnline(projectId = this.projectId) {
    return this.syncService.createStepsForProjectUpload(projectId);
  }

  async recovery(projectId: string = this.projectId, reset = true, complete = false) {
    try {
      const recovery = await this.api.createRecovery(projectId).toPromise();
      const api = `${this.apiUrlService.apiUrl}/projects/${projectId}/sync/recovery/${recovery.key}`;
      const token = `Bearer ${await this.authenticationService.getCurrentAccessToken()}`;

      // complete = false;

      var filter: DBFilter | false = false;
      if (!complete) {
        filter = {};
        filter['mode'] = [MigrationStatus.INSERT, MigrationStatus.UPDATE];
        filter['project'] = projectId;
      }

      const dump = await this.database.dump(filter);

      for (const entity of await this.database.getDriveItemsWhereMode(MigrationStatus.INSERT, projectId)) {
        const dir = getDirectoryPath(entity.project, entity.resource, entity.path, entity.name);

        await Http.uploadFile({
          url: api,
          name: dir,
          filePath: dir + OfflineService.OriginalSuffix,
          fileDirectory: Directory.Data,
          headers: {
            Authorization: token,
            Resource: entity.resource,
          },
        });
      }

      const request = new XMLHttpRequest();
      const form = new FormData();
      const blob = new Blob([JSON.stringify(dump)]);

      form.set('SQLite.json', blob, 'SQLite.json');

      request.open('POST', api);
      request.setRequestHeader('Authorization', token);
      request.send(form);

      if (reset) {
        await this.database.resetProject(projectId);

        await this.database.setProjectOfflineStatus(projectId, false);

        await this.syncService.updateProjectStatesFromDatabase();
      }
      
      await this.syncService.disableAutoSync(projectId, false);

      return recovery.key;
    } catch ($err) {
      this.logService.error('Recovery failed', $err);
      return null;
    }
  }

  createStepsForProjectSync(projectId = this.projectId) {
    return this.syncService.createStepsForProjectSync(projectId);
  }

  async getPreview(id: string, projectId: string, spec: FilePreviewSpec, resource: string): Promise<FileResponse> {
    await this.throwIfOfflineNotSupported(projectId);

    const results = await this.database.getDriveItemsWhereResourceAndId(projectId, resource, id);
    const fileHandler = this.syncEngine.fileHandler;

    for (const file of results) {
      try {
        const directory = getDirectoryPath(projectId, resource, file.path, file.name);

        const buffer = await fileHandler.readFile(directory + '/' + spec + '.bin', Directory.Data);
        const blob = new Blob([buffer], { type: file.mimeType });

        return {
          data: blob,
          status: 200,
        };
      } catch ($err) {
        console.error($err);
      }
    }

    throw new Error('Unable to find');
  }

  async getResourceIdentifiers(projectId: string): Promise<ResourceIdentifier[]> {
    await this.throwIfOfflineNotSupported(projectId);

    return getResourceIdentifiers(projectId, this.database);
  }

  async getAvailableProjects() {
    await this.throwIfOfflineNotSupported();

    return Promise.all(this.statusManager.offlineProjectIds.map(id => this.getProjectById(id)));
  }

  async getProjectById(projectId: string): Promise<ProjectModel> {
    await this.throwIfOfflineNotSupported(projectId);

    const project = await this.database.getProjectById(projectId);
    return this.mapProject(project.json);
  }

  async getDriveItems(
    projectId: string,
    path: string,
    flatten: boolean,
    searchText: string,
    resource: string,
    metadata: DriveActionMetadata,
    filter: C4ApiFilterDefinition
  ): Promise<DriveItemsResult> {
    await this.throwIfOfflineNotSupported(projectId);

    const query = ['SELECT * FROM driveItems WHERE'];
    const queryParameters = [] as Array<any>;

    query.push(`project LIKE ?`);
    queryParameters.push(projectId);

    query.push(`AND resource LIKE ?`);
    queryParameters.push(resource);

    if (flatten) {
      query.push('AND path LIKE ? AND type LIKE ?');
      queryParameters.push('/' + path + '%');
      queryParameters.push(DriveItemType.File);
    } else {
      query.push('AND path LIKE ?');
      queryParameters.push('/' + path);
    }

    if (metadata?.relatedEntityId) {
      query.push(`AND relatedEntityId LIKE ?`);
      queryParameters.push(metadata?.relatedEntityId);
    }

    if (searchText) {
      searchText = searchText.replace(/is:([A-Z]+)/i, ($0, $1) => {
        switch ($1.toLowerCase()) {
          case 'file':
            query.push('AND type LIKE ?');
            queryParameters.push(DriveItemType.File);
            return '';

          case 'folder':
            query.push('AND type LIKE ?');
            queryParameters.push(DriveItemType.Folder);
            return '';

          default:
            throw new Error('Query Type not found');
        }
      });

      if (searchText.length > 0) {
        query.push('AND name LIKE ?');
        queryParameters.push('%' + searchText + '%');
      }
    }

    query.push('ORDER BY name DESC');

    if (filter) {
      const limit = filter.top || filter.totalCount || 0;
      const offset = 0;

      if (limit) {
        query.push('LIMIT ?');
        queryParameters.push(limit);

        if (offset) {
          query.push('OFFSET ?');
          queryParameters.push(offset);
        }
      }
    }

    const items = new Array<SyncDriveItemModel>();

    const wrapper = <SQLiteQueryWrapper>this.database; // dirty hack
    const results = await wrapper.query(query.join(' '), ...queryParameters);

    for (const o of results) {
      const syncModel = JSON.parse(o.json) as SyncDriveItemModel;
      const model = new DriveItemModel(syncModel);

      model.privilege = new DriveItemPrivilegeModel({
        readContent: true,
      });

      items.push(model);
    }

    const resourceIdentifiers = await this.getResourceIdentifiers(this.projectId);
    const addFileAllowed = resourceIdentifiers.some(
      i => i.key.name == resource && [ModuleType.Plan, ModuleType.Defect].contains(i.moduleType)
    );

    return {
      items: items,

      addFileAllowed,
      addFolderAllowed: false,
    };
  }

  async uploadDriveItem(
    projectId: string,
    fileName: string,
    fileData: any,
    filePath: string,
    existingNameBehavior: ConflictBehavior,
    resource: string,
    metadata: DriveActionMetadata,
    progressCallback: (progressPercent: number) => void
  ) {
    await this.throwIfOfflineNotSupported(projectId);

    const userId = await this.authenticationService.getUserId();
    const fileId = Utils.createUUID();

    const fileHandler = this.syncEngine.fileHandler;
    const arrayBuffer = await new Promise<ArrayBuffer>(resolve => {
      const fileReader = new FileReader();

      fileReader.onload = e => {
        const data = e.target.result as ArrayBuffer;

        resolve(data);
      };

      fileReader.readAsArrayBuffer(fileData);
    });

    const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
    const resourceIdentifier = resourceIdentifiers.find(r => r.key.name == resource);

    const me = new IdentitySetModel({ user: new IdentityModel({ id: userId }) });
    const directory = getDirectoryPath(projectId, resourceIdentifier.key.name, filePath, fileName);

    const data = new Uint8Array(arrayBuffer);
    const date = new Date();

    await fileHandler.saveFile(directory + OfflineService.OriginalSuffix, Directory.Data, data);

    const driveItem = new DriveItemModel({
      id: fileId,
      name: fileName,
      path: '/' + filePath,
      size: fileData.size,
      type: DriveItemType.File,
      mimeType: fileData.type,

      createdBy: me,
      createdDateTime: date,

      lastModifiedBy: me,
      lastModifiedDateTime: date,

      resourceIdentifier,
    });

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSDriveItem>(SQLiteTables.DriveItems, {
      id: driveItem.id,
      project: projectId,
      relatedEntityId: metadata?.relatedEntityId,

      type: driveItem.type,
      path: driveItem.path,
      name: driveItem.name,
      mimeType: driveItem.mimeType,
      resource: driveItem.resourceIdentifier.key.name,

      json: JSON.stringify(driveItem),
      mode: MigrationStatus.INSERT,
      deleted: false,
      modifiedOn: driveItem.lastModifiedDateTime,
    });

    if (fileData.type.startsWith('image/')) {
      try {
        await this.createPreviews(fileHandler, directory, fileData);
      } catch ($err) {
        this.logService.error('failed to convert image', $err);
      }
    }

    await statements.execute();

    return new DriveUploadResultModel({
      fileId: fileId,
      fileName: fileName,
    });
  }

  private async createPreviews(fileHandler: OfflineServiceFileHandler, directory: string, fileData: any) {
    const sizes = [
      { size: 800, type: FilePreviewSpec.Preview },
      { size: 80, type: FilePreviewSpec.Thumbnail },
    ];

    const image = new Image();

    await new Promise((res, rej) => {
      image.onerror = async (evt, source, lineNr, columnNr, error) => {
        // Logging in next, try & catch
        rej(error);
      };

      image.onload = async () => {
        try {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          const w = image.naturalWidth;
          const h = image.naturalHeight;

          for (const s of sizes) {
            if (w > h) {
              const f = h / w;
              canvas.width = s.size * 1;
              canvas.height = s.size * f;
            } else {
              const f = w / h;
              canvas.width = s.size * f;
              canvas.height = s.size * 1;
            }

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            const blob = await new Promise<Blob>(res => canvas.toBlob(res, 'image/png'));

            const arrayBuffer = await blob.arrayBuffer();

            const data = new Uint8Array(arrayBuffer);

            await fileHandler.saveFile(directory + '/' + s.type + '.bin', Directory.Data, data);
          }

          canvas.remove();

          res(true);
        } catch ($err) {
          // Logging in next, try & catch
          rej($err);
        }
      };

      image.src = URL.createObjectURL(fileData);
    });

    URL.revokeObjectURL(image.src);

    image.remove();
  }

  async getViews(projectId: string): Promise<PropertyBagModel[]> {
    await this.throwIfOfflineNotSupported(projectId);

    return [];
  }

  async getOrganizations(): Promise<OrganizationModel[]> {
    await this.throwIfOfflineNotSupported();

    const orgs = [];

    for (const o of await this.database.getOrganizations(this.projectId)) {
      const syncModel = JSON.parse(o.json) as SyncOrganizationModel;
      const model = new OrganizationModel({
        address: syncModel.address,
        name: syncModel.name,
        id: syncModel.id,
        isPartOfProject: syncModel.isPartOfProject,
      });

      orgs.push(model);
    }

    return orgs;
  }

  async getCustomizations(): Promise<CustomizationModel> {
    await this.throwIfOfflineNotSupported();

    const metadata = await this.database.getMetadata(MetadataKey.customizations);

    if (!metadata) throw new Error('CUSTOMIZATIONS NOT FOUND IN DATABASE');

    return CustomizationModel.fromJS(JSON.parse(metadata));
  }

  async setCustomizations(customizations: CustomizationModel): Promise<void> {
    await this.throwIfOfflineNotSupported();

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
      name: MetadataKey.customizations,
      data: JSON.stringify(customizations),
    });

    statements.execute();
  }

  async getTenantInfo(): Promise<TenantInfoModel> {
    await this.throwIfOfflineNotSupported();

    const metadata = await this.database.getMetadata(MetadataKey.tenantInfo);

    if (!metadata) throw new Error('TENANT INFO NOT FOUND IN DATABASE');

    return TenantInfoModel.fromJS(JSON.parse(metadata));
  }

  async setTenantInfo(tenantInfo: TenantInfoModel): Promise<void> {
    await this.throwIfOfflineNotSupported();

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
      name: MetadataKey.tenantInfo,
      data: JSON.stringify(tenantInfo),
    });

    statements.execute();
  }

  async getUserPrivileges(): Promise<PrivilegeEnum[]> {
    await this.throwIfOfflineNotSupported();

    const metadata = await this.database.getMetadata(MetadataKey.userPrivileges);

    return !metadata ? [] : JSON.parse(metadata);
  }

  async setUserPrivileges(privileges: PrivilegeEnum[]): Promise<void> {
    await this.throwIfOfflineNotSupported();

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
      name: MetadataKey.userPrivileges,
      data: JSON.stringify(privileges),
    });

    statements.execute();
  }

  async getUserSession(): Promise<UserSessionModel> {
    await this.throwIfOfflineNotSupported();

    const metadata = await this.database.getMetadata(MetadataKey.userSession);

    if (!metadata) throw new Error('USER SESSION NOT FOUND IN DATABASE');

    return UserSessionModel.fromJS(JSON.parse(metadata));
  }

  async setUserSession(userSession: UserSessionModel): Promise<void> {
    await this.throwIfOfflineNotSupported();

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
      name: MetadataKey.userSession,
      data: JSON.stringify(userSession),
    });

    statements.execute();
  }

  async getTenantSettings(): Promise<SettingsModel> {
    await this.throwIfOfflineNotSupported();

    const metadata = await this.database.getMetadata(MetadataKey.tenantSettings);

    if (!metadata) throw new Error('TENANT SETTINGS NOT FOUND IN DATABASE');

    return new SettingsModel(JSON.parse(metadata));
  }

  async getPlanSchemaForProject(projectId: string, resource: string): Promise<PlanSchemaDefinition> {
    await this.throwIfOfflineNotSupported(projectId);

    const type = await this.getConfigForResource(resource);
    const schema = await this.database.getSchemaWhereType(projectId, type);

    if (!schema) throw new Error('Unable to find schema');

    const model: PlanSchemaModel = JSON.parse(schema.json);

    return model.definition;
  }

  async getMetadataDriveItems(projectId: string, resource: string): Promise<MetadataDriveItemsModel> {
    await this.throwIfOfflineNotSupported(projectId);
    // todo implement with offline data when plans are supported
    return new MetadataDriveItemsModel({ items: [], addFileAllowed: false });
  }

  async getGeoJson(
    metadataId: string,
    projectId: string,
    relatedType?: GeoJSONRelationType,
    relatedId?: string
  ): Promise<GeoJSONFeatureCollection> {
    await this.throwIfOfflineNotSupported(projectId);
    // todo implement with offline data when plans are supported
    return new GeoJSONFeatureCollection({
      features: [],
      isEmpty: true,
      type: GeoJSONType.FeatureCollection,
    });
  }

  async getRolesForProject(projectId: string): Promise<TeamRole[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const obj = await this.database.getObjectById<SyncSchemaModel>(SQLiteTables.Schema, {
      type: 'Definition',
      project: projectId,
    });

    return obj.team.definition.roles;
  }

  async getUserPrivilegesForProject(projectId: string): Promise<PrivilegeEnum[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const rows = await this.database.getUserPrivilegesForProject(projectId);

    return rows.map(r => r.id as PrivilegeEnum);
  }

  async getUserForProject(projectId: string): Promise<ProjectUserModel[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const rows = await this.database.getProjectUsersWithUser(projectId);

    return rows
      .map(projectUserWithUser => {
        const projectUser: SyncProjectUserModel = JSON.parse(projectUserWithUser.json);
        const user: SyncUserModel = JSON.parse(projectUserWithUser.userJson);

        return {
          projectUser,
          user,
        };
      })
      .filter(({ projectUser }) => !projectUser.isImpersonateAssignment)
      .map(({ projectUser, user }) => ProjectUserModel.fromJS({ roles: [], projectUser, user }));
  }

  async getUserForOrganisationInProject(organizationId: string, projectId: string): Promise<UserModel[]> {
    return (await this.getUserForProject(projectId))
      .map(projectUser => projectUser.user)
      .filter(user => user.organizationId == organizationId);
  }

  async editDefectComment(projectId: string, comment: CommentModel) {
    await this.throwIfOfflineNotSupported(projectId);

    const userId = await this.authenticationService.getUserId();
    const modifiedOn = new Date();

    const row = await this.database.getCommentById(comment.id);

    const mode = row.mode == MigrationStatus.INSERT ? MigrationStatus.INSERT : MigrationStatus.UPDATE;

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSComment>(SQLiteTables.Comments, {
      id: comment.id,
      project: projectId,

      text: comment.text,
      editorId: userId,

      mode: mode,
      modifiedOn: modifiedOn,
      deleted: false,
    });

    statements.execute();
  }

  async saveDefect(projectId: string, defect: AddOrUpdateDefectModel, files: FileParameter[]): Promise<string> {
    await this.throwIfOfflineNotSupported(projectId);

    const userId = await this.authenticationService.getUserId();
    const defectId = defect.id || Utils.createUUID();
    const modifiedOn = new Date();

    let oldDefect: SyncDefectModel;
    if (defect.id) {
      oldDefect = await this.database.getObjectById<SyncDefectModel>(SQLiteTables.Defects, defect.id);
    } else {
      const emptyDefect = await this.createEmptyDefect(projectId);
      oldDefect = SyncDefectModel.fromJS({
        ...emptyDefect,
        modifiedOn: modifiedOn,
        createdOn: modifiedOn,
        createdId: userId,
        changeSets: [],
        number: 0,
      });
    }

    const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
    const resource = resourceIdentifiers.find(i => i.moduleType === ModuleType.Defect).key.name;

    const userAssignments = defect.userAssignment.map(x => new SyncDefectUserAssignmentModel({ userId: x.id }));

    const syncModelChanges = (oldDefect.changeSets || []).map(ChangeSetModel.fromJS);

    const newState =
      defect.stateId == oldDefect.currentState.key
        ? oldDefect.currentState
        : oldDefect.allowedStates.find(state => state.key == defect.stateId);

    const syncModel: ISyncDefectModel = {
      id: defectId,
      number: oldDefect.number,
      createdId: oldDefect.createdId,
      allowedPermissions: oldDefect.allowedPermissions ?? [DefectStatePermission.Defect_edit_basic],
      changeSets: syncModelChanges,
      userAssignments: userAssignments,
      zonesIds: defect.zones,

      createdOn: safeDate(oldDefect.createdOn),
      modifiedOn: modifiedOn,
      graceExpiredMailCreatedOn: safeDate(oldDefect.graceExpiredMailCreatedOn),
      deadlineExpiredMailCreatedOn: safeDate(oldDefect.deadlineExpiredMailCreatedOn),
      deadlineReminderMailCreatedOn: safeDate(oldDefect.deadlineReminderMailCreatedOn),

      stateId: newState.key,
      stateType: newState.stateType,
      currentState: newState,
      allowedStates: oldDefect.allowedStates,

      areaId: defect.areaId,
      regionId: defect.regionId,
      roomId: defect.roomId,
      typeId: defect.typeId,
      craftId: defect.craftId,
      floorId: defect.floorId,
      reasonId: defect.reasonId,
      organizationId: defect.organizationId,
      complainerUserId: defect.complainerUserId,
      complainerOrganizationId: defect.complainerOrganizationId,

      title: defect.title,
      description: defect.description,

      reduction: defect.reduction,
      retention: defect.retention,
      grace: safeDate(defect.grace),
      deadline: safeDate(defect.deadline),
      approvalExtern: safeDate(defect.approvalExtern),
      approvalIntern: safeDate(defect.approvalIntern),
      approvalOfBuilder: safeDate(defect.approvalOfBuilder),
      defectImageMetadataId: defect.defectImageMetadataId,

      authorId: userId,
      isDeleted: false,
    };

    const mode = syncModel.number ? MigrationStatus.UPDATE : MigrationStatus.INSERT;

    const statements = await this.database.getStatements();

    statements.insertOrReplace<IPSDefect>(SQLiteTables.Defects, {
      id: defectId,
      project: projectId,

      json: JSON.stringify(SyncDefectModel.fromJS(syncModel)),
      mode: mode,
      deleted: false,
      modifiedOn: modifiedOn,
    });

    for (const file of files) {
      this.uploadDriveItem(
        projectId,
        file.fileName,
        file.data,
        defectId,
        ConflictBehavior.Fail,
        resource,
        DriveActionMetadata.fromJS({
          relatedEntityId: defectId,
        }),
        null
      );
    }

    for (const comment of defect.comments || []) {
      const mode = comment.id ? MigrationStatus.UPDATE : MigrationStatus.INSERT;
      const commentId = comment.id || Utils.createUUID();
      const modifiedOn = comment.modifiedOn || new Date();

      statements.insertOrReplace<IPSComment>(SQLiteTables.Comments, {
        id: commentId,
        project: projectId,

        text: comment.text,
        editorId: userId,

        mode: mode,
        deleted: false,
        modifiedOn: modifiedOn,
      });

      if (mode == MigrationStatus.INSERT) {
        statements.insertOrReplace<IPSDefectComment>(SQLiteTables.DefectComments, {
          id: Utils.createUUID(),
          project: projectId,

          defectId: defectId,
          commentId: commentId,

          mode: mode,
          deleted: false,
          modifiedOn: modifiedOn,
        });
      }
    }

    statements.execute();

    return syncModel.id;
  }

  async getDefectsForProject(projectId: string): Promise<DefectModel[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const defects: DefectModel[] = [];

    const d = await this.database.getDefects(projectId);

    for (const o of d) {
      const syncModel: SyncDefectModel = JSON.parse(o.json);
      const model = DefectModel.fromJS(syncModel);

      await this.prepareDefectModel(model, syncModel);

      defects.push(model);
    }

    await this.loadNavigationProps(defects);

    return defects;
  }

  private async loadNavigationProps(defects: DefectModel[]) {
    const comments = await this.database.getCommentsWithUserWhereDefects(defects.map(d => d.id));

    const rooms = await this.database.getObjectsByIds<SyncRoomModel>(
      SQLiteTables.Rooms,
      defects.map(d => d.roomId)
    );
    const areas = await this.database.getObjectsByIds<SyncAreaModel>(
      SQLiteTables.Areas,
      defects.map(d => d.areaId)
    );
    const floors = await this.database.getObjectsByIds<SyncFloorModel>(
      SQLiteTables.Floors,
      defects.map(d => d.floorId)
    );
    const crafts = await this.database.getObjectsByIds<SyncCraftModel>(
      SQLiteTables.Crafts,
      defects.map(d => d.craftId)
    );
    const organizations = await this.database.getObjectsByIds<SyncOrganizationModel>(
      SQLiteTables.Organizations,
      defects.flatMap(d => [d.organizationId, d.complainerOrganizationId])
    );
    const regions = await this.database.getObjectsByIds<SyncRegionModel>(
      SQLiteTables.Regions,
      defects.map(d => d.regionId)
    );
    const users = await this.database.getObjectsByIds<SyncUserModel>(
      SQLiteTables.Users,
      defects.flatMap(d => [d.authorId, d.complainerUserId])
    );
    const types = await this.database.getObjectsByIds<SyncDefectTypeModel>(
      SQLiteTables.DefectTypes,
      defects.map(d => d.typeId)
    );
    const reasons = await this.database.getObjectsByIds<SyncReasonModel>(
      SQLiteTables.DefectReasons,
      defects.map(d => d.reasonId)
    );

    for (const defect of defects) {
      const room = rooms.find(r => r.id == defect.roomId);
      if (room) {
        defect.roomName = room.title;
        defect.roomNumber = room.internalNumber;
      }
      const area = areas.find(a => a.id == defect.areaId);
      if (area) {
        defect.areaName = area.title;
      }
      const floor = floors.find(f => f.id == defect.floorId);
      if (floor) {
        defect.floorName = floor.title;
      }
      const craft = crafts.find(c => c.id == defect.craftId);
      if (craft) {
        defect.craftName = craft.title;
      }
      const organization = organizations.find(c => c.id == defect.organizationId);
      if (organization) {
        defect.organization = new OrganizationModel({
          name: organization.name,
          address: organization.address,
          isPartOfProject: organization.isPartOfProject,
          id: organization.id,
        });
      }
      const region = regions.find(r => r.id == defect.regionId);
      if (region) {
        defect.regionName = region.title;
      }
      const creator = users.find(c => c.id == defect.authorId);
      if (creator) {
        defect.createdBy = creator.username;
      }
      const type = types.find(t => t.id == defect.typeId);
      if (type) {
        defect.typeName = type.name;
      }
      const reason = reasons.find(r => r.id == defect.reasonId);
      if (reason) {
        defect.reasonName = reason.name;
      }
      const complainerUser = users.find(r => r.id == defect.complainerUserId);
      if (complainerUser) {
        defect.complainerUserName = complainerUser.username;
      }
      const complainerOrganization = organizations.find(r => r.id == defect.complainerOrganizationId);
      if (complainerOrganization) {
        defect.complainerOrganizationName = complainerOrganization.name;
      }

      defect.comments = comments.map(x => {
        const jsonUser = JSON.parse(x.jsonUser);
        return new CommentModel({
          modifiedOn: new Date(x.modifiedOn),
          createdBy: jsonUser.displayUsername,
          createdOn: new Date(x.modifiedOn),
          editorId: x.editorId,
          text: x.text,
          id: x.id,
        });
      });
    }
  }

  private async prepareDefectModel(model: DefectModel, syncModel: SyncDefectModel) {
    const userAssignments = syncModel.userAssignments || [];
    model.assignedUsers = userAssignments.map(x => {
      return new UserModel({
        id: x.userId,
      });
    });
  }

  async createEmptyDefect(projectId: string): Promise<DefectModel> {
    await this.throwIfOfflineNotSupported(projectId);

    const key = getProjectMetadataKey(MetadataKey.emptyDefect, projectId);
    const metadata = await this.database.getMetadata(key);

    if (!metadata) throw new Error('EMPTY DEFECT NOT FOUND IN DATABASE');

    return await this.mapDefectModel(JSON.parse(metadata), projectId);
  }

  async getDefect(id: string, projectId: string): Promise<DefectModel> {
    await this.throwIfOfflineNotSupported(projectId);

    const defect = await this.database.getDefectById(id);

    if (!defect) return null;

    const syncModel: SyncDefectModel = JSON.parse(defect.json);

    return await this.mapDefectModel(syncModel, projectId);
  }

  private async mapDefectModel(syncModel: SyncDefectModel, projectId: string): Promise<DefectModel> {
    let zones = [];
    if (syncModel.zonesIds?.length) {
      const allZones = await this.getZonesForProject(projectId);
      zones = this.mapZoneIds(syncModel.zonesIds, allZones);
    }

    const model: DefectModel = DefectModel.fromJS({ ...syncModel, zones });

    await this.prepareDefectModel(model, syncModel);

    await this.loadNavigationProps([model]);

    return model;
  }

  async getDefectTypes(): Promise<DefectTypeModel[]> {
    await this.throwIfOfflineNotSupported();

    const types = new Array<DefectTypeModel>();

    // todo these are global and shouldn't be saved per project
    for (const o of await this.database.getDefectTypes(this.projectId)) {
      const syncModel: SyncDefectTypeModel = JSON.parse(o.json);
      const model = new DefectTypeModel({
        id: syncModel.id,

        name: syncModel.name,
        code: syncModel.code,
        hexColor: syncModel.hexColor,
        isDefault: syncModel.isDefault,
      });

      types.push(model);
    }

    return types;
  }

  async getDefectReasons(): Promise<DefectReasonModel[]> {
    await this.throwIfOfflineNotSupported();

    const crafts = new Array<DefectReasonModel>();

    // todo these are global and shouldn't be saved per project
    for (const o of await this.database.getDefectReasons(this.projectId)) {
      const syncModel: SyncReasonModel = JSON.parse(o.json);
      const model = new DefectReasonModel({
        id: syncModel.id,

        name: syncModel.name,
        code: syncModel.code,
      });

      crafts.push(model);
    }

    return crafts;
  }

  async getCrafts(): Promise<CraftModel[]> {
    await this.throwIfOfflineNotSupported();

    const crafts = new Array<CraftModel>();
    for (const o of await this.database.getCrafts()) {
      const syncModel: SyncCraftModel = JSON.parse(o.json);
      const model = new CraftModel({
        name: syncModel.title,
        id: syncModel.id,
        isPartOfProject: syncModel.isPartOfProject,
      });

      crafts.push(model);
    }

    return crafts;
  }

  async getAreasForProject(projectId: string): Promise<AreaModel[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const areas = new Array<AreaModel>();
    const zones = await this.getZonesForProject(projectId);

    for (const o of await this.database.getAreas(projectId)) {
      const syncModel: SyncAreaModel = JSON.parse(o.json);

      const model = new AreaModel({
        id: syncModel.id,
        name: syncModel.title,
        zones: this.mapZoneIds(syncModel.zonesIds, zones),

        floors: await this.getFloorsByArea(syncModel.id, { [syncModel.id]: syncModel }, zones),
        regions: await this.getRegionsByArea(syncModel.id),
      });

      areas.push(model);
    }

    return areas;
  }

  async getOrganizationsForProject(projectId: string): Promise<OrganizationModel[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const models = new Array<OrganizationModel>();
    for (const o of await this.database.getProjectOrganizations(projectId)) {
      const syncModel: SyncProjectOrganizationModel = JSON.parse(o.json);

      const organization = await this.database.getObjectById<SyncOrganizationModel>(
        SQLiteTables.Organizations,
        syncModel.organizationId
      );

      const model = new OrganizationModel({
        id: organization.id,

        name: organization.name,
        address: organization.address,

        isPartOfProject: syncModel.isPartOfProject,

        crafts: await this.getCraftsByProjectOrganization(o.id),
      });

      models.push(model);
    }

    return models;
  }

  async getRoomById(roomId: string, projectId: string): Promise<RoomModel> {
    await this.throwIfOfflineNotSupported(projectId);

    const roomDtos = await this.database.getRoomsWhereId(roomId);
    const rooms = await this.mapRooms(roomDtos);

    return rooms.pop();
  }

  private async mapRooms(
    roomDtos: IPSRoom[],
    areas: Record<string, SyncAreaModel> = {},
    floors: Record<string, SyncFloorModel> = {},
    zones: ZoneModel[] = null
  ) {
    const models = new Array<RoomModel>();

    zones = zones ?? (await this.getZonesForProject(roomDtos[0].project));

    for (const o of roomDtos) {
      const syncModel: SyncRoomModel = JSON.parse(o.json);

      const floorId = syncModel.floorId;
      if (floors[floorId] == null) {
        const floorDto = await this.database.getFloorWhereId(floorId);
        floors[floorId] = JSON.parse(floorDto.json);
      }

      const areaId = floors[floorId].areaId;
      if (areas[areaId] == null) {
        const areaDto = await this.database.getAreaWhereId(areaId);
        areas[areaId] = JSON.parse(areaDto.json);
      }

      const model = new RoomModel({
        id: syncModel.id,
        name: syncModel.title,
        regionId: syncModel.regionId,

        areaId,
        floorId,
        internalNumber: syncModel.internalNumber,

        zones: [
          ...this.mapZoneIds(areas[areaId].zonesIds, zones, true),
          ...this.mapZoneIds(floors[floorId].zonesIds, zones, true),
          ...this.mapZoneIds(syncModel.zonesIds, zones),
        ],
      });

      const index = models.findIndex(m => m.internalNumber > model.internalNumber);

      models.splice(index < 0 ? models.length : index, 0, model);
    }

    return models;
  }

  private async getFloorsByArea(
    areaId: string,
    areas: Record<string, SyncAreaModel>,
    zones: ZoneModel[]
  ): Promise<FloorModel[]> {
    const models = new Array<FloorModel>();

    for (const o of await this.database.getFloorsWhereArea(areaId)) {
      const syncModel: SyncFloorModel = JSON.parse(o.json);

      const model = new FloorModel({
        id: syncModel.id,
        name: syncModel.title,

        rooms: await this.getRoomsByFloor(syncModel.id, areas, { [syncModel.id]: syncModel }, zones),
        zones: this.mapZoneIds(syncModel.zonesIds, zones),

        areaId: areaId,
        regionId: syncModel.regionId,
      });

      models.push(model);
    }

    return models;
  }

  private async getRegionsByArea(areaId: string): Promise<RegionModel[]> {
    const models = new Array<RegionModel>();

    for (const o of await this.database.getRegionsWhereArea(areaId)) {
      const syncModel: SyncRegionModel = JSON.parse(o.json);

      const model = new RegionModel({
        id: syncModel.id,
        name: syncModel.title,

        areaId: areaId,
      });

      models.push(model);
    }

    return models;
  }

  private async getRoomsByFloor(
    floorId: string,
    areas: Record<string, SyncAreaModel>,
    floors: Record<string, SyncFloorModel>,
    zones: ZoneModel[]
  ): Promise<RoomModel[]> {
    const roomDtos = await this.database.getRoomsWhereFloor(floorId);
    return await this.mapRooms(roomDtos, areas, floors, zones);
  }

  private async getCraftsByProjectOrganization(projectOrganizationId: string): Promise<CraftModel[]> {
    const models = new Array<CraftModel>();

    for (const o of await this.database.getProjectOrganizationCrafts(projectOrganizationId)) {
      const syncModel: SyncProjectOrganizationCraftModel = JSON.parse(o.json);

      const craft = await this.database.getObjectById<SyncCraftModel>(SQLiteTables.Crafts, syncModel.craftId);

      const model = new CraftModel({
        id: craft.id,
        name: craft.title,
        isPartOfProject: craft.isPartOfProject,
      });

      models.push(model);
    }

    return models;
  }

  async getZoneGroupsForProject(projectId: string): Promise<ZoneGroupModel[]> {
    await this.throwIfOfflineNotSupported(projectId);

    const zoneGroups = new Array<ZoneGroupModel>();

    for (const o of await this.database.getZoneGroups(projectId)) {
      const syncModel: SyncZoneGroupModel = JSON.parse(o.json);

      const model = new ZoneGroupModel({
        id: syncModel.id,
        name: syncModel.name,

        zones: await this.getZonesByZoneGroup(syncModel.id),
      });

      zoneGroups.push(model);
    }

    return zoneGroups;
  }

  async getZonesForProject(projectId: string): Promise<ZoneModel[]> {
    await this.throwIfOfflineNotSupported(projectId);
    const zoneDtos = await this.database.getZones(this.projectId);
    return this.mapZones(zoneDtos);
  }

  private mapZones(zoneDtos: IPSZone[]) {
    const zones = new Array<ZoneModel>();

    for (const o of zoneDtos) {
      const syncModel: SyncZoneModel = JSON.parse(o.json);

      const model = new ZoneModel({
        id: syncModel.id,
        name: syncModel.name,

        zoneGroupId: syncModel.zoneGroupId,
      });

      zones.push(model);
    }

    return zones;
  }

  private async getZonesByZoneGroup(zoneGroupId: string): Promise<ZoneModel[]> {
    const zoneDtos = await this.database.getZonesWhereZoneGroup(zoneGroupId);
    return this.mapZones(zoneDtos);
  }

  private async getConfigForResource(resource: string) {
    const resoureIdentifiers = await this.getResourceIdentifiers(this.projectId);
    const module = resoureIdentifiers.find(i => i.key.name == resource).moduleType;

    switch (module) {
      case ModuleType.Bim:
        return ConfigType.BimSchema;
      case ModuleType.Plan:
        return ConfigType.PlanSchema;
      default:
        throw new Error('Unable to find resource: ' + module);
    }
  }

  private mapProject(json: string) {
    const model: SyncProjectModel = JSON.parse(json);

    return new ProjectModel({
      id: model.id,
      name: model.name,
      number: model.number,
      externalId: model.externalId,
    });
  }

  private mapZoneIds(zonesIds: string[], zones: ZoneModel[], isImplicit: boolean = false) {
    return zonesIds.reduce((foundZones: ZoneModel[], id) => {
      const zone = zones.find(z => z.id == id);
      if (zone) foundZones.push(new ZoneModel({ ...zone, isImplicit }));
      return foundZones;
    }, []);
  }

  private initialize() {
    if (CapacitorUtils.isApp()) {
      this.syncService = new OfflineSyncService(this.statusManager, this.syncEngine);
      this.projectSynced$ = this.syncService.projectSynced$.asObservable();

      if (this.useDebugOffline) {
        this.isDebugOffline$.subscribe(async isDebugOffline => {
          this.offlineStateChanged(isDebugOffline);
        });
      } else {
        Network.addListener('networkStatusChange', this.setNetworkStatus.bind(this));
        Network.getStatus().then(this.setNetworkStatus.bind(this));
      }

      this.syncService.updateProjectStatesFromDatabase();
    }
  }

  private async setNetworkStatus(status: ConnectionStatus) {
    await this.offlineStateChanged(status.connectionType === 'none');
  }

  private async offlineStateChanged(isOffline: boolean) {
    if (!CapacitorUtils.isApp()) throw 'Offline only supported in native app';

    if (isOffline) await this.syncService.setAutoSyncedProjectsOffline();
    else await this.syncService.setAutoSyncedProjectsOnline();

    this.statusManager.isOffline$.next(isOffline);
  }

  private canBeOffline() {
    return CapacitorUtils.isApp();
  }

  private async canProjectBeOffline(projectId: string) {
    return (
      this.canBeOffline() &&
      (this.isProjectOffline(projectId) ||
        ((await this.isAutoSyncEnabled(projectId)) && (await this.hasProjectData(projectId))))
    );
  }

  async hasProjectData(projectId: string): Promise<boolean> {
    return await this.syncService.hasProjectData(projectId);
  }

  private async throwIfOfflineNotSupported(projectId?: string) {
    const canBeOffline = !projectId ? this.canBeOffline() : await this.canProjectBeOffline(projectId);
    if (!canBeOffline) throw new Error('OFFLINE NOT SUPPORTED');
  }

  // #region DEBUG

  readonly useDebugOffline = !environment.production && USE_DEBUG_OFFLINE;
  readonly isDebugOffline$ = new BehaviorSubject(localStorage.getItem(IS_DEBUG_OFFLINE_KEY) === 'true');
  toggleDebugOffline(goOffline = !this.isDebugOffline$.value) {
    localStorage.setItem(IS_DEBUG_OFFLINE_KEY, `${goOffline}`);
    this.isDebugOffline$.next(goOffline);
  }

  // #endregion
}
