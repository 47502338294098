import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ConstructionDiaryModel,
  ConstructionDiaryState,
  ConstructionDiaryStatePermission,
  ConstructionDiaryStateProjectEntityState,
} from '@app/api';
import {
  AppRoutingData,
  BaseSubscriptionComponent,
  DataHolder,
  GlobalsService,
  nameof,
  pathFragmentsTo,
  ProjectService,
} from '@app/core';
import { Busy } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import { C4GridDef, C4GridFilterType, C4GridMatchMode, C4GridSelectOptions, GridComponent, GridSelectionMode } from '../grid';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LocalizedDatePipe, NumberFormatPipe } from '@app/shared/pipes';

interface GridDiaryModel {
  id: string;
  number: string;
  state: ConstructionDiaryState;
  currentState?: ConstructionDiaryStateProjectEntityState;
  assigned?: string;
  dayOfWeek?: number;
  weekNumber?: number;
  crafts?: string;
  organizations?: string;
  regions?: string;
  selected?: boolean;
  canWrite?: boolean;
}

export enum ConstructionDiaryListMode {
  default = 'default',
  singleSelectIntegrated = 'singleSelectIntegrated',
}

@Component({
  selector: 'app-diary-list',
  templateUrl: './diary-list.component.html',
  styleUrls: ['./diary-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DiaryListComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy, Busy {
  @ViewChild('stateTemplate', { static: true }) stateTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('contextMenuTemplate', { static: true }) contextTemplate: TemplateRef<any>;
  @ViewChild('selectAllTemplate', { static: true }) selectAllTemplate: TemplateRef<any>;
  @ViewChild('selectRowTemplate', { static: true }) selectRowTemplate: TemplateRef<any>;
  @ViewChild('assignedTemplate', { static: true }) assignedTemplate: TemplateRef<any>;
  @ViewChild(GridComponent) grid: GridComponent;

  // Grid Selection in Item Action Dialog
  private _selection: any;
  get selection(): any {
    return this._selection;
  }
  @Input() set selection(value: any) {
    this._selection = value;
    this.selectionChange.emit(value);
  }
  @Output() selectionChange = new EventEmitter<any>();
  @Output() createItemClicked = new EventEmitter<void>();
  @Output() copyItemClicked = new EventEmitter<string>();
  @Output() generateReportClicked = new EventEmitter<string>();
  // Selection via Checkmarks
  @Output() customSelectionChange = new EventEmitter<GridDiaryModel[]>();

  @Input() rows: number = 10;
  @Input() rowsOptions: number[] = [5, 10, 20, 50, 100];
  @Input() mode: ConstructionDiaryListMode = ConstructionDiaryListMode.default;
  @Input() set diaries(value: ConstructionDiaryModel[]) {
    this.allDiaries = value;
    if (this.isInitialized) this.updateData();
  }
  @Input() canEditDiary: boolean;
  @Input() isBusy: boolean;

  selectionMode: GridSelectionMode = GridSelectionMode.none;
  visibleOverviewFields: string[] = [];
  gridDef: C4GridDef;
  diaryHolder: DataHolder<GridDiaryModel[]>;
  allDiaries: ConstructionDiaryModel[];

  private isInitialized: boolean = false;
  private diaryStates: C4GridSelectOptions[] = [];

  constructor(
    private datePipe: LocalizedDatePipe,
    private numberFormatPipe: NumberFormatPipe,
    private projectService: ProjectService,
    private router: Router,
    private translate: TranslateService,
    public globals: GlobalsService
  ) {
    super();
  }

  async ngOnInit() {
    this.selectionMode =
      this.mode == ConstructionDiaryListMode.singleSelectIntegrated ? GridSelectionMode.single : GridSelectionMode.none;
    this.diaryHolder = new DataHolder(this.initData());
    this.gridDef = this.getGridDef();
    this.visibleOverviewFields = [];
    this.gridDef.cols.forEach(c => {
      if (!c.hidden) this.visibleOverviewFields.push(c.field);
    });
    if (this.allDiaries) this.updateData();
    this.isInitialized = true;
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }

  public async updateData() {
    await this.diaryHolder?.updateData(async () => {
      const result = this.getGridData(this.allDiaries);

      const options = this.allDiaries.reduce(
        (options, diary) => {
          options.states[diary.currentState.title] = diary.currentState;
          return options;
        },
        { states: {} }
      );

      const gridOptionMapper = ([label, value]) => ({
        label,
        value,
      });

      this.diaryStates.splice(0, this.diaryStates.length, ...Object.entries(options.states).map(gridOptionMapper));

      this.gridDef.grid.rowCount = result?.length ?? 0;
      this.customSelectionChange.emit([]);
      return result;
    }, true);
  }

  createItem() {
    this.createItemClicked.emit();
  }

  editItem(id: string) {
    this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.diary.path), {
      queryParams: { edit: true, id: id },
    });
  }

  copyItem(id: string) {
    this.copyItemClicked.emit(id);
  }

  selectBar(): boolean {
    if (!this.diaryHolder.data) return false;
    return this.diaryHolder.data.some(s => s.selected);
  }

  rowSelect(row: any) {
    row.selected = !row.selected;
    const selectedDiaries = this.diaryHolder.data.filter(s => s.selected);
    this.customSelectionChange.emit(selectedDiaries);
  }

  //REDUNDANCY WARNING (plan-list, defect-list)
  allRowsSelect(e: Event) {
    e.stopImmediatePropagation();

    const stillSelected = this.grid.toggleSelectionOnAllVisibleRows();

    this.customSelectionChange.emit(stillSelected);
  }

  deselectAll() {
    this.diaryHolder.data.forEach(s => {
      s.selected = false;
    });
    this.customSelectionChange.emit([]);
  }

  shareItem(asdf: any) {}

  generateReport(id: string) {
    this.generateReportClicked.emit(id);
  }

  private getGridData(diaries: ConstructionDiaryModel[]): GridDiaryModel[] {
    return diaries.map(diary => {
      const craftNames =
        diary.positions
          ?.reduce((previous, position) => [...previous, ...position.crafts.map(c => c.name)], [])
          ?.distinct()
          ?.join(', ') ?? '';
      const regionNames =
        diary.positions
          ?.reduce((previous, position) => [...previous, ...position.regions.map(r => r.name)], [])
          ?.distinct()
          ?.join(', ') ?? '';

      const model: GridDiaryModel = {
        id: diary.id,
        number: this.numberFormatPipe.transform(diary.number, 4),
        state: diary.stateType,
        currentState: diary.currentState,
        assigned: diary.assigned,
        dayOfWeek: this.translate.instant('general.weekdays.day' + moment(diary.assigned).day()),
        weekNumber: moment(diary.assigned).week(),
        crafts: craftNames,
        organizations:
          diary.positions
            ?.map(position => position.organization.name)
            ?.distinct()
            ?.join(', ') ?? '',
        regions: regionNames,
        selected: false,
        canWrite: diary.allowedPermissions.includes(ConstructionDiaryStatePermission.Construction_diary_edit),
      };

      return model;
    });
  }

  private initData(count: number = 5) {
    const diaries = [];
    const date = new Date('1970-01-01');
    const dateMoment = moment(date);

    for (let i = 0; i < count; i++) {
      const model: GridDiaryModel = {
        id: 'fakeData',
        number: '#0000',
        state: ConstructionDiaryState.Capture,
        currentState: new ConstructionDiaryStateProjectEntityState({
          title: 'fakeData',
        }),
        assigned: dateMoment.toLocalDate(),
        dayOfWeek: dateMoment.day(),
        weekNumber: 5,
        crafts: 'fakeData',
        organizations: 'fakeData',
        regions: 'fakeData',
        selected: false,
        canWrite: false,
      };
      diaries.push(model);
    }

    return diaries;
  }

  private getGridDef(): C4GridDef {
    const gridDef: C4GridDef = {
      initialSorting: [
        {
          field: 'number',
          order: 1,
        },
      ],
      grid: {
        filterRow: true,
        lazy: false,
        lazyInit: false,
        paging: true,
        responsive: true,
        scrollable: true,
        rowExpand: true,
        rows: 10,
        rowsOptions: [5, 10, 20, 50, 100],
      },
      row: {
        link: false,
      },
      cols: [],
    };

    if (this.mode != ConstructionDiaryListMode.singleSelectIntegrated) {
      gridDef.cols.push({
        field: 'select',
        header: 'none',
        width: '3.5em',
        minWidth: '3.5em',
        priority: 4,
        cssClass: 'planbutton',
        sortable: false,
        template: this.selectRowTemplate,
        headerTemplate: this.selectAllTemplate,
      });
    }

    gridDef.cols.push(
      {
        field: nameof<GridDiaryModel>('number'),
        header: 'constructionDiary.number',
        width: '5em',
        minWidth: '5em',
        cssClass: 'right-aligned',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<GridDiaryModel>('assigned'),
        header: 'constructionDiary.assigned',
        width: '1*',
        minWidth: '10em',
        priority: 1,
        sortable: true,
        pipe: this.datePipe,
        template: this.mode != ConstructionDiaryListMode.singleSelectIntegrated ? this.assignedTemplate : null,
      }
    );

    if (this.mode != ConstructionDiaryListMode.singleSelectIntegrated) {
      gridDef.cols.push({
        field: 'contextmenu',
        header: 'none',
        width: '4em',
        minWidth: '4em',
        priority: 1,
        sortable: false,
        template: this.contextTemplate,
        cssClass: 'diary-list-ico',
      });
    }

    gridDef.cols.push(
      {
        field: nameof<GridDiaryModel>('dayOfWeek'),
        header: 'constructionDiary.dayOfWeek',
        width: '1*',
        minWidth: '8em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<GridDiaryModel>('weekNumber'),
        header: 'constructionDiary.weekNumber',
        width: '1*',
        minWidth: '10em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<GridDiaryModel>('currentState'),
        header: 'constructionDiary.state',
        width: '1*',
        minWidth: '10em',
        priority: 1,
        sortable: true,
        options: this.diaryStates,
        filterType: C4GridFilterType.multiselect,
        filterMatchMode: C4GridMatchMode.equals,
        template: this.stateTemplate,
      },
      {
        field: nameof<GridDiaryModel>('crafts'),
        header: 'constructionDiary.crafts',
        width: '1*',
        minWidth: '12em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<GridDiaryModel>('organizations'),
        header: 'constructionDiary.organizations',
        width: '1*',
        minWidth: '12em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<GridDiaryModel>('regions'),
        header: 'constructionDiary.regions',
        width: '1*',
        minWidth: '12em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      }
    );

    return gridDef;
  }
}
