import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LeanWorkpackageSequenceModel, PrivilegeEnum } from '@app/api';
import { ApiService, AppRoutingData, BaseSubscriptionComponent, CanLeave, ProjectService, Utils } from '@app/core';
import { ConfirmDialogComponent, MultiSelectDialogComponent } from '@app/shared/components';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import { SequenceEditorComponent } from './sequence-editor/sequence-editor.component';

interface ExtendedWorkpackageSequence extends LeanWorkpackageSequenceModel {
  joinedTemplateNames?: string;
}

@Component({
  selector: 'app-workpackage-sequences',
  templateUrl: './workpackage-sequences.component.html',
  styleUrls: ['./workpackage-sequences.component.scss'],
  host: { class: 'c4-prevent-flex-height-overflow' },
})
export class WorkpackageSequencesComponent extends BaseSubscriptionComponent implements Busy, OnInit, CanLeave {
  @ViewChild('sequenceTemplate', { static: true }) sequenceTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('editor', { static: true }) editor: SequenceEditorComponent;
  @ViewChild('workPackageSequenceList', { static: true }) workPackageSequenceList: TemplateRef<any>;

  allowGlobalImport = false;
  isBusy = false;
  icon = AppRoutingData.workpackageSequences.icon;

  projectId: string;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private userNotification: UserNotificationService
  ) {
    super();
  }

  canLeave() {
    return this.editor.canLeave();
  }

  ngOnInit() {
    this.subscribe(this.projectService.projectId$, async projectId => {
      this.projectId = projectId;

      var privileges = await this.apiService.getUserPrivileges();
      this.allowGlobalImport = privileges.indexOf(PrivilegeEnum.ReadWriteLean) >= 0;
    });
  }

  async addFromGlobal() {
    let globalSequences: LeanWorkpackageSequenceModel[];
    await using(new BusyScope(this), async _ => {
      globalSequences = await this.apiService.getGlobalSequences();

      if (!globalSequences?.length) this.userNotification.notify('grid.workpackageSequences.noDataText');
    }).catch(error => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.workpackageSequences', error);
    });

    if (!globalSequences) return;

    const data = {
      items: globalSequences.sort(Utils.propertySort(sequence => sequence.name)).map(t => {
        var selectableTemplate = t as Selectable;
        selectableTemplate.displayName = t.name;
        selectableTemplate.isSelected = false;
        return selectableTemplate;
      }),
      mustSelect: true,
      canCancel: true,
      title: 'workpackageSequences.global.importTitle',
      description: 'workpackageSequences.global.importDescription',
    };

    const sequences: LeanWorkpackageSequenceModel[] = await this.dialog
      .open(MultiSelectDialogComponent, {
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    if (sequences?.length > 0) {
      const derrivedIdsOfExistingSequences = this.editor.getSequences().map(s => s.derrivedId);
      const overridedSequences = sequences.filter(s => derrivedIdsOfExistingSequences.includes(s.id));

      let updateIfExisting = false;
      if (overridedSequences.length > 0) {
        updateIfExisting = await this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              title: 'workpackageTemplates.import.overwriteTitle',
              description: 'workpackageTemplates.import.overwriteDesc',
              acceptButtonLabel: 'general.yes',
              cancelButtonLabel: 'general.no',
              contentTemplate: this.workPackageSequenceList,
              contentData: overridedSequences,
            },
          })
          .afterClosed()
          .toPromise();
      }

      await using(new BusyScope(this), async _ => {
        await this.apiService.transferSequencesToProject(
          sequences.map(c => c.id),
          updateIfExisting
        );
        this.userNotification.notify('general.successMsg.transfer');
        this.editor.updateData();
      }).catch(error => {
        this.userNotification.notify('general.errorMsg.transfer', { error: error });
      });
    }
  }

  async save() {
    const sequences = this.editor.getSequences();

    if (sequences.any(s => Utils.isNullOrWhitespace(s.name))) {
      this.userNotification.notify('workpackageSequences.errors.nameEmpty');
      return;
    }

    using(new BusyScope(this), async _ => {
      await this.apiService.bulkSaveWorkpackageSequences(sequences);
      this.userNotification.notify('general.successMsg.save');
      this.editor.updateData();
    }).catch(e => {
      this.userNotification.notify('general.errorMsg.save', { error: e });
    });
  }
}
