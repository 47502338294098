import { Injectable } from '@angular/core';
import {
  ApiService,
  AppConfigService,
  AuthenticationService,
  BehaviorObservable,
  CustomValidators,
  FormState,
  FormUtils,
  GlobalFormStateTrackerService,
  LogService,
  Utils,
  intRegex,
  nameof,
} from '@app/core';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { UserNotificationService } from '../user-notification/user-notification.service';
import {
  AddressModel,
  ChannelType,
  ConfigType,
  FunctionalPrivilegeModel,
  IProjectConfigDocumentsFolder,
  IProjectConfigDocumentsResource,
  IProjectConfigResourceTab,
  LRTaskState,
  LocationModel,
  ModuleType,
  OrganizationModel,
  PlanSchemaMetadata,
  PrivilegeEnum,
  PrivilegeEnumGroupData,
  ProblemDetails,
  ProblemDetailsErrorType,
  ProjectConfigAssignments,
  ProjectConfigBimModule,
  ProjectConfigConstructionDiaryModule,
  ProjectConfigDefectModule,
  ProjectConfigDocumentsFolder,
  ProjectConfigDocumentModule,
  ProjectConfigDocumentsResource,
  ProjectConfigGalleryModule,
  ProjectConfigLeanModule,
  ProjectConfigMSTeamsFolder,
  ProjectConfigMSTeamsModule,
  ProjectConfigMSTeamsResource,
  ProjectConfigMetadataModel,
  ProjectConfigModel,
  ProjectConfigModelWithPrivileges,
  ProjectConfigPlanModule,
  ProjectConfigResourceTab,
  ProjectConfigRoomBookModule,
  ProjectConfigSchemaModel,
  ProjectModelWithLogos,
  ResourceTemplateType,
  SchemaErrorType,
  TeamConfigDefinitionSettings,
  TeamConfigMetadata,
  TeamConfigPrivilegeModel,
  UserModel,
  IProjectConfigTeamRole,
  ProjectConfigTeamRole,
  DefectState,
  DefectStateProjectConfigState,
  ProjectConfigStateRoleType,
  ProjectConfigStateBinding,
  ProjectConfigPermissionDescription,
  DefectStateProjectConfigStateConfiguration,
  ConstructionDiaryStateProjectConfigState,
  ConstructionDiaryState,
  ConstructionDiaryStateProjectConfigStateConfiguration,
  IssueStateProjectConfigState,
  IssueState,
  WorkpackageStateProjectConfigState,
  WorkpackageState,
  IssueStateProjectConfigStateConfiguration,
  WorkpackageStateProjectConfigStateConfiguration,
  PrintQrCodeConfiguration,
  QrCodeAnchor,
  ProjectState,
} from '@app/api';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import {
  PcfBim,
  PcfChannelTab,
  PcfDefect,
  PcfDiary,
  PcfChannel,
  PcfGeneral,
  PcfLean,
  PcfMetadata,
  PartialProjectConfigFormGroupModel,
  PcfPlan,
  PcfRolePrivilege,
  PcfRole,
  ProjectConfigFormGroupModel,
  PcfRoles,
  PcfStructure,
  PcfPrivilegeGroup,
  PcfFolder,
  PcfDirectory,
  PcfMsTeams,
  PcfStatesGroup,
} from './project-config.interfaces';
import {
  CheckboxState,
  ConfirmDialogComponent,
  IndividualPrivilegeService,
  PrivilegeRowData,
  StateRolePermission,
  SchemaEditDialogComponent,
  StateRole,
  StateSchemaService,
  StateSchemaRole,
  ProjectConfigState,
  ConfigState,
  DocumentPosition,
} from '@app/shared/components';
import { LongRunningTaskService } from '../long-running-tasks/long-running-task.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDateFormat, LocalizedDatePipe } from '@app/shared/pipes';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateFallbackPipe } from '@app/shared/pipes/try-translate.pipe';

interface FunctionalPrivilegeGroup {
  groupType: PrivilegeEnumGroupData;
  privileges: FunctionalPrivilegeModel[];
}

@Injectable({
  providedIn: 'root',
})
export class ProjectConfigService implements Busy, FormState {
  isBusy: boolean;

  form: UntypedFormGroup;

  private _id: string;
  private _isNew: boolean;
  private _isTemplate: boolean;
  private _privileges: PrivilegeEnum[];

  private _computedName: string;

  private _originalConfig: ProjectConfigModelWithPrivileges;
  private _planSchemes: PlanSchemaMetadata[] = [];
  private _teamTemplates: TeamConfigMetadata[] = [];
  private _msTeamsTemplates: ProjectConfigMSTeamsResource[] = [];
  private _activeMsTeamsTemplates: ProjectConfigMSTeamsResource[] = [];
  private _portalTemplates: ProjectConfigDocumentsResource[] = [];
  private _activePortalTemplates: ProjectConfigDocumentsResource[] = [];
  private _projectOrganizations: OrganizationModel[] = [];
  private _globalOrganizations: OrganizationModel[] = [];
  private _functionalPrivilegeGroups: FunctionalPrivilegeGroup[];
  private _singleFolderPrivileges: TeamConfigPrivilegeModel[];
  private _multiFolderPrivileges: TeamConfigPrivilegeModel[];
  private _projectUsers: UserModel[] = [];
  private _roleAndUserData: Record<string, PrivilegeRowData> = {};
  private _channelTemplateMapping: Record<string, string> = {};

  // private structureChangedSubject = new BehaviorSubject<UntypedFormGroup[]>([]);
  private initializedSubject = new BehaviorSubject<boolean>(false);
  private savedSubject = new Subject<string>();
  private _state = ProjectState.Active;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private datePipe: LocalizedDatePipe,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private logService: LogService,
    private lrtService: LongRunningTaskService,
    private router: Router,
    private translateService: TranslateService,
    private tryTranslatePipe: TranslateFallbackPipe,
    private userNotification: UserNotificationService
  ) {
    this.initForm();
  }

  get isDirty(): boolean {
    return (
      this.generalForm.dirty ||
      this.metadataForm.dirty ||
      this.rolesForm.dirty ||
      this.msTeamsForm.dirty ||
      this.structureForm.dirty ||
      this.planForm.dirty ||
      this.bimForm.dirty ||
      this.defectForm.dirty ||
      this.diaryForm.dirty ||
      this.leanForm.dirty
    );
  }

  get isValid(): boolean {
    // need to check !invalid since disabledControl.valid == false
    return (
      !this.generalForm.invalid &&
      !this.metadataForm.invalid &&
      !this.rolesForm.invalid &&
      !this.msTeamsForm.invalid &&
      !this.structureForm.invalid &&
      !this.planForm.invalid &&
      !this.bimForm.invalid &&
      !this.defectForm.invalid &&
      !this.diaryForm.invalid &&
      !this.leanForm.invalid
    );
  }

  get isNew(): boolean {
    return this._isNew;
  }

  get isTemplate(): boolean {
    return this._isTemplate;
  }

  get canProvision(): boolean {
    return this._privileges?.includes(PrivilegeEnum.DriveProvisioning) ?? false;
  }

  get canWriteProject(): boolean {
    return this._privileges?.includes(PrivilegeEnum.ReadWriteProject) ?? false;
  }

  get canEditPlanSchema(): boolean {
    if (!this.canProvision || !this.canWriteProject) return false;
    if (this.isTemplate || this.isNew) return true;

    const savedPlanSchemaId = this._originalConfig?.plan?.metadata?.id;
    const selectedPlanSchemaId = FormUtils.getFormValue<PcfPlan, PlanSchemaMetadata>(this.planForm, 'selectedSchema')?.id;
    return savedPlanSchemaId != null && savedPlanSchemaId == selectedPlanSchemaId;
  }

  get canEditBimSchema(): boolean {
    if (!this.canProvision || !this.canWriteProject) return false;
    if (this.isTemplate || this.isNew) return true;

    const savedBimSchemaId = this._originalConfig?.bim?.metadata?.id;
    const selectedBimSchemaId = FormUtils.getFormValue<PcfBim, PlanSchemaMetadata>(this.bimForm, 'selectedSchema')?.id;
    return savedBimSchemaId != null && savedBimSchemaId == selectedBimSchemaId;
  }

  get computedName(): string {
    return !this.isTemplate && !this.isNew ? FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'name') : this._computedName;
  }

  get planSchemes(): PlanSchemaMetadata[] {
    return this._planSchemes;
  }

  get teamTemplates(): TeamConfigMetadata[] {
    return this._teamTemplates;
  }

  get msTeamsTemplates(): ProjectConfigMSTeamsResource[] {
    return this._msTeamsTemplates;
  }

  get portalTemplates(): ProjectConfigDocumentsResource[] {
    return this._portalTemplates;
  }

  get projectOrganizations(): OrganizationModel[] {
    return this._projectOrganizations;
  }

  get globalOrganizations(): OrganizationModel[] {
    return this._globalOrganizations;
  }

  get functionalPrivilegeGroups(): FunctionalPrivilegeGroup[] {
    return this._functionalPrivilegeGroups;
  }

  get singleFolderPrivileges(): TeamConfigPrivilegeModel[] {
    return this._singleFolderPrivileges;
  }

  get multiFolderPrivileges(): TeamConfigPrivilegeModel[] {
    return this._multiFolderPrivileges;
  }

  get defectStatePermissions(): ProjectConfigPermissionDescription[] {
    return this._originalConfig?.defect?.stateConfiguration?.permissions ?? [];
  }

  get defectStatePredefinedRoles(): StateSchemaRole[] {
    return this._originalConfig?.defect?.stateConfiguration?.roles ?? [];
  }

  get diaryStatePermissions(): ProjectConfigPermissionDescription[] {
    return this._originalConfig?.constructionDiary?.stateConfiguration?.permissions ?? [];
  }

  get diaryStatePredefinedRoles(): StateSchemaRole[] {
    return this._originalConfig?.constructionDiary?.stateConfiguration?.roles ?? [];
  }

  get issueStatePermissions(): ProjectConfigPermissionDescription[] {
    return this._originalConfig?.bim?.stateConfiguration?.permissions ?? [];
  }

  get issueStatePredefinedRoles(): StateSchemaRole[] {
    return this._originalConfig?.bim?.stateConfiguration?.roles ?? [];
  }

  get workpackageStatePermissions(): ProjectConfigPermissionDescription[] {
    return this._originalConfig?.lean?.stateConfiguration?.permissions ?? [];
  }

  get workpackageStatePredefinedRoles(): StateSchemaRole[] {
    return this._originalConfig?.lean?.stateConfiguration?.roles ?? [];
  }

  get projectUsers(): UserModel[] {
    return this._projectUsers;
  }

  get roleAndUserData(): Record<string, PrivilegeRowData> {
    return this._roleAndUserData;
  }

  get initialized$(): BehaviorObservable<boolean> {
    return this.initializedSubject;
  }

  get saved(): Observable<string> {
    return this.savedSubject;
  }

  get generalForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'general');
  }

  get metadataForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'metadata');
  }

  get rolesForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'roles');
  }

  get msTeamsForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'msTeams');
  }

  get structureForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'structure');
  }

  get planForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'plan');
  }

  get bimForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'bim');
  }

  get defectForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'defect');
  }

  get diaryForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'diary');
  }

  get leanForm(): UntypedFormGroup {
    return FormUtils.getFormControl<ProjectConfigFormGroupModel, UntypedFormGroup>(this.form, 'lean');
  }

  get state(): ProjectState {
    return this._state;
  }

  private get address(): AddressModel {
    const address = FormUtils.getFormValue<PcfMetadata, PcfMetadata['address']>(this.metadataForm, 'address');

    return new AddressModel({
      street: address?.street ?? '',
      city: address?.city ?? '',
      zipCode: address?.zipCode ?? '',
      country: address?.country ?? '',
    });
  }

  private get location(): LocationModel {
    const location = FormUtils.getFormValue<PcfMetadata, PcfMetadata['location']>(this.metadataForm, 'location');

    return location && location.latitude && location.longitude
      ? new LocationModel({
          latitude: Number.parseFloat(location.latitude.toString().replace(',', '.')),
          longitude: Number.parseFloat(location.longitude.toString().replace(',', '.')),
        })
      : null;
  }

  async canLeave() {
    if (this.isDirty) {
      const hasConfirmed = await this.dialog
        .open(ConfirmDialogComponent, {
          data: { title: 'general.warning', description: 'general.unsavedChangesConfirmation' },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      if (hasConfirmed) {
        this.form.markAsPristine();
      }
    }

    return !this.isDirty;
  }

  async initialize(id = null, isTemplate = false) {
    this.globalFormStateTracker.trackFormState(this);

    this._isTemplate = isTemplate;
    this._isNew = id == null;
    this._id = id;

    await this.loadSelections(id);

    if (isTemplate) await this.loadTemplate(id);
    else await this.loadProject(id);

    this.disableControls();

    this.initializedSubject.next(true);
  }

  async save() {
    if (!this.isValid) throw 'Invalid Project Config';

    if (!this.isNew && !this.isDirty) {
      this.userNotification.notify('general.successMsg.save');
      return;
    }

    await using(new BusyScope(this), async _ => {
      if (this.isTemplate) {
        try {
          this._id = await this.apiService.saveOrUpdateTemplateConfiguration(
            this.getProjectConfig(
              new ProjectConfigMetadataModel({
                id: this._id,
                name: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'name'),
                code: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'code'),
                description: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'description'),
              })
            )
          );
        } catch (error) {
          if (error.status === 409)
            await this.userNotification.notify('projectConfig.error.template.conflict', { error: error });
          else await this.userNotification.notify('projectConfig.error.template.saveFailed', { error: error });
          return;
        }
      } else {
        const project = this.parseProject();
        const success = await this.provisionProject(project);
        if (!success) return;

        this.updateStructureAfterSave();
        this.apiService.removeCacheAffectedByProjectSettings(this._id);
        this.authService.notifyProjectUpdated(this._id);
      }

      this.disableControlsAfterSave();
      this.savedSubject.next(this._id);
      this.form.markAsPristine();

      this.userNotification.notify('general.successMsg.save');
    }).catch(e => {
      this.logService.error(e);
      this.userNotification.notify('general.errorMsg.save', { error: e });
    });
  }

  async saveAsNewTemplate(): Promise<string> {
    if (!this.isValid) throw 'Invalid Project Config';

    return await using(new BusyScope(this), async _ => {
      let name = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'name');
      let code = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'code');
      let description = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'description');

      if (!this.isTemplate) {
        name = `${await this.translateService.get('general.template').toPromise()}_${name}`;
        description = await this.translateService
          .get('projectConfig.saveAsNewTemplateDescription', {
            date: this.datePipe.transform(new Date(), LocalizedDateFormat.short),
          })
          .toPromise();
      } else if (name == this._originalConfig?.metadata?.name) {
        name += ` (${await this.translateService.get('general.copyNoun').toPromise()})`;
      }

      const id = await this.apiService.saveOrUpdateTemplateConfiguration(
        this.getProjectConfig(
          new ProjectConfigMetadataModel({
            name,
            code,
            description,
          })
        )
      );

      await this.userNotification.notify('projectConfig.success.asTemplate');

      return id;
    }).catch(error => {
      this.logService.error(error);

      if (error.status === 409) this.userNotification.notify('projectConfig.error.template.conflict', { error: error });
      else this.userNotification.notify('projectConfig.error.template.saveFailed', { error: error });

      return null;
    });
  }

  async setState(projectState: ProjectState) {
    await this.apiService.setProjectState(this._id, projectState);
    this.apiService.removeCacheAffectedByProjectSettings(this._id);
  }

  dispose() {
    this.globalFormStateTracker.untrackFormState(this);
    this.reset();
  }

  getCurrentRoles(): PcfRole[] {
    return FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.rolesForm, 'roles').getRawValue();
  }

  addRole(role: IProjectConfigTeamRole) {
    const roleGroup = this.getRoleGroup({ id: Utils.createUUID(), privilege: [], ...role });

    const roles = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.rolesForm, 'roles');
    roles.push(roleGroup);
    roles.markAsDirty();

    this.addRoleToStates(roleGroup.value.id);
  }

  removeRole(id: string) {
    const rolesArray = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.rolesForm, 'roles');
    const index = rolesArray.controls.findIndex((r: UntypedFormGroup) => FormUtils.getFormValue<PcfRole>(r, 'id') == id);

    if (index < 0) throw 'Could not find role';

    delete this._roleAndUserData[id];
    rolesArray.removeAt(index);
    rolesArray.markAsDirty();

    this.removeFromSubRoles(id, rolesArray.controls as UntypedFormGroup[]);

    const portalChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'portalChannels');
    this.removeRoleFromStructureRecursive(id, portalChannels);

    this.removeRoleFromStates(id);
  }

  getTabGroup(tab: IProjectConfigResourceTab, isProvisioned: boolean = true) {
    const hideNavigationParam = 'contentOnly=true';

    const channelTabGroup: DictWithKeysOf<PcfChannelTab> = {
      displayName: [tab.displayName ?? '', [Validators.required]],
      entityId: [{ value: tab.entityId ?? '', disabled: !this.isTemplate && isProvisioned }, [Validators.required]],
      appId: [{ value: tab.appId ?? '', disabled: !this.isTemplate && isProvisioned }, [Validators.required]],
      webSiteUrl: [tab.webSiteUrl ?? '', [Validators.required]],
      contentUrl: [tab.contentUrl ?? '', { updateOn: 'blur', validators: [Validators.required] }],
      hideNavigation: [tab.contentUrl ? tab.contentUrl.toLowerCase().indexOf(hideNavigationParam.toLowerCase()) > 0 : false],
    };

    const formGroup = this.formBuilder.group(channelTabGroup);
    const contentUrlControl = FormUtils.getFormControl<PcfChannelTab>(formGroup, 'contentUrl');

    const hideNavigationViaUrl = (url: string) => {
      const paramStart = url.toLowerCase().indexOf(hideNavigationParam.toLowerCase());

      if (paramStart < 0) {
        const hasOtherParameters = url.indexOf('?') >= 0;
        contentUrlControl.setValue(`${url}${hasOtherParameters ? '&' : '?'}${hideNavigationParam}`, {
          emitEvent: false,
        });
      }
    };

    const showNavigationViaUrl = (url: string) => {
      let paramStart = url.toLowerCase().indexOf(hideNavigationParam.toLowerCase());

      if (paramStart >= 0) {
        --paramStart;

        const urlArray = url.split('');

        const isFirstParameter = url[paramStart] == '?';

        if (isFirstParameter) {
          urlArray[url.indexOf('&')] = '?';
        }

        urlArray.splice(paramStart, hideNavigationParam.length + 1);

        contentUrlControl.setValue(urlArray.join(''), { emitEvent: false });
      }
    };

    const hideNavigationControl = FormUtils.getFormControl<PcfChannelTab>(formGroup, 'hideNavigation');

    contentUrlControl.valueChanges.subscribe((url: string) => {
      const hideNavigation = !!hideNavigationControl.value;
      if (hideNavigation) hideNavigationViaUrl(url);
      else showNavigationViaUrl(url);
    });

    hideNavigationControl.valueChanges.subscribe((hideNavigation: boolean) => {
      const url = contentUrlControl.value ?? '';

      if (hideNavigation) hideNavigationViaUrl(url);
      else showNavigationViaUrl(url);
    });

    return formGroup;
  }

  getChannelGroup(resource: IProjectConfigDocumentsResource, isPortalResource: boolean, isForBuild: boolean = false) {
    const isBuildForExistingProject = isForBuild && !this.isTemplate && !this.isNew;
    const individualPrivileges = this.getCurrentRoles()
      .filter(r => r.isSystemRole)
      .reduce((individualPrivileges, systemRole) => {
        individualPrivileges[systemRole.id] = individualPrivileges[systemRole.id] ?? 0;
        return individualPrivileges;
      }, resource.privilege ?? {});

    const channelGroup: DictWithKeysOf<PcfChannel> = {
      id: [resource.id],
      path: [
        {
          value: resource.name ?? '',
          disabled: isBuildForExistingProject,
        },
        [Validators.required, CustomValidators.sharepointChannel],
      ],
      individualPrivileges: this.formBuilder.array(
        Object.keys(individualPrivileges)
          .filter(id => this.roleAndUserData[id] != undefined)
          .map(id =>
            IndividualPrivilegeService.getIndividualPrivilegeGroup(
              this.formBuilder,
              id,
              individualPrivileges[id],
              this.singleFolderPrivileges
            )
          )
      ),
      folders: this.formBuilder.array([], [CustomValidators.uniqueControls(nameof<PcfFolder>('path'))]),
      templateType: [resource.resourceTemplateType ?? ResourceTemplateType.Default],
      // channelType: [
      //   {
      //     value: resource.channelType ?? ChannelType.Channel,
      //     disabled: !isPortalResource || isBuildForExistingProject,
      //   },
      // ],
      isHidden: [
        {
          value: resource.channelType === ChannelType.Hidden,
          disabled: !isPortalResource || isBuildForExistingProject,
        },
      ],
      tabs: this.formBuilder.array((resource.tabs ?? []).map(tab => this.getTabGroup(tab, isBuildForExistingProject))),
    };

    return this.buildDirectoryGroup(channelGroup);
  }

  getFolderGroup(folderConfig: IProjectConfigDocumentsFolder, isNew: boolean = false) {
    const folderPath = folderConfig.name ? folderConfig.name : null;
    const oldFolderPath = isNew ? null : folderPath;
    const subFoldersForm = this.formBuilder.array([], [CustomValidators.uniqueControls(nameof<PcfChannel>('path'))]);
    const individualPrivileges = folderConfig.privilege ?? {};

    const folderGroup: DictWithKeysOf<PcfFolder> = {
      path: [folderPath ?? '', [Validators.required, CustomValidators.driveItemName]],
      oldPath: [{ value: oldFolderPath, disabled: true }],
      individualPrivileges: this.formBuilder.array(
        Object.keys(individualPrivileges)
          .filter(id => this.roleAndUserData[id] != undefined)
          .map(id =>
            IndividualPrivilegeService.getIndividualPrivilegeGroup(
              this.formBuilder,
              id,
              individualPrivileges[id],
              this.singleFolderPrivileges
            )
          )
      ),
      folders: subFoldersForm,
    };

    return this.buildDirectoryGroup(folderGroup);
  }

  addMsTeamsTemplate(resource: ProjectConfigMSTeamsResource) {
    return this.addChannelTemplate<ProjectConfigMSTeamsResource>(
      resource,
      this._msTeamsTemplates,
      this._activeMsTeamsTemplates
    );
  }

  addPortalTemplate(resource: ProjectConfigDocumentsResource) {
    return this.addChannelTemplate<ProjectConfigDocumentsResource>(
      resource,
      this._portalTemplates,
      this._activePortalTemplates,
      true
    );
  }

  convertChannel(channelGroup: UntypedFormGroup, newModule: ModuleType) {
    const isHiddenControl = FormUtils.getFormControl<PcfChannel>(channelGroup, 'isHidden');
    const isEditable = FormUtils.getFormControl<PcfChannel>(channelGroup, 'path').enabled;

    if (newModule == ModuleType.Document) {
      if (isEditable) isHiddenControl.enable({ emitEvent: false });
    } else if (!isEditable && !!isHiddenControl.value) {
      this.userNotification.notify('projectConfig.structure.errors.moveHiddenChannelToNone');
      return;
    } else {
      isHiddenControl.disable({ emitEvent: false });
      isHiddenControl.setValue(false);
    }
  }

  handleChannelRemoved(id: string) {
    const templateId = this._channelTemplateMapping[id];
    if (!templateId) return;

    let moduleType = ModuleType.MSTeams;
    let activeIndex = this._activeMsTeamsTemplates.findIndex(t => t.id === templateId);

    if (activeIndex < 0) {
      moduleType = ModuleType.Document;
      activeIndex = this._activePortalTemplates.findIndex(t => t.id === templateId);
    }

    if (activeIndex < 0) throw 'Active Template not found';

    delete this._channelTemplateMapping[id];

    switch (moduleType) {
      case ModuleType.Document:
        const [portalTemplate] = this._activePortalTemplates.splice(activeIndex, 1);
        this._portalTemplates.push(portalTemplate);
        break;
      case ModuleType.MSTeams:
        const [msTeamsTemplate] = this._activeMsTeamsTemplates.splice(activeIndex, 1);
        this._msTeamsTemplates.push(msTeamsTemplate);
        break;
    }
  }

  async editPlanSchema(schemaType: ConfigType) {
    if (!this.canProvision) throw 'Cannot edit schema - missing privileges';

    let selectedSchema: PlanSchemaMetadata = null;
    switch (schemaType) {
      case ConfigType.BimSchema:
        selectedSchema = FormUtils.getFormValue<PcfBim, PlanSchemaMetadata>(this.bimForm, 'selectedSchema');
        break;
      case ConfigType.PlanSchema:
        selectedSchema = FormUtils.getFormValue<PcfPlan, PlanSchemaMetadata>(this.planForm, 'selectedSchema');
        break;
      default:
        throw 'Must specify schema type';
    }

    const wasSaved = await this.dialog
      .open(SchemaEditDialogComponent, {
        data: {
          title: this.isTemplate || this.isNew ? 'dialogs.editSchema.editTitle' : 'dialogs.editSchema.companyTitle',
          params: {
            companyName: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'name'),
            schemaName: selectedSchema.name ?? '',
          },
          planSchemaId: selectedSchema.id,
          projectId: this.isTemplate ? null : this._id,
          schemaType,
        },
        disableClose: true,
        panelClass: 'team-cfg-dlg',
      })
      .afterClosed()
      .toPromise();

    if (wasSaved) await this.refreshPlanSchemes();
  }

  private initForm() {
    const generalGroup: DictWithKeysOf<PcfGeneral> = {
      number: [''],
      name: ['', [Validators.required]],
      template: [null, [Validators.required]],
      organization: [null],
      code: [''],
      description: [''],
      isBimModuleEnabled: [false],
      isDefectModuleEnabled: [false],
      isDiaryModuleEnabled: [false],
      isGalleryModuleEnabled: [false],
      isLeanModuleEnabled: [false],
      isPlanModuleEnabled: [false],
      isRoomBookModuleEnabled: [false],
      logo: [null],
      logoSmall: [null],
      isNameComputed: [false],
    };

    const metadataGroup: DictWithKeysOf<PcfMetadata> = {
      administration: [''],
      externalId: [''],
      externalSystemName: [''],
      start: [null],
      end: [null],
      address: [null],
      location: [null],
      mainClient: [null],
    };

    const rolesGroup: DictWithKeysOf<PcfRoles> = {
      roles: this.formBuilder.array([]),
    };

    const msTeamsGroup: DictWithKeysOf<PcfMsTeams> = {
      isTeamPublic: [false],
      supportSharePointGroupPrivileges: [false],
    };

    const structureGroup: DictWithKeysOf<PcfStructure> = {
      portalChannels: this.formBuilder.array([]),
      msTeamsChannels: this.formBuilder.array([]),
    };

    const planGroup: DictWithKeysOf<PcfPlan> = {
      selectedSchema: [null, [Validators.required]],
      qrPrintEnabled: [null],
      qrPosition: [null],
      qrSize: [null, [Validators.required]],
      qrOffsetVertical: [null, [Validators.required]],
      qrOffsetHorizontal: [null, [Validators.required]],
      qrScaling: [null],
    };

    const bimGroup: DictWithKeysOf<PcfBim> = {
      selectedSchema: [null, [Validators.required]],
      deadlineNotifications: [false],
      states: this.formBuilder.array([]),
    };

    const defectGroup: DictWithKeysOf<PcfDefect> = {
      deadlineNotifications: [false],
      states: this.formBuilder.array([]),
    };

    const diaryGroup: DictWithKeysOf<PcfDiary> = {
      defaultReportResource: [''],
      defaultReportPath: [''],
      states: this.formBuilder.array([]),
    };

    const leanGroup: DictWithKeysOf<PcfLean> = {
      weekCount: ['', [Validators.required, Validators.min(1), Validators.pattern(intRegex)]],
      states: this.formBuilder.array([]),
    };

    const form: DictWithKeysOf<ProjectConfigFormGroupModel> = {
      general: this.formBuilder.group(generalGroup),
      metadata: this.formBuilder.group(metadataGroup),
      plan: this.formBuilder.group(planGroup),
      bim: this.formBuilder.group(bimGroup),
      roles: this.formBuilder.group(rolesGroup),
      msTeams: this.formBuilder.group(msTeamsGroup),
      structure: this.formBuilder.group(structureGroup, {
        validators: [
          CustomValidators.uniqueFlatControls(
            [nameof<PcfStructure>('msTeamsChannels'), nameof<PcfStructure>('portalChannels')],
            nameof<PcfChannel>('path')
          ),
        ],
      }),
      defect: this.formBuilder.group(defectGroup),
      diary: this.formBuilder.group(diaryGroup),
      lean: this.formBuilder.group(leanGroup),
    };

    this.form = this.formBuilder.group(form);

    combineLatest([
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isNameComputed').valueChanges.pipe(startWith(false)),
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'name').valueChanges.pipe(startWith('')),
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'template').valueChanges.pipe(
        startWith(null as TeamConfigMetadata)
      ),
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'organization').valueChanges.pipe(
        startWith(null as OrganizationModel)
      ),
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'number').valueChanges.pipe(startWith('')),
    ]).subscribe(([isNameComputed, name, template, organization, number]) => {
      this.updateName(isNameComputed, name, template?.code, organization?.code, number);
    });

    FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'template')
      .valueChanges.pipe(
        startWith(null),
        pairwise(),
        filter(([previousTemplate, selectedTemplate]) => !!selectedTemplate && previousTemplate?.id != selectedTemplate.id),
        map(([_, selectedTemplate]) => selectedTemplate)
      )
      .subscribe(selectedTemplate => {
        this.loadTemplate(selectedTemplate.id, true).then(() => {
          this.disableControls();
        });
      });

    FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isPlanModuleEnabled').valueChanges.subscribe(isEnabled => {
      if (isEnabled) {
        this.planForm.enable({ emitEvent: false });

        const canSelectPlanSchema = (this._originalConfig?.plan?.canModifyMetadata ?? true) && this.canProvision;
        if (!canSelectPlanSchema)
          FormUtils.getFormControl<PcfPlan>(this.planForm, 'selectedSchema').disable({ emitEvent: false });

        // trigger value change to disable controls if required
        const qrPrintEnabledControl = FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrPrintEnabled');
        qrPrintEnabledControl.setValue(qrPrintEnabledControl.value);
      } else {
        this.planForm.disable({ emitEvent: false });
      }
    });

    FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isBimModuleEnabled').valueChanges.subscribe(isEnabled => {
      if (isEnabled) {
        this.bimForm.enable({ emitEvent: false });

        if (!this.canProvision) FormUtils.getFormControl<PcfBim>(this.bimForm, 'states').disable({ emitEvent: false });

        const canSelectBimSchema = (this._originalConfig?.bim?.canModifyMetadata ?? true) && this.canProvision;
        if (!canSelectBimSchema) FormUtils.getFormControl<PcfBim>(this.bimForm, 'selectedSchema').disable({ emitEvent: false });
      } else {
        this.bimForm.disable({ emitEvent: false });
      }
    });

    FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isDefectModuleEnabled').valueChanges.subscribe(isEnabled => {
      if (isEnabled) {
        this.defectForm.enable({ emitEvent: false });

        if (!this.canProvision) FormUtils.getFormControl<PcfDefect>(this.defectForm, 'states').disable({ emitEvent: false });
      } else {
        this.defectForm.disable({ emitEvent: false });
      }
    });

    FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isDiaryModuleEnabled').valueChanges.subscribe(isEnabled => {
      if (isEnabled) {
        this.diaryForm.enable({ emitEvent: false });

        if (this.isTemplate) {
          FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'defaultReportResource').disable({ emitEvent: false });
          FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'defaultReportPath').disable({ emitEvent: false });
        }

        if (!this.canProvision) FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'states').disable({ emitEvent: false });
      } else {
        this.diaryForm.disable({ emitEvent: false });
      }
    });

    FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isLeanModuleEnabled').valueChanges.subscribe(isEnabled => {
      if (isEnabled) {
        this.leanForm.enable({ emitEvent: false });

        if (!this.canProvision) FormUtils.getFormControl<PcfLean>(this.leanForm, 'states').disable({ emitEvent: false });
      } else {
        this.leanForm.disable({ emitEvent: false });
      }
    });

    FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrPrintEnabled').valueChanges.subscribe(isEnabled => {
      if (isEnabled) {
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrPosition').enable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrSize').enable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrOffsetVertical').enable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrOffsetHorizontal').enable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrScaling').enable({ emitEvent: false });
      } else {
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrPosition').disable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrSize').disable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrOffsetVertical').disable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrOffsetHorizontal').disable({ emitEvent: false });
        FormUtils.getFormControl<PcfPlan>(this.planForm, 'qrScaling').disable({ emitEvent: false });
      }
    });
  }

  private resetFormGroup(model: PartialProjectConfigFormGroupModel, markAsPristine: boolean = true) {
    this.generalForm.enable({ emitEvent: false });
    this.metadataForm.enable({ emitEvent: false });
    this.planForm.enable({ emitEvent: false });
    this.bimForm.enable({ emitEvent: false });
    this.defectForm.enable({ emitEvent: false });
    this.diaryForm.enable({ emitEvent: false });
    this.leanForm.enable({ emitEvent: false });
    this.msTeamsForm.enable({ emitEvent: false });

    this.form.patchValue(model);
    if (markAsPristine) {
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
  }

  private disableControls() {
    if (this._originalConfig?.bim?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isBimModuleEnabled').disable({ emitEvent: false });
    if (this._originalConfig?.constructionDiary?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isDiaryModuleEnabled').disable({ emitEvent: false });
    if (this._originalConfig?.defect?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isDefectModuleEnabled').disable({ emitEvent: false });
    if (this._originalConfig?.gallery?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isGalleryModuleEnabled').disable({ emitEvent: false });
    if (this._originalConfig?.lean?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isLeanModuleEnabled').disable({ emitEvent: false });
    if (this._originalConfig?.plan?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isPlanModuleEnabled').disable({ emitEvent: false });
    if (this._originalConfig?.roomBook?.isLocked || !this.canProvision)
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isRoomBookModuleEnabled').disable({ emitEvent: false });

    if (this.isTemplate) {
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'template').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'organization').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'defaultReportResource').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'defaultReportPath').disable({ emitEvent: false });
    } else if (this.isNew) {
      FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'defaultReportResource').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfDiary>(this.diaryForm, 'defaultReportPath').disable({ emitEvent: false });
    } else {
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'name').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'template').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'organization').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfGeneral>(this.generalForm, 'isNameComputed').disable({ emitEvent: false });
      FormUtils.getFormControl<PcfMsTeams>(this.msTeamsForm, 'isTeamPublic').disable({ emitEvent: false });
    }
  }

  private disableControlsAfterSave() {
    if (!this.isNew && !this.isTemplate) {
      const msTeamsChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'msTeamsChannels');
      const portalChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'portalChannels');

      for (const channel of msTeamsChannels.controls.concat(portalChannels.controls) as UntypedFormGroup[]) {
        FormUtils.getFormControl<PcfChannel>(channel, 'path').disable();
        FormUtils.getFormControl<PcfChannel>(channel, 'isHidden').disable();
      }
    }
  }

  private getEmptyFormGroup(): ProjectConfigFormGroupModel {
    return {
      general: {
        number: '',
        name: '',
        template: null,
        organization: null,
        code: '',
        description: '',
        isBimModuleEnabled: false,
        isDefectModuleEnabled: false,
        isDiaryModuleEnabled: false,
        isGalleryModuleEnabled: false,
        isLeanModuleEnabled: false,
        isPlanModuleEnabled: false,
        isRoomBookModuleEnabled: false,
        logo: null,
        logoSmall: null,
        isNameComputed: false,
      },
      metadata: {
        start: null,
        end: null,
        address: null,
        location: null,
        administration: '',
        externalId: '',
        externalSystemName: '',
        mainClient: null,
      },
      roles: {
        roles: [],
      },
      msTeams: {
        isTeamPublic: true,
        supportSharePointGroupPrivileges: false,
      },
      structure: {
        msTeamsChannels: [],
        portalChannels: [],
      },
      plan: {
        selectedSchema: null,
        qrPrintEnabled: false,
        qrPosition: DocumentPosition.topLeft,
        qrSize: 30,
        qrOffsetVertical: 0,
        qrOffsetHorizontal: 0,
        qrScaling: false,
      },
      bim: {
        selectedSchema: null,
        deadlineNotifications: false,
        states: [],
      },
      defect: {
        deadlineNotifications: false,
        states: [],
      },
      diary: {
        defaultReportResource: '',
        defaultReportPath: '',
        states: [],
      },
      lean: {
        weekCount: '',
        states: [],
      },
    };
  }

  private getFormGroupForConfig(config: ProjectConfigModel): PartialProjectConfigFormGroupModel {
    const planSchemaId = config?.plan?.metadata?.id;
    const bimSchemaId = config?.bim?.metadata?.id;

    return {
      general: {
        number: config.project?.number,
        name: config.project?.name ?? config.metadata?.name,
        code: config.metadata?.code,
        description: config.project?.description ?? config.metadata?.description,
        isNameComputed: config.settings.isTeamNameComputed,
        isBimModuleEnabled: config.bim.isEnabled,
        isDefectModuleEnabled: config.defect.isEnabled,
        isDiaryModuleEnabled: config.constructionDiary.isEnabled,
        isGalleryModuleEnabled: config.gallery.isEnabled,
        isLeanModuleEnabled: config.lean.isEnabled,
        isPlanModuleEnabled: config.plan.isEnabled,
        isRoomBookModuleEnabled: config.roomBook.isEnabled,
        logo: config.project?.logo,
        logoSmall: config.project?.logoSmall,
      },
      metadata: {
        start: config.project?.start,
        end: config.project?.end,
        address: config.project?.address,
        location: config.project?.location,
        administration: config.project?.administration,
        externalId: config.project?.externalId,
        externalSystemName: config.project?.externalSystemName,
        mainClient: this.projectOrganizations.find(o => o.id == config.project.mainOrganizationId),
      },
      bim: {
        deadlineNotifications: config.bim.areDeadlineNotificationsEnabled,
        selectedSchema: bimSchemaId != null ? this.planSchemes.find(s => s.id == bimSchemaId) : null,
      },
      defect: {
        deadlineNotifications: config.defect.areDeadlineNotificationsEnabled,
      },
      diary: {
        defaultReportResource: config.constructionDiary.defaultReportResource,
        defaultReportPath: config.constructionDiary.defaultReportPath,
      },
      lean: {
        weekCount: config.lean.previewWeeks,
      },
      plan: {
        selectedSchema: planSchemaId != null ? this.planSchemes.find(s => s.id == planSchemaId) : null,
        qrPrintEnabled: config.plan.qrConfiguration && config.plan.qrConfiguration.position != QrCodeAnchor.None,
        qrPosition: this.mapQrCodeAnchor(config.plan.qrConfiguration?.position),
        qrSize: config.plan.qrConfiguration?.size ?? 30,
        qrOffsetVertical: config.plan.qrConfiguration?.offsetVertical ?? 0,
        qrOffsetHorizontal: config.plan.qrConfiguration?.offsetHorizontal ?? 0,
        qrScaling: !!config.plan.qrConfiguration?.scaling,
      },
      msTeams: {
        isTeamPublic: !!config.settings?.isTeamPublic,
        supportSharePointGroupPrivileges: !!config.settings?.isSharePointUserGroupManager,
      },
    };
  }

  private clearStatesInForm<T extends { states: unknown[] }>(form: UntypedFormGroup) {
    const states = FormUtils.getFormControl<T, UntypedFormArray>(form, 'states');
    states.clear();
  }

  private clearStates() {
    this.clearStatesInForm<PcfBim>(this.bimForm);
    this.clearStatesInForm<PcfDefect>(this.defectForm);
    this.clearStatesInForm<PcfDiary>(this.diaryForm);
    this.clearStatesInForm<PcfLean>(this.leanForm);
  }

  private clearRoles() {
    const roles = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.rolesForm, 'roles');
    roles.clear();
  }

  private clearStructure() {
    const msTeamsChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'msTeamsChannels');
    msTeamsChannels.clear();

    const portalChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'portalChannels');
    portalChannels.clear();
  }

  private reset() {
    this._id = null;
    this._isNew = true;
    this._isTemplate = false;

    this._originalConfig = null;

    this._privileges = [];
    this._planSchemes = [];
    this._teamTemplates = [];
    this._globalOrganizations = [];

    this._portalTemplates = [];
    this._activePortalTemplates = [];
    this._msTeamsTemplates = [];
    this._activeMsTeamsTemplates = [];

    this._singleFolderPrivileges = [];
    this._multiFolderPrivileges = [];

    this._projectUsers = [];
    this._roleAndUserData = {};

    this.clearStates();
    this.clearRoles();
    this.clearStructure();

    this.initializedSubject.next(false);

    this.resetFormGroup(this.getEmptyFormGroup());
  }

  private async loadSelections(projectOrTemplateId: string) {
    await using(new BusyScope(this), async _ => {
      const projectId = this.isTemplate ? undefined : projectOrTemplateId;
      this._privileges = await Utils.enhanceException(this.apiService.getUserPrivileges(projectId), 'privilege');

      const isNewProject = !this.isTemplate && this.isNew;
      const isExistingProject = !this.isTemplate && !this.isNew;

      // do not await here
      const schemes = this.canProvision
        ? Utils.enhanceException(this.apiService.getPlansTemplates(projectId), 'planSchemes')
        : Promise.resolve<PlanSchemaMetadata[]>([]);
      const templates = isNewProject
        ? Utils.enhanceException(this.apiService.getTeamsTemplates(), 'templates')
        : Promise.resolve<TeamConfigMetadata[]>([]);
      const globalOrganizations = isNewProject
        ? Utils.enhanceException(this.apiService.getOrganizations(), 'organizations')
        : Promise.resolve<OrganizationModel[]>([]);
      const projectOrganizations = isExistingProject
        ? Utils.enhanceException(this.apiService.getOrganizationsForProject(projectId), 'organizations')
        : Promise.resolve<OrganizationModel[]>([]);

      this._planSchemes = await schemes;
      this._teamTemplates = await templates;
      this._globalOrganizations = await globalOrganizations;
      this._projectOrganizations = await projectOrganizations;
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.' + e.translationKey, e);
    });
  }

  private async refreshPlanSchemes() {
    if (this.canProvision) {
      await using(new BusyScope(this), async _ => {
        const schemes = await this.apiService.getPlansTemplates();
        this._planSchemes = schemes;

        const selectedBimSchema = FormUtils.getFormValue<PcfBim, PlanSchemaMetadata>(this.bimForm, 'selectedSchema');
        if (selectedBimSchema?.id) {
          const updatedSchema = schemes.find(schema => schema.id == selectedBimSchema.id);
          FormUtils.getFormControl<PcfBim>(this.bimForm, 'selectedSchema').setValue(updatedSchema);
        }

        const selectedPlanSchema = FormUtils.getFormValue<PcfPlan, PlanSchemaMetadata>(this.planForm, 'selectedSchema');
        if (selectedPlanSchema.id) {
          const updatedSchema = schemes.find(schema => schema.id == selectedPlanSchema.id);
          FormUtils.getFormControl<PcfPlan>(this.planForm, 'selectedSchema').setValue(updatedSchema);
        }
      }).catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.planSchemes', e);
      });
    }
  }

  private async loadProject(projectId: string) {
    return await using(new BusyScope(this), async _ => {
      if (!projectId) {
        await this.loadTemplate(null, true);
        return;
      }

      const [assignedUsers, config] = await Promise.all([
        Utils.enhanceException(this.apiService.getAssignedUsersForProject(projectId), 'users'),
        Utils.enhanceException(this.apiService.getProjectConfig(projectId), 'projectConfig'),
      ]);

      this._state = config.project.state;

      for (const user of assignedUsers) {
        this._roleAndUserData[user.id] = {
          name: user.username,
          description: user.emailAddress,
        };

        this._projectUsers.push(user);
      }

      this._originalConfig = config;
      if (this.canProvision) this.loadTeamConfig(config);

      const formModel = this.getFormGroupForConfig(config);
      this.resetFormGroup(formModel);
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + (e.translationKey ?? 'settings'),
        e
      );

      // todo use or not?
      // this.router.navigate(pathFragmentsTo());
      this.reset();
    });
  }

  private async loadTemplate(templateId: string, isTemplateSelectionForNewProject: boolean = false) {
    await using(new BusyScope(this), async _ => {
      const config = !templateId
        ? await this.apiService.getEmptyTemplateConfiguration()
        : await this.apiService.getTemplateConfiguration(templateId);

      this._originalConfig = config;

      this.loadTeamConfig(config, isTemplateSelectionForNewProject);

      const formModel = this.getFormGroupForConfig(config);

      if (isTemplateSelectionForNewProject) {
        formModel.general.name = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'name');
        formModel.general.number = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'number');
        formModel.general.description = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'description');
        formModel.general.logo = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'logo');
        formModel.general.logoSmall = FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'logoSmall');
      }

      this.resetFormGroup(formModel, !isTemplateSelectionForNewProject);
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.template', e);

      this.reset();
    });
  }

  private addChannelTemplate<T extends ProjectConfigMSTeamsResource | ProjectConfigDocumentsResource>(
    channelTemplate: T,
    templates: T[],
    activeTemplates: T[],
    isPortalResource: boolean = false
  ) {
    let id = Utils.createUUID();
    if (channelTemplate.resourceTemplateType == ResourceTemplateType.Default) {
      this._channelTemplateMapping[id] = channelTemplate.id;
      const index = templates.indexOf(channelTemplate);
      if (index >= 0) {
        templates.splice(index, 1);
        activeTemplates.push(channelTemplate);
      }
    }

    const channelGroup = this.getChannelGroup({ ...channelTemplate, id }, isPortalResource);
    this.buildFoldersRecursive(
      channelTemplate.folders ?? [],
      FormUtils.getFormControl<PcfChannel, UntypedFormArray>(channelGroup, 'folders')
    );

    return channelGroup;
  }

  private getRoleGroup(role: IProjectConfigTeamRole) {
    const isSystemRole = !!role.isSystemRole;
    const description = !isSystemRole
      ? role.description
      : this.translateService.instant('systemRoles.description.' + role.id.toLowerCase());

    this._roleAndUserData[role.id] = {
      name: this.tryTranslatePipe.transform('systemRoles.' + role.name, role.name),
      description,
      isSystemRole: role.isSystemRole,
    };

    const everyoneId = AppConfigService.settings.defaultRoleIds.everyone;
    const roleConfig: DictWithKeysOf<PcfRole> = {
      id: [role.id],
      name: [role.name],
      description: [{ value: description, disabled: isSystemRole }],
      privilegeGroups: this.formBuilder.array(
        this._functionalPrivilegeGroups.map(privilegeGroupModel => {
          const setPrivileges: number[] = [];
          const privilegesForm = this.formBuilder.array(
            privilegeGroupModel.privileges.map(privilegeModel => {
              const privilegeConfig: DictWithKeysOf<PcfRolePrivilege> = {
                isSet: [CheckboxState.unchecked],
                number: [privilegeModel.number],
              };

              if (role.privilege.includes(privilegeModel.number)) setPrivileges.push(privilegeModel.number);

              const privilegeForm = this.formBuilder.group(privilegeConfig);

              FormUtils.getFormControl<PcfRolePrivilege>(privilegeForm, 'isSet').valueChanges.subscribe(
                (isSet: CheckboxState) => {
                  let privilegesToUncheck: number[] = [];
                  let dependentCheckboxesState = CheckboxState.unchecked;
                  if (isSet == CheckboxState.checked) {
                    dependentCheckboxesState = CheckboxState.indeterminate;

                    privilegesToUncheck = privilegeGroupModel.privileges
                      .filter(p => p.includes.includes(privilegeModel.number))
                      .map(p => p.number);
                  }

                  const privilegeFormValues: PcfRolePrivilege[] = privilegesForm.value;
                  for (const privilegeFormValue of privilegeFormValues) {
                    if (privilegeFormValue.number == privilegeModel.number) {
                      privilegeFormValue.isSet = isSet;
                    } else if (privilegeModel.includes.includes(privilegeFormValue.number)) {
                      let state = dependentCheckboxesState;
                      if (state == CheckboxState.unchecked) {
                        const isDependentOnOther = privilegeGroupModel.privileges
                          .filter(pm => pm.number != privilegeModel.number && pm.includes.includes(privilegeFormValue.number))
                          .some(otherDependentPrivilege => {
                            const otherDependentPrivilegeState = privilegeFormValues.find(
                              pfv => pfv.number == otherDependentPrivilege.number
                            ).isSet;

                            return otherDependentPrivilegeState == CheckboxState.checked;
                          });

                        if (isDependentOnOther) state = CheckboxState.indeterminate;
                      }

                      privilegeFormValue.isSet = state;
                    } else if (privilegesToUncheck.includes(privilegeFormValue.number)) {
                      privilegeFormValue.isSet = CheckboxState.unchecked;
                    }
                  }

                  privilegesForm.setValue(privilegeFormValues, { emitEvent: false });
                }
              );

              return privilegeForm;
            })
          );

          // set checked privileges to trigger indeterminate for dependent
          for (const privilegeNumber of setPrivileges) {
            const privilegeForm = privilegesForm.controls.find(
              (c: UntypedFormGroup) => FormUtils.getFormValue<PcfRolePrivilege>(c, 'number') == privilegeNumber
            ) as UntypedFormGroup;

            FormUtils.getFormControl<PcfRolePrivilege>(privilegeForm, 'isSet').setValue(CheckboxState.checked);
          }

          const privilegeGroupConfig: DictWithKeysOf<PcfPrivilegeGroup> = {
            privileges: privilegesForm,
          };

          return this.formBuilder.group(privilegeGroupConfig);
        })
      ),
      roles: [{ value: role.roles ?? [], disabled: isSystemRole }],
      users: [{ value: role.users ?? [], disabled: role.id == everyoneId }],
      isReadOnly: [!!role.isReadOnly],
      isHidden: [!!role.isHidden],
      isSystemRole: [{ value: isSystemRole, disabled: true }],
    };

    const roleGroup = this.formBuilder.group(roleConfig);

    FormUtils.getFormControl<PcfRole>(roleGroup, 'name').valueChanges.subscribe(name => {
      const data = this._roleAndUserData[role.id];
      if (data) data.name = this.tryTranslatePipe.transform('systemRoles.' + name, name);
    });

    FormUtils.getFormControl<PcfRole>(roleGroup, 'description').valueChanges.subscribe(description => {
      const data = this._roleAndUserData[role.id];
      if (data) data.description = description;
    });

    return roleGroup;
  }

  private buildRoles(roles: ProjectConfigTeamRole[]) {
    const rolesForm = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.rolesForm, 'roles');
    rolesForm.clear();

    for (const role of roles) {
      rolesForm.push(this.getRoleGroup(role));
    }
  }

  private buildStates(config: ProjectConfigModel) {
    this.buildDefectStates(config.defect.stateConfiguration?.list ?? []);
    this.buildDiaryStates(config.constructionDiary.stateConfiguration?.list ?? []);
    this.buildIssueStates(config.bim.stateConfiguration?.list ?? []);
    this.buildWorkpackageStates(config.lean.stateConfiguration?.list ?? []);
  }

  private buildDefectStates(states: DefectStateProjectConfigState[]) {
    const defectStatesForm = FormUtils.getFormControl<PcfDefect, UntypedFormArray>(this.defectForm, 'states');
    this.buildEntityStates(states, defectStatesForm, this.defectStatePredefinedRoles, this.defectStatePermissions);
  }

  private buildDiaryStates(states: ConstructionDiaryStateProjectConfigState[]) {
    const diaryStatesForm = FormUtils.getFormControl<PcfDiary, UntypedFormArray>(this.diaryForm, 'states');
    this.buildEntityStates(states, diaryStatesForm, this.diaryStatePredefinedRoles, this.diaryStatePermissions);
  }

  private buildIssueStates(states: IssueStateProjectConfigState[]) {
    const issueStatesForm = FormUtils.getFormControl<PcfBim, UntypedFormArray>(this.bimForm, 'states');
    this.buildEntityStates(states, issueStatesForm, this.issueStatePredefinedRoles, this.issueStatePermissions);
  }

  private buildWorkpackageStates(states: WorkpackageStateProjectConfigState[]) {
    const workpackageStatesForm = FormUtils.getFormControl<PcfLean, UntypedFormArray>(this.leanForm, 'states');
    this.buildEntityStates(
      states,
      workpackageStatesForm,
      this.workpackageStatePredefinedRoles,
      this.workpackageStatePermissions
    );
  }

  private buildEntityStates<T>(
    states: ProjectConfigState<T>[],
    formArray: UntypedFormArray,
    predefinedRoles: StateSchemaRole[],
    permissions: ProjectConfigPermissionDescription[]
  ) {
    formArray.clear();

    const roles = FormUtils.getFormValue<PcfRoles, PcfRole[]>(this.rolesForm, 'roles');
    const mergedStateSchemaRoles = StateSchemaService.getMergedStateSchemaRoles(
      predefinedRoles.concat(roles),
      this.translateService
    );

    for (const state of states) formArray.push(StateSchemaService.getStateGroup(state, mergedStateSchemaRoles, permissions));
  }

  private addRoleToStates(roleId: string) {
    this.addRoleToStatesGroup<DefectState>(roleId, this.defectForm, this.defectStatePermissions);
    this.addRoleToStatesGroup<ConstructionDiaryState>(roleId, this.diaryForm, this.diaryStatePermissions);
    this.addRoleToStatesGroup<IssueState>(roleId, this.bimForm, this.issueStatePermissions);
    this.addRoleToStatesGroup<WorkpackageState>(roleId, this.leanForm, this.workpackageStatePermissions);
  }

  private addRoleToStatesGroup<T>(roleId: string, form: UntypedFormGroup, permissions: ProjectConfigPermissionDescription[]) {
    const statesFormArray = FormUtils.getFormControl<PcfStatesGroup<T>, UntypedFormArray>(form, 'states');
    for (const statesForm of statesFormArray.controls as UntypedFormGroup[]) {
      const rolesFormArray = FormUtils.getFormControl<ConfigState<T>, UntypedFormArray>(statesForm, 'roles');
      rolesFormArray.push(StateSchemaService.getStateRoleGroup(roleId, permissions));
    }
  }

  private removeRoleFromStates(id: string) {
    this.removeRoleFromStatesGroup(id, this.defectForm);
    this.removeRoleFromStatesGroup(id, this.diaryForm);
    this.removeRoleFromStatesGroup(id, this.bimForm);
    this.removeRoleFromStatesGroup(id, this.leanForm);
  }

  private removeRoleFromStatesGroup<T>(id: string, form: UntypedFormGroup) {
    const statesFormArray = FormUtils.getFormControl<PcfStatesGroup<T>, UntypedFormArray>(form, 'states');
    for (const statesForm of statesFormArray.controls as UntypedFormGroup[]) {
      const rolesFormArray = FormUtils.getFormControl<ConfigState<T>, UntypedFormArray>(statesForm, 'roles');

      const index = rolesFormArray.controls.findIndex((roleForm: UntypedFormGroup) => {
        const roleKeyFormControl = FormUtils.getFormControl<StateRole>(roleForm, 'key');
        return roleKeyFormControl.value == id;
      });

      if (index >= 0) rolesFormArray.removeAt(index);
    }
  }

  private buildStructure(config: ProjectConfigModel, isTemplateSelectionForNewProject: boolean = false) {
    this.clearStructure();

    let msTeamsChannels = config.msTeams?.resources ?? [];
    let portalChannels = config.document?.resources ?? [];

    if (config.project != null || isTemplateSelectionForNewProject) {
      const [activeMsTeamsTemplates, msTeamsTemplates, requriedMsTeamsTemplates] = this.buildTemplates(
        config.msTeams?.resources
      );
      this._msTeamsTemplates = msTeamsTemplates;

      const [activePortalTemplates, portalTemplates, requriedPortalTemplates] = this.buildTemplates(config.document?.resources);
      this._portalTemplates = portalTemplates;

      if (isTemplateSelectionForNewProject) {
        this._activeMsTeamsTemplates = activeMsTeamsTemplates;
        msTeamsChannels = requriedMsTeamsTemplates.concat(
          activeMsTeamsTemplates.map(t => {
            const id = Utils.createUUID();
            this._channelTemplateMapping[id] = t.id;
            return new ProjectConfigMSTeamsResource({ ...t, id });
          })
        );

        this._activePortalTemplates = activePortalTemplates;
        portalChannels = requriedPortalTemplates.concat(
          activePortalTemplates.map(t => {
            const id = Utils.createUUID();
            this._channelTemplateMapping[id] = t.id;
            return new ProjectConfigDocumentsResource({ ...t, id });
          })
        );
      }
    }

    this.buildChannels(
      msTeamsChannels,
      FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'msTeamsChannels'),
      false
    );

    this.buildChannels(
      portalChannels,
      FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'portalChannels'),
      true
    );
  }

  private buildTemplates<T extends ProjectConfigMSTeamsResource | ProjectConfigDocumentsResource>(channelTemplates: T[]) {
    const activeTemplates: T[] = [];
    const templates: T[] = [];
    const requiredTemplates: T[] = [];

    for (const channel of channelTemplates ?? []) {
      switch (channel.resourceTemplateType) {
        case ResourceTemplateType.Default:
          activeTemplates.push(channel);
          break;
        case ResourceTemplateType.Multiple:
          templates.push(channel);
          break;
        case ResourceTemplateType.Required:
          requiredTemplates.push(channel);
          break;
      }
    }

    return [activeTemplates, templates, requiredTemplates];
  }

  private buildChannels(
    channels: ProjectConfigMSTeamsResource[] | ProjectConfigDocumentsResource[],
    channelsForm: UntypedFormArray,
    isPortalResource: boolean
  ) {
    for (const channel of channels) {
      const channelForm = this.getChannelGroup(channel, isPortalResource, true);
      const channelFoldersArray = FormUtils.getFormControl<PcfChannel, UntypedFormArray>(channelForm, 'folders');

      this.buildFoldersRecursive(channel.folders, channelFoldersArray, true);

      channelsForm.push(channelForm);
    }
  }

  private buildFoldersRecursive(
    folders: ProjectConfigMSTeamsFolder[] | ProjectConfigDocumentsFolder[],
    foldersForm: UntypedFormArray,
    isForBuild: boolean = false
  ) {
    for (const folder of folders) {
      const folderForm = this.getFolderGroup(folder);
      const subFoldersArray = FormUtils.getFormControl<PcfFolder, UntypedFormArray>(folderForm, 'folders');

      this.buildFoldersRecursive(folder.folders, subFoldersArray, isForBuild);

      foldersForm.push(folderForm);
    }
  }

  private buildDirectoryGroup(group: DictWithKeysOf<PcfDirectory>) {
    const directoryForm = this.formBuilder.group(group);

    const directoryPathControl = FormUtils.getFormControl<PcfDirectory>(directoryForm, 'path');
    directoryPathControl.valueChanges.subscribe((path: string) => {
      directoryPathControl.setValue(path.trimStart(), { emitEvent: false });
    });

    return directoryForm;
  }

  private updateStructureAfterSave() {
    const msTeamsChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'msTeamsChannels');
    this.updateChannelsAfterSave(msTeamsChannels);

    const documentChannels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'portalChannels');
    this.updateChannelsAfterSave(documentChannels);
  }

  private updateChannelsAfterSave(channels: UntypedFormArray) {
    for (const channel of channels.controls as UntypedFormGroup[]) {
      const folders = FormUtils.getFormControl<PcfChannel, UntypedFormArray>(channel, 'folders');
      this.updateFoldersAfterSave(folders);
    }
  }

  private updateFoldersAfterSave(folders: UntypedFormArray) {
    for (const folder of folders.controls as UntypedFormGroup[]) {
      const newPath = FormUtils.getFormValue<PcfFolder>(folder, 'path');
      const oldPathControl = FormUtils.getFormControl<PcfFolder>(folder, 'oldPath');
      oldPathControl.setValue(newPath);

      const subFolders = FormUtils.getFormControl<PcfFolder, UntypedFormArray>(folder, 'folders');
      this.updateFoldersAfterSave(subFolders);
    }
  }

  private loadTeamConfig(config: ProjectConfigModelWithPrivileges, isTemplateSelectionForNewProject: boolean = false) {
    let groupedFunctionalPrivileges: Partial<Record<PrivilegeEnumGroupData, FunctionalPrivilegeModel[]>> = {};
    this._functionalPrivilegeGroups = Object.entries(
      config.functionalPrivileges.reduce((grouped, privilege) => {
        grouped[privilege.groupModuleType] = grouped[privilege.groupModuleType] ?? [];
        grouped[privilege.groupModuleType].push(privilege);
        return grouped;
      }, groupedFunctionalPrivileges)
    ).map(([groupType, privileges]) => ({
      groupType: groupType as PrivilegeEnumGroupData,
      privileges,
    }));

    this._singleFolderPrivileges = config.privileges.filter(p => p.single);
    this._multiFolderPrivileges = config.privileges.filter(p => !p.single);

    this.buildRoles(config.assignments.roles);
    this.buildStructure(config, isTemplateSelectionForNewProject);
    this.buildStates(config);
  }

  private parseProject() {
    return new ProjectModelWithLogos({
      id: this._id,
      name: this.computedName,
      number: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'number'),
      description: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'description'),
      externalSystemName: FormUtils.getFormValue<PcfMetadata>(this.metadataForm, 'externalSystemName'),
      externalId: FormUtils.getFormValue<PcfMetadata>(this.metadataForm, 'externalId'),
      administration: FormUtils.getFormValue<PcfMetadata>(this.metadataForm, 'administration'),
      logo: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'logo'),
      logoSmall: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'logoSmall'),
      start: FormUtils.getFormValue<PcfMetadata>(this.metadataForm, 'start'),
      end: FormUtils.getFormValue<PcfMetadata>(this.metadataForm, 'end'),
      address: this.address,
      location: this.location,
      mainOrganizationId: FormUtils.getFormValue<PcfMetadata, OrganizationModel>(this.metadataForm, 'mainClient')?.id,
    });
  }

  private getFolderConfigurations(folders: UntypedFormArray): ProjectConfigDocumentsFolder[] {
    return folders.controls.map((folder: UntypedFormGroup) => {
      const pathControl = FormUtils.getFormControl<PcfFolder>(folder, 'path');
      const name = pathControl.value?.trim();
      pathControl.setValue(name);

      return new ProjectConfigDocumentsFolder({
        name,
        oldName: FormUtils.getFormValue<PcfFolder>(folder, 'oldPath'),
        folders: this.getFolderConfigurations(FormUtils.getFormControl<PcfFolder, UntypedFormArray>(folder, 'folders')),
        privilege: IndividualPrivilegeService.parseIndividualPrivileges(
          FormUtils.getFormControl<PcfFolder, UntypedFormArray>(folder, 'individualPrivileges')
        ),
      });
    });
  }

  private getTabConfigurations(channel: UntypedFormGroup) {
    return FormUtils.getFormControl<PcfChannel, UntypedFormArray>(channel, 'tabs').controls.map((tab: UntypedFormGroup) => {
      const entityId = FormUtils.getFormValue<PcfChannelTab>(tab, 'entityId');

      return new ProjectConfigResourceTab({
        appId: FormUtils.getFormValue<PcfChannelTab>(tab, 'appId'),
        entityId: entityId,
        contentUrl: FormUtils.getFormValue<PcfChannelTab>(tab, 'contentUrl'),
        displayName: FormUtils.getFormValue<PcfChannelTab>(tab, 'displayName'),
        webSiteUrl: FormUtils.getFormValue<PcfChannelTab>(tab, 'webSiteUrl'),
      });
    });
  }

  private getTeamsResources() {
    const resources: ProjectConfigMSTeamsResource[] = [];

    const channels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'msTeamsChannels');
    for (const channel of channels.controls as UntypedFormGroup[]) {
      const pathControl = FormUtils.getFormControl<PcfChannel>(channel, 'path');
      const name = pathControl.value?.trim();
      pathControl.setValue(name);

      resources.push(
        new ProjectConfigMSTeamsResource({
          id: FormUtils.getFormValue<PcfChannel>(channel, 'id'),
          folders: this.getFolderConfigurations(FormUtils.getFormControl<PcfFolder, UntypedFormArray>(channel, 'folders')),
          tabs: this.getTabConfigurations(channel),
          resourceTemplateType: FormUtils.getFormValue<PcfChannel>(channel, 'templateType'),
          name,
        })
      );
    }

    return resources;
  }

  private getPortalResources() {
    const resources: ProjectConfigDocumentsResource[] = [];

    const channels = FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.structureForm, 'portalChannels');
    for (const channel of channels.controls as UntypedFormGroup[]) {
      const pathControl = FormUtils.getFormControl<PcfChannel>(channel, 'path');
      const name = pathControl.value?.trim();
      pathControl.setValue(name);

      resources.push(
        new ProjectConfigDocumentsResource({
          id: FormUtils.getFormValue<PcfChannel>(channel, 'id'),
          channelType: FormUtils.getFormValue<PcfChannel>(channel, 'isHidden') ? ChannelType.Hidden : ChannelType.Channel,
          folders: this.getFolderConfigurations(FormUtils.getFormControl<PcfFolder, UntypedFormArray>(channel, 'folders')),
          tabs: this.getTabConfigurations(channel),
          privilege: IndividualPrivilegeService.parseIndividualPrivileges(
            FormUtils.getFormControl<PcfChannel, UntypedFormArray>(channel, 'individualPrivileges')
          ),
          resourceTemplateType: FormUtils.getFormValue<PcfChannel>(channel, 'templateType'),
          name,
        })
      );
    }

    return resources;
  }

  private getRoles() {
    const roles: ProjectConfigTeamRole[] = [];

    const rolesForm = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.rolesForm, 'roles');
    for (const roleForm of rolesForm.controls as UntypedFormGroup[]) {
      const isSystemRole = FormUtils.getFormValue<PcfRole>(roleForm, 'isSystemRole');

      roles.push(
        new ProjectConfigTeamRole({
          id: FormUtils.getFormValue<PcfRole>(roleForm, 'id'),
          name: FormUtils.getFormValue<PcfRole>(roleForm, 'name'),
          description: isSystemRole ? null : FormUtils.getFormValue<PcfRole>(roleForm, 'description'),
          isHidden: FormUtils.getFormValue<PcfRole>(roleForm, 'isHidden'),
          isReadOnly: FormUtils.getFormValue<PcfRole>(roleForm, 'isReadOnly'),
          roles: FormUtils.getFormValue<PcfRole>(roleForm, 'roles'),
          users: FormUtils.getFormValue<PcfRole>(roleForm, 'users'),
          privilege: FormUtils.getFormValue<PcfRole, PcfPrivilegeGroup[]>(roleForm, 'privilegeGroups').flatMap(privilegeGroup =>
            privilegeGroup.privileges
              .filter(privilege => privilege.isSet == CheckboxState.checked)
              .map(privilege => privilege.number)
          ),
        })
      );
    }

    return roles;
  }

  private getProjectConfig(metadata: ProjectConfigMetadataModel, project: ProjectModelWithLogos = null) {
    const diaryDefaultReportPath = FormUtils.getFormValue<PcfDiary>(this.diaryForm, 'defaultReportPath');
    const diaryDefaultReportResource = FormUtils.getFormValue<PcfDiary>(this.diaryForm, 'defaultReportResource');
    const bimSchema = FormUtils.getFormValue<PcfBim, PlanSchemaMetadata>(this.bimForm, 'selectedSchema');
    const planSchema = FormUtils.getFormValue<PcfPlan, PlanSchemaMetadata>(this.planForm, 'selectedSchema');
    const isPlanQrPrintEnabled = FormUtils.getFormValue<PcfPlan>(this.planForm, 'qrPrintEnabled');

    return new ProjectConfigModel({
      project,
      metadata,
      assignments: new ProjectConfigAssignments({
        roles: this.getRoles(),
      }),
      settings: new TeamConfigDefinitionSettings({
        isSharePointUserGroupManager: FormUtils.getFormValue<PcfMsTeams>(this.msTeamsForm, 'supportSharePointGroupPrivileges'),
        isTeamNameComputed: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isNameComputed'),
        isTeamPublic: FormUtils.getFormValue<PcfMsTeams>(this.msTeamsForm, 'isTeamPublic'),
      }),

      document: new ProjectConfigDocumentModule({
        resources: this.getPortalResources(),
      }),
      msTeams: new ProjectConfigMSTeamsModule({
        resources: this.getTeamsResources(),
      }),

      bim: new ProjectConfigBimModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isBimModuleEnabled'),
        areDeadlineNotificationsEnabled: FormUtils.getFormValue<PcfBim>(this.bimForm, 'deadlineNotifications'),
        stateConfiguration: new IssueStateProjectConfigStateConfiguration({
          list: StateSchemaService.parseStates<IssueState>(
            FormUtils.getFormControl<PcfBim, UntypedFormArray>(this.bimForm, 'states')
          ).map(state => new IssueStateProjectConfigState(state)),
        }),
        metadata: !bimSchema
          ? null
          : new ProjectConfigSchemaModel({
              id: bimSchema.id,
              name: bimSchema.name,
              description: bimSchema.description,
            }),
      }),
      constructionDiary: new ProjectConfigConstructionDiaryModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isDiaryModuleEnabled'),
        defaultReportPath: !diaryDefaultReportPath ? null : diaryDefaultReportPath,
        defaultReportResource: !diaryDefaultReportResource ? null : diaryDefaultReportResource,
        stateConfiguration: new ConstructionDiaryStateProjectConfigStateConfiguration({
          list: StateSchemaService.parseStates<ConstructionDiaryState>(
            FormUtils.getFormControl<PcfDiary, UntypedFormArray>(this.diaryForm, 'states')
          ).map(state => new ConstructionDiaryStateProjectConfigState(state)),
        }),
      }),
      defect: new ProjectConfigDefectModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isDefectModuleEnabled'),
        areDeadlineNotificationsEnabled: FormUtils.getFormValue<PcfDefect>(this.defectForm, 'deadlineNotifications'),
        stateConfiguration: new DefectStateProjectConfigStateConfiguration({
          list: StateSchemaService.parseStates<DefectState>(
            FormUtils.getFormControl<PcfDefect, UntypedFormArray>(this.defectForm, 'states')
          ).map(state => new DefectStateProjectConfigState(state)),
        }),
      }),
      gallery: new ProjectConfigGalleryModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isGalleryModuleEnabled'),
      }),
      lean: new ProjectConfigLeanModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isLeanModuleEnabled'),
        previewWeeks: Number.parseInt(FormUtils.getFormValue<PcfLean>(this.leanForm, 'weekCount')),
        stateConfiguration: new WorkpackageStateProjectConfigStateConfiguration({
          list: StateSchemaService.parseStates<WorkpackageState>(
            FormUtils.getFormControl<PcfLean, UntypedFormArray>(this.leanForm, 'states')
          ).map(state => new WorkpackageStateProjectConfigState(state)),
        }),
      }),
      plan: new ProjectConfigPlanModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isPlanModuleEnabled'),
        metadata: !planSchema
          ? null
          : new ProjectConfigSchemaModel({
              id: planSchema.id,
              name: planSchema.name,
              description: planSchema.description,
            }),
        qrConfiguration: new PrintQrCodeConfiguration({
          position: isPlanQrPrintEnabled
            ? this.mapDocumentPosition(FormUtils.getFormValue<PcfPlan>(this.planForm, 'qrPosition'))
            : QrCodeAnchor.None,
          size: FormUtils.getFormValue<PcfPlan>(this.planForm, 'qrSize'),
          offsetVertical: FormUtils.getFormValue<PcfPlan>(this.planForm, 'qrOffsetVertical'),
          offsetHorizontal: FormUtils.getFormValue<PcfPlan>(this.planForm, 'qrOffsetHorizontal'),
          scaling: FormUtils.getFormValue<PcfPlan>(this.planForm, 'qrScaling'),
        }),
      }),
      roomBook: new ProjectConfigRoomBookModule({
        isEnabled: FormUtils.getFormValue<PcfGeneral>(this.generalForm, 'isRoomBookModuleEnabled'),
      }),
    });
  }

  private async provisionProject(project: ProjectModelWithLogos) {
    try {
      this._id = await new Promise(async (resolve, reject) => {
        const oldMetadata = this._originalConfig.metadata
          ? new ProjectConfigMetadataModel({
              id: this._originalConfig.metadata.id,
              name: this._originalConfig.metadata.name,
              description: this._originalConfig.metadata.description,
            })
          : null;

        const teamConfig = this.getProjectConfig(oldMetadata, project);

        try {
          const createdStatus = await this.apiService.saveProject(teamConfig);

          this.lrtService.getObservableForTask(createdStatus.id).subscribe(
            updatedStatus => {
              switch (updatedStatus.state) {
                case LRTaskState.Working:
                  break;
                case LRTaskState.Success:
                  resolve(updatedStatus.creation);
                  break;
                case LRTaskState.Failure:
                  this.logService.error('LRTask error:', updatedStatus.problemDetails);
                  reject(updatedStatus.problemDetails);
              }
            },
            error => {
              reject(error);
            }
          );
        } catch (e) {
          reject(e);
        }
      });

      this._isNew = false;

      await this.userNotification.notify('dialogs.editCompany.success');

      return true;
    } catch (error) {
      this.logService.error('Error provisioning project', { type: error.type, extensions: error.extensions });

      if (error instanceof ProblemDetails) {
        switch (error.type) {
          case ProblemDetailsErrorType.Conflict:
            await this.userNotification.notify('projectConfig.error.projectExists', { error: error });
            return false;
          case ProblemDetailsErrorType.SchemaError:
            switch (error.extensions[ProblemDetailsErrorType.SchemaError]) {
              case SchemaErrorType.ChangedAfterUpload:
                await this.userNotification.notify('projectConfig.error.schema.changedAfterUpload', { error: error });
                break;
              case SchemaErrorType.DuplicateTabKeys:
                await this.userNotification.notify('projectConfig.error.schema.duplicateTabKeys', { error: error });
                break;
              case SchemaErrorType.MissingFolder:
                await this.userNotification.notify('projectConfig.error.schema.missingFolder', { error: error });
                break;
              case SchemaErrorType.MissingPlanProperties:
                await this.userNotification.notify('projectConfig.error.schema.missingPlanProperties', { error: error });
                break;
              case SchemaErrorType.TabForHiddenChannel:
                await this.userNotification.notify('projectConfig.error.schema.tabForHiddenChannel', { error: error });
                break;
              case SchemaErrorType.PrivilegeOnWrongResource:
                await this.userNotification.notify('projectConfig.error.schema.privilegeOnWrongResource', { error: error });
                break;
              case SchemaErrorType.StateAlreadyInUse:
                const stateName = error.extensions['StateText'];
                await this.userNotification.notify('projectConfig.error.schema.stateAlreadyInUse', {
                  params: { stateName },
                  error: error,
                });
                break;
              default:
                await this.userNotification.notify('projectConfig.error.schema.save', { error: error });
                break;
            }

            return false;
          case ProblemDetailsErrorType.MissingData:
            await this.userNotification.notify('projectConfig.error.schema.missingChannel', { error: error });
            return false;
          case ProblemDetailsErrorType.MissingEntity:
            if (error.extensions['EntityType'] == 'PlanSchemaTemplate') {
              await this.userNotification.notify('projectConfig.error.schema.missingPlanSchema', { error: error });
              return false;
            }
            break;
        }
      }

      await this.userNotification.notify('projectConfig.error.save', { error: error });

      return false;
    }
  }

  private updateName(isNameComputed: boolean, name: string, organizationCode: string, templateCode: string, number: string) {
    const hasNameInput = !!name?.length;

    if (!isNameComputed) {
      this._computedName = hasNameInput ? name : null;
      return;
    }

    const segments: string[] = [];
    const hasOrganizationCode = !!organizationCode?.length;
    if (hasOrganizationCode) segments.push(organizationCode);

    const hasTemplateCode = !!templateCode?.length;
    if (hasTemplateCode) segments.push(templateCode);

    const hasNumberInput = !!number?.length;
    if (hasNumberInput) segments.push(number);

    if (hasNameInput) segments.push(name);

    this._computedName = segments.length > 0 ? segments.join(' ') : null;
  }

  private removeFromSubRoles(id: string, roleGroups: UntypedFormGroup[]) {
    for (const role of roleGroups) {
      const subRolesForm = FormUtils.getFormControl<PcfRole, UntypedFormArray>(role, 'roles');
      const existingIds: string[] = subRolesForm.value;
      const index = existingIds.indexOf(id);

      if (index >= 0) {
        existingIds.splice(index, 1);
        subRolesForm.setValue(existingIds);
        role.markAsDirty();
      }
    }
  }

  private removeRoleFromStructureRecursive(id: string, directories: UntypedFormArray) {
    for (const directory of directories.controls as UntypedFormGroup[]) {
      const individualPrivileges = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(directory, 'individualPrivileges');
      const roleIndex = individualPrivileges.controls.findIndex(
        (individualPrivilege: UntypedFormGroup) => FormUtils.getFormValue<PcfRole>(individualPrivilege, 'id') == id
      );

      if (roleIndex >= 0) {
        individualPrivileges.removeAt(roleIndex);
        individualPrivileges.markAsDirty();
      }

      this.removeRoleFromStructureRecursive(id, FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(directory, 'folders'));
    }
  }

  private mapQrCodeAnchor(position: QrCodeAnchor): DocumentPosition {
    switch (position) {
      case QrCodeAnchor.TopRight:
        return DocumentPosition.topRight;
      case QrCodeAnchor.BottomLeft:
        return DocumentPosition.bottomLeft;
      case QrCodeAnchor.BottomRight:
        return DocumentPosition.bottomRight;
      default:
        return DocumentPosition.topLeft;
    }
  }

  private mapDocumentPosition(position: DocumentPosition): QrCodeAnchor {
    switch (position) {
      case DocumentPosition.topLeft:
        return QrCodeAnchor.TopLeft;
      case DocumentPosition.topRight:
        return QrCodeAnchor.TopRight;
      case DocumentPosition.bottomLeft:
        return QrCodeAnchor.BottomLeft;
      case DocumentPosition.bottomRight:
        return QrCodeAnchor.BottomRight;
      default:
        return QrCodeAnchor.None;
    }
  }
}
