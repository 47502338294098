<form *ngIf="form" class="structure-config" [formGroup]="form" [ngClass]="[globals.isMobileMenu?'isphone':'']">
	<div class="tree-container" [ngClass]="hideTree?'hide-tree':'show-tree'">
		<app-return-menu-button cssClass="mobile-structure-tree-btn" (click)="structureTreeBtnClicked()">
			<div>{{ 'settings.title' | translate }}</div>
		</app-return-menu-button>
		<div class="tree-title">
			<span>
				{{ 'projectConfig.structure.title' | translate }}
			</span>
			<ng-template [ngTemplateOutlet]="formGroupErrors" [ngTemplateOutletContext]="{
				form: form
			}"></ng-template>
		</div>

		<p-tree #tree class="structure-tree slim" [value]="nodes" [filter]="true" [selection]="selectedNode?.treeNode"
						(onNodeSelect)="selectNode($event?.node)" (onNodeUnselect)="ignoreDeselect($event)" selectionMode="single"
						[filterPlaceholder]="'general.actions.filter' | translate" scrollHeight="flex"
						[emptyMessage]="'general.noDataText' | translate" [draggableNodes]="true" [droppableNodes]="true"
						[validateDrop]="true" (onNodeDrop)="onNodeDrop($event)" (onNodeCollapse)="nodeCollapse($event)">
			<ng-template let-node pTemplate="channelHead">
				<div class="tree-label-row">
					<span class="mdi mdi-plus-box" [matMenuTriggerFor]="addChannelMenu"></span>
					<div [class]="node.data == ModuleType.Document ? 'logo-icon' : 'mdi mdi-microsoft-teams header-icon'">
					</div>
					<span class="tree-label" [matTooltip]="node.label">
						{{ node.label }}
					</span>
					<mat-menu #addChannelMenu="matMenu">
						<button mat-menu-item (click)="addChannelOrFolder(node, ChannelType.Channel)">
							<!-- <span class="mdi mdi-plus-box"></span> -->
							<span>{{ 'projectConfig.structure.addChannel' | translate }}</span>
						</button>
						<!-- <button mat-menu-item (click)="addChannelOrFolder(node, ChannelType.Hidden)"
										[disabled]="node.data == ModuleType.None">
							<span class="mdi mdi-eye-off-outline"></span>
							<span>{{ 'projectConfig.structure.channelType.hidden' | translate }}</span>
						</button> -->
						<ng-template #channelTemplateMenuItemsTempalte let-channelTemplates
												 [ngTemplateOutlet]="channelTemplateMenuItemsTempalte" [ngTemplateOutletContext]="{
							$implicit: getChannelTemplates(node.data)
						}">
							<ng-container *ngIf="channelTemplates.length">
								<mat-divider></mat-divider>
								<button *ngFor="let channelTemplate of channelTemplates" mat-menu-item
												(click)="addChannelTemplate(node, channelTemplate)">
									<!-- <span class="mdi"
												[class]="channelTemplate.channelType == ChannelType.Hidden ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"></span> -->
									<span>{{ channelTemplate.name }}</span>
								</button>
							</ng-container>
						</ng-template>
					</mat-menu>
				</div>
			</ng-template>
			<ng-template let-node pTemplate="default">
				<div class="tree-label-row" [formGroup]="node.data">
					<!-- <app-icon-toggle *ngIf="node.parent.type == channelHeadNodeType" formControlName="channelType"
													 [options]="channelTypeOptions">
					</app-icon-toggle> -->
					<span class="mdi mdi-plus-box" (click)="$event.stopPropagation(); addChannelOrFolder(node)"></span>
					<span class="tree-label" [matTooltip]="node.label">
						{{ node.label }}
					</span>
					<span *ngIf="node.data.controls.folders.errors" class="mdi mdi-information error"></span>
				</div>
			</ng-template>
		</p-tree>
	</div>

	<div class="detail-container">
		<app-return-menu-button cssClass="mobile-structure-tree-btn sticky-btn" (click)="hideTree = false">
			<div>{{ 'settings.structure.tree-title' | translate }}</div>
		</app-return-menu-button>
		<div class="config-content-container" *ngIf="selectedNode" [formGroup]="selectedNode.form">
			<div class="config-content fit-to-page">
				<div class="config-group slim sticky">
					<ng-template [ngTemplateOutlet]="formGroupErrors" [ngTemplateOutletContext]="{
						form: selectedNode.form.controls.folders
					}"></ng-template>

					<div class="config-title" [class.with-action]="selectedNode.form.controls.path.enabled">
						<span>{{ 'projectConfig.structure.' + (selectedNode.isChannel ? 'channel' : 'folder') | translate }}</span>
						<span *ngIf="selectedNode.canRemove" class="mdi mdi-trash-can-outline action"
									(click)="removeSelectedNode()"></span>
					</div>

					<mat-form-field class="gap-top">
						<mat-label>{{ 'projectConfig.structure.folderPath' | translate }}</mat-label>
						<input #pathInput matInput formControlName="path">
						<mat-error *ngIf="selectedNode.form.controls.path.invalid">
							{{ 'general.errorForms.' + getError(selectedNode.form.controls.path) | translate }}
						</mat-error>
					</mat-form-field>

					<app-toggle-field *ngIf="selectedNode.isChannel && selectedNode.isPortalNode" formControlName="isHidden"
														[label]="'projectConfig.structure.channelType.toggleLabel' | translate"
														[description]="'projectConfig.structure.channelType.toggleDescription' | translate">
					</app-toggle-field>
				</div>

				<div *ngIf="isTemplate && selectedNode.isChannel" class="config-group gap slim sticky">
					<div class="config-title">{{ 'projectConfig.structure.templateChannelSettings' | translate }}</div>

					<app-radio-group [options]="channelFolderOptions" formControlName="templateType"></app-radio-group>
				</div>

				<div *ngIf="selectedNode.isChannel" class="config-group gap">
					<div class="config-title sticky slim" [class.with-action]="!selectedNode.form.controls.tabs.controls.length">
						<span>{{ 'projectConfig.structure.tab.titleMulti' | translate }}</span>
						<span *ngIf="!selectedNode.form.controls.tabs.controls.length" class="mdi mdi-plus-box action"
									(click)="addOrEditTab()"></span>
					</div>

					<div *ngIf="selectedNode.form.controls.tabs.controls.length" class="tabs-matrix table-grid-component">
						<div class="c4-table-grid-cell c4-table-grid-header-start c4-table-grid-header-top">
							{{ 'projectConfig.structure.tab.displayName' | translate }}
						</div>
						<div class="c4-table-grid-cell c4-table-grid-header-top">
							{{ 'projectConfig.structure.tab.contentUrl' | translate }}
						</div>
						<div class="c4-table-grid-cell c4-table-grid-header-top centered">
							{{ 'projectConfig.structure.tab.hideNavigation' | translate }}
						</div>
						<div class="c4-table-grid-cell c4-table-grid-header-top"></div>

						<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-start">
							{{ 'projectConfig.structure.tab.portal' | translate }}
						</div>
						<div class="c4-table-grid-cell c4-table-grid-group-header full-row"></div>
						<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-end">
							<button mat-icon-button (click)="addOrEditTab()">
								<span class="mdi mdi-plus-box"></span>
							</button>
						</div>

						<ng-container *ngFor="let tab of selectedNode.form.controls.tabs.controls">
							<div class="c4-table-grid-cell c4-table-grid-header-start">
								{{ tab.controls.displayName.value }}
							</div>
							<div class="c4-table-grid-cell content-url" [matTooltip]="tab.controls.contentUrl.value">
								<span>
									{{ tab.controls.contentUrl.value }}
								</span>
							</div>
							<div class="c4-table-grid-cell centered" [formGroup]="tab">
								<mat-checkbox formControlName="hideNavigation"></mat-checkbox>
							</div>

							<div class="c4-table-grid-cell centered">
								<div class="action-container">
									<button mat-icon-button (click)="addOrEditTab(tab)">
										<span class="mdi mdi-pencil"></span>
									</button>
									<button mat-icon-button (click)="removeTab(tab)">
										<span class="mdi mdi-trash-can-outline"></span>
									</button>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>

			<div #individualPrivilegesContainer class="config-content">
				<div *ngIf="selectedNode.isPortalNode" class="config-group gap">
					<div class="config-title sticky">{{ 'projectConfig.structure.channelRights' | translate }}</div>

					<div class="table-grid-component-container">
						<app-privilege-matrix class="table-grid-component" [service]="selectedNode.individualPrivilegeService">
						</app-privilege-matrix>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<ng-template #formGroupErrors let-form="form">
	<div *ngIf="form.errors as errors" class="error-container error">
		<span class="mdi mdi-information"></span>
		<span class="label" *ngIf="errors.uniqueControls"
					[matTooltip]="'projectConfig.structure.errors.uniqueFolderNames' | translate">
			{{ 'projectConfig.structure.errors.uniqueFolderNames' | translate }}
		</span>
		<span class="label" *ngIf="errors.uniqueFlatControls"
					[matTooltip]="'projectConfig.structure.errors.uniqueChannelNames' | translate">
			{{ 'projectConfig.structure.errors.uniqueChannelNames' | translate }}
		</span>
	</div>
</ng-template>