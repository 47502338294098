import { Pipe, PipeTransform } from '@angular/core';
import { ApiUrlService, AppConfigService } from '@app/core';

@Pipe({
  name: 'apiUrl',
})
export class ApiUrlPipe implements PipeTransform {
  constructor(private apiUrlService: ApiUrlService) {}

  transform(value: string, prependApiUrl = true): string {
    if (!value) return value;

    return prependApiUrl ? this.apiUrlService.apiUrl + value : this.apiUrlService.replaceApiFQDN(value);
  }
}
