import { Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseSubscriptionComponent } from './base-subscription-component';

const DATEPICKER_PARSE_ERROR = 'matDatepickerParse'; // Angular Material Datepicker

@Directive()
export abstract class FormComponent extends BaseSubscriptionComponent {
  form: UntypedFormGroup;

  constructor() {
    super();
  }

  get f() {
    return this.form?.controls;
  }

  get isValid() {
    return this.form?.valid ?? false;
  }

  getError(fieldName: string) {
    const errors = this.f[fieldName]?.errors;
    return Object.keys(errors)[0];
  }

  getFirstError(translationPrefix: string = ''): FieldError {
    const firstKeyWithError = Object.keys(this.f).find(k => this.f[k].errors);

    if (!firstKeyWithError) return null;

    const errorKeys = Object.keys(this.f[firstKeyWithError].errors);
    const dateParseError = errorKeys.contains(DATEPICKER_PARSE_ERROR) ? DATEPICKER_PARSE_ERROR : errorKeys[0];

    return {
      fieldName: translationPrefix.length > 0 ? `${translationPrefix}.${firstKeyWithError}` : firstKeyWithError,
      error: dateParseError,
    };
  }

  protected emptyStringAsNull(str: string): string {
    return !str?.trim()?.length ? null : str;
  }

  protected parseIntNanAsNull(str: string): number {
    const number = Number.parseInt(str);
    return number == Number.NaN ? null : number;
  }

  protected parseFloatNanAsNull(str: string): number {
    const number = Number.parseFloat(str);
    return number == Number.NaN ? null : number;
  }
}
