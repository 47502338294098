import { CancellationToken } from 'typescript';
import { IQueryWrapper, QueryCondition } from './offline-query-wrapper';
import {
  ActivityStepError,
  ActivitySteps,
  ActivityStepsCancellationToken,
  ActivityStepState,
  ActivityStepWithProgress,
} from './stepWithProgress';
import {
  AddOrUpdateDefectModel,
  CommentModel,
  ConflictBehavior,
  DriveActionMetadata,
  DriveClientExtended,
  FileParameter,
  FilePreviewSpec,
  ModuleType,
  ProblemDetails,
  ProblemDetailsErrorType,
  ProjectDefectClient,
  ResourceIdentifier,
  SyncAreaCategory,
  SyncClient,
  SyncCommentsCategory,
  SyncCraftCategory,
  SyncDefectCategory,
  SyncDefectCommentCategory,
  SyncDefectModel,
  SyncDefectTypeCategory,
  SyncDriveItemCategory,
  SyncEmptyEntityCategory,
  SyncFloorCategory,
  SyncModel,
  SyncOrganizationCategory,
  SyncProjectCategory,
  SyncProjectOrganizationCategory,
  SyncProjectOrganizationCraftCategory,
  SyncProjectPrivilegesCategory,
  SyncProjectUserCategory,
  SyncReasonCategory,
  SyncRegionCategory,
  SyncResourceCategory,
  SyncRoomCategory,
  SyncSchemaCategory,
  SyncTenantSettingsCategory,
  SyncUserCategory,
  SyncZoneCategory,
  SyncZoneGroupCategory,
  UserModel,
} from '@app/api';
import { MigrationStatus, SQLiteTables } from './sqlite/utils';
import { MIME_TYPES_WITH_PREVIEW } from '@app/shared/services/file-preview/FilePreviewConfig';
import { Filesystem, Directory } from '@capacitor/filesystem';
import {
  IPSMetadata,
  IPSResource,
  IPSProject,
  IPSProjectPrivilege,
  IPSCraft,
  IPSArea,
  IPSDefect,
  IPSDefectType,
  IPSDefectComment,
  IPSComment,
  IPSFloor,
  IPSRegion,
  IPSZoneGroup,
  IPSZone,
  IPSReason,
  IPSRoom,
  IPSSchema,
  IPSDriveItem,
  IPSOrganization,
  IPSUser,
  IPSProjectUser,
  IPSProjectOrganization,
  IPSProjectOrganizationCraft,
  UNEXPECTED_ERROR,
  MetadataKey,
  IPSSyncError,
  getProjectMetadataKey,
} from './definitions';
import { OfflineService } from './offline.service';
import { AuthenticationService } from '../authentication';
import { OfflineState, OfflineStatusManager } from './offline-status-manager';
import { Utils } from '@app/core/utils';
import { OfflineServiceFileHandler } from './OfflineServiceFileHandler';
import { isMoment, Moment } from 'moment';
import { distinctUntilChanged, filter, interval, skip, Subject, takeUntil } from 'rxjs';
import { ApiUrlService } from '../api';
import { AppConfigService } from '../app-config';
import { KeepAwake } from '@capacitor-community/keep-awake';

const offlineServiceStateId = 'FACADE00-B365-1000-0000-000000000001';

export class OfflineSyncService {
  projectSynced$ = new Subject<string>();

  private syncProjectCancellationTokens: Record<string, ActivityStepsCancellationToken> = {};
  private disposed$ = new Subject<void>();
  private keepAwakeActionCount = 0;

  constructor(private statusManager: OfflineStatusManager, private syncEngine: SyncEngine) {
    interval(1000 * 60 * AppConfigService.settings.offline.syncIntervalInMinutes)
      .pipe(takeUntil(this.disposed$))
      .subscribe(async () => {
        await this.syncAutoSyncedProjects();
      });

    statusManager.isOffline$
      .pipe(
        takeUntil(this.disposed$),
        distinctUntilChanged(),
        skip(1),
        filter(isOffline => !isOffline)
      )
      .subscribe(async () => {
        await this.syncAutoSyncedProjects();
      });
  }

  dispose() {
    this.disposed$.next();
  }

  private get database() {
    return this.syncEngine.database;
  }

  createStepsForProjectDownload(projectId: string, isUserAction = true) {
    const steps = new ActivitySteps();
    const { steps: downloadSteps, executor: downloadProject } = downloadProjectFactory(projectId, this.syncEngine);

    for (const step of downloadSteps) steps.addItem(step);

    const stepGoOffline = new ActivityStepWithProgress('offline.activitySteps.goOffline');
    if (isUserAction) steps.addItem(stepGoOffline);

    steps.start = async (cancellationToken: CancellationToken) => {
      await this.keepAwakeAction(async () => {
        const success = await downloadProject(cancellationToken);

        if (success) {
          if (isUserAction) {
            stepGoOffline.start();

            try {
              await this.database.setProjectOfflineStatus(projectId, true);
            } catch (e) {
              stepGoOffline.addError(UNEXPECTED_ERROR);
              return;
            } finally {
              stepGoOffline.complete();
            }
          }

          try {
            this.statusManager.updateProjectStatus(projectId, {
              offlineState: isUserAction ? OfflineState.offlineByUser : undefined,
              lastSynced: await this.database.getProjectLastSynced(projectId),
            });
          } catch (e) {
            stepGoOffline.addError(UNEXPECTED_ERROR);
            return;
          }
        }

        await this.updateErrorsForProject(projectId, steps);
      });
    };

    return steps;
  }

  createStepsForProjectUpload(projectId: string, isUserAction = true) {
    const steps = new ActivitySteps();
    const { steps: uploadSteps, executor: uploadProject } = uploadProjectFactory(projectId, this.syncEngine);

    for (const step of uploadSteps) steps.addItem(step);

    const stepGoOnline = new ActivityStepWithProgress('offline.activitySteps.goOnline');
    if (isUserAction) steps.addItem(stepGoOnline);

    steps.start = async (cancellationToken: CancellationToken) => {
      await this.keepAwakeAction(async () => {
        const success = await uploadProject(cancellationToken);

        if (success && isUserAction) {
          stepGoOnline.start();

          try {
            await this.database.setProjectOfflineStatus(projectId, false);

            this.statusManager.updateProjectStatus(projectId, { offlineState: OfflineState.online });
          } catch (e) {
            stepGoOnline.addError(UNEXPECTED_ERROR);
            return;
          } finally {
            stepGoOnline.complete();
          }
        }

        await this.updateErrorsForProject(projectId, steps);
      });
    };

    return steps;
  }

  createStepsForProjectSync(projectId: string) {
    const steps = new ActivitySteps();
    const { steps: uploadProjectSteps, executor: uploadLocalData } = uploadProjectFactory(projectId, this.syncEngine);
    const { steps: downloadProjectSteps, executor: updateLocalData } = downloadProjectFactory(projectId, this.syncEngine);

    for (const step of uploadProjectSteps) steps.addItem(step);
    for (const step of downloadProjectSteps) steps.addItem(step);

    const sync = async (cancellationToken: CancellationToken) => {
      await this.keepAwakeAction(async () => {
        let success = true;
        success &&= await uploadLocalData(cancellationToken);
        success &&= await updateLocalData(cancellationToken);

        if (success)
          this.statusManager.updateProjectStatus(projectId, {
            lastSynced: await this.database.getProjectLastSynced(projectId),
          });

        await this.updateErrorsForProject(projectId, steps);
      });
    };

    steps.start = sync;

    return steps;
  }

  async updateErrorsForProject(projectId: string, steps: ActivitySteps) {
    const errors = steps.items
      .filter(s => s.currentState == ActivityStepState.failure)
      // flat map
      .reduce((previous, step) => [...previous, ...step.errors], []);

    const statements = await this.database.getStatements();

    statements.remove(SQLiteTables.SyncErrors, {
      project: projectId,
    });

    for (const error of errors) {
      statements.insertOrReplace<IPSSyncError>(SQLiteTables.SyncErrors, {
        id: Utils.createUUID(),
        project: projectId,
        createdOn: new Date(),
        parameters: JSON.stringify(error),
      });
    }

    await statements.execute();
  }

  async hasProjectData(projectId: string): Promise<boolean> {
    return (await this.database.getProjectById(projectId)) != null;
  }

  async isAutoSyncEnabled(projectId: string) {
    const autoSyncedProjects = await this.getAutoSyncedProjects();
    return autoSyncedProjects.includes(projectId);
  }

  isProjectSyncing(projectId: string) {
    return !!this.syncProjectCancellationTokens[projectId];
  }

  cancelSync(projectId: string, createNew = false) {
    this.syncProjectCancellationTokens[projectId]?.cancel();
    return (this.syncProjectCancellationTokens[projectId] = createNew ? new ActivityStepsCancellationToken() : null);
  }

  async syncAutoSyncedProjects() {
    const isAuthenticated = this.syncEngine.authenticationService.isAuthenticated();
    if (!isAuthenticated) return;

    const isOffline = this.statusManager.isOffline$.value;
    if (isOffline) return;

    const autoSyncedProjectIds = await this.getAutoSyncedProjects();
    const syncs: Promise<boolean>[] = [];
    for (const id of autoSyncedProjectIds) syncs.push(this.syncProject(id));

    return await Promise.all(syncs);
  }

  async syncProject(projectId: string, fireSynced = true) {
    const canSyncProject = !this.statusManager.isOffline(projectId) && !this.isProjectSyncing(projectId);
    if (!canSyncProject) return false;

    this.statusManager.updateProjectStatus(projectId, { isSyncing: true });

    try {
      const newCancellationToken = this.cancelSync(projectId, true);
      const steps = this.createStepsForProjectSync(projectId);

      await steps.start(newCancellationToken);

      if (steps.success && fireSynced) this.projectSynced$.next(projectId);

      return steps.success;
    } catch {
      return false;
    } finally {
      this.syncProjectCancellationTokens[projectId] = null;
      this.statusManager.updateProjectStatus(projectId, { isSyncing: false });
    }
  }

  async enableAutoSync(projectId: string) {
    if (this.isProjectSyncing(projectId)) return false;

    await this.enableAutoSyncOfProject(projectId);

    // do not await so it can be downloaded in the background
    this.syncProject(projectId);

    return true;
  }

  async disableAutoSync(projectId: string, uploadData = true) {
    if (this.isProjectSyncing(projectId)) return false;

    const success = !uploadData || await this.syncProject(projectId, false);
    if (success) {
      await this.disableAutoSyncOfProject(projectId);
      this.projectSynced$.next(projectId);
    }

    return success;
  }

  async setAutoSyncedProjectsOffline() {
    const projects = await this.database.getProjects();
    const autoSyncedProjectIds = await this.getAutoSyncedProjects();

    // can only switch if project was synced once
    for (const project of projects) {
      if (autoSyncedProjectIds.includes(project.id) && (await this.hasProjectData(project.id))) {
        this.statusManager.updateProjectStatus(project.id, { offlineState: OfflineState.offlineBySystem });
      }
    }
  }

  async setAutoSyncedProjectsOnline() {
    for (const id of this.statusManager.autoOfflineProjectIds) {
      this.statusManager.updateProjectStatus(id, { offlineState: OfflineState.online });
    }
  }

  async updateProjectStatesFromDatabase() {
    const isOffline = this.statusManager.isOffline$.value;
    const switchOfflineProjectIds = isOffline ? await this.getAutoSyncedProjects() : [];
    const projects = await this.database.getProjects();

    for (const project of projects) {
      const lastSynced = project.sync_datetime;
      const switchOffline = switchOfflineProjectIds.includes(project.id);
      const offlineState = !project.offline
        ? switchOffline
          ? OfflineState.offlineBySystem
          : OfflineState.online
        : OfflineState.offlineByUser;

      this.statusManager.updateProjectStatus(project.id, { offlineState, lastSynced });
    }
  }

  private async getAutoSyncedProjects(): Promise<string[]> {
    const metadata = await this.database.getMetadata(MetadataKey.syncedProjects);
    return metadata ? JSON.parse(metadata) : [];
  }

  private async updateAutoSyncedProjects(action: (projects: string[]) => string[]) {
    const autoSyncedProjects = await this.getAutoSyncedProjects();

    const updatedProjects = action(autoSyncedProjects);

    const statements = await this.database.getStatements();
    statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
      name: MetadataKey.syncedProjects,
      data: JSON.stringify(updatedProjects),
    });

    await statements.execute();
  }

  private async enableAutoSyncOfProject(projectId: string) {
    await this.updateAutoSyncedProjects(projects => {
      if (!projects.includes(projectId)) projects.push(projectId);

      return projects;
    });
  }

  private async disableAutoSyncOfProject(projectId: string) {
    await this.updateAutoSyncedProjects(projects => projects.filter(p => p != projectId));
  }

  private async keepAwakeAction<T>(action: () => Promise<T>) {
    try {
      this.keepAwakeActionCount++;
      await KeepAwake.keepAwake();

      return await action();
    } finally {
      this.keepAwakeActionCount--;
      if (this.keepAwakeActionCount == 0) KeepAwake.allowSleep();
    }
  }
}

interface BaseEngine {
  database: IQueryWrapper;
}

export interface DownloadEngine extends BaseEngine {
  api: SyncClient;
  apiUrlService: ApiUrlService;
  authenticationService: AuthenticationService;
}

export interface UploadEngine extends BaseEngine {
  defectClient: ProjectDefectClient;
  driveClient: DriveClientExtended;
  fileHandler: OfflineServiceFileHandler;
}

export interface SyncEngine extends DownloadEngine, UploadEngine {}

export interface SyncFactoryResult {
  steps: ActivityStepWithProgress[];
  executor: (cancellationToken: CancellationToken) => Promise<boolean>;
}

export function downloadProjectFactory(projectId: string, engine: DownloadEngine): SyncFactoryResult {
  const stepOfFetchChanges = new ActivityStepWithProgress('offline.activitySteps.fetchChanges');
  const stepOfUpdateDatabase = new ActivityStepWithProgress(
    'offline.activitySteps.migrateTables',
    'offline.activitySteps.migratedTables',
    22
  ); // all executes until files
  const stepOfDownloadFiles = new ActivityStepWithProgress(
    'offline.activitySteps.downloadFiles',
    'offline.activitySteps.downloadedFiles'
  );

  return {
    steps: [stepOfFetchChanges, stepOfUpdateDatabase, stepOfDownloadFiles],
    executor: async (cancellationToken: CancellationToken) => {
      const statements = await engine.database.getStatements();
      const startDate = new Date();

      stepOfFetchChanges.start();
      let changes: SyncModel;
      const syncInfo = {
        sync_checksum: null,
        offline: false,
      };

      try {
        const project = await engine.database.getProjectById(projectId);
        Object.assign(syncInfo, project);

        const changeRequest = new SyncModel({
          syncFingerprint: syncInfo.sync_checksum,

          tenantSettings: new SyncTenantSettingsCategory(),

          emptyEntities: new SyncEmptyEntityCategory(),

          resources: new SyncResourceCategory(),

          projectOrganizationCrafts: new SyncProjectOrganizationCraftCategory({
            from: await engine.database.getLastModified(SQLiteTables.ProjectOrganizationCrafts),
          }),
          projectOrganizations: new SyncProjectOrganizationCategory({
            from: await engine.database.getLastModified(SQLiteTables.ProjectOrganizations),
          }),
          projectPrivileges: new SyncProjectPrivilegesCategory({
            full: true,
          }),
          projectUsers: new SyncProjectUserCategory({
            from: await engine.database.getLastModified(SQLiteTables.ProjectUsers),
          }),
          project: new SyncProjectCategory({
            full: true,
          }),

          schema: new SyncSchemaCategory({
            from: await engine.database.getLastModified(SQLiteTables.Schema),
          }),

          driveItems: new SyncDriveItemCategory({
            from: await engine.database.getLastModified(SQLiteTables.DriveItems, QueryCondition.onlyOriginal),
          }),

          defects: new SyncDefectCategory({
            from: await engine.database.getLastModified(SQLiteTables.Defects, QueryCondition.onlyOriginal),
          }),
          defectTypes: new SyncDefectTypeCategory({
            from: await engine.database.getLastModified(SQLiteTables.DefectTypes),
          }),
          defectComments: new SyncDefectCommentCategory({
            from: await engine.database.getLastModified(SQLiteTables.DefectComments, QueryCondition.onlyOriginal),
          }),

          organizations: new SyncOrganizationCategory({
            from: await engine.database.getLastModified(SQLiteTables.Organizations),
          }),
          comments: new SyncCommentsCategory({
            from: await engine.database.getLastModified(SQLiteTables.Comments, QueryCondition.onlyOriginal),
          }),
          reasons: new SyncReasonCategory({
            from: await engine.database.getLastModified(SQLiteTables.DefectReasons),
          }),
          regions: new SyncRegionCategory({
            from: await engine.database.getLastModified(SQLiteTables.Regions),
          }),
          crafts: new SyncCraftCategory({
            from: await engine.database.getLastModified(SQLiteTables.Crafts),
          }),
          floors: new SyncFloorCategory({
            from: await engine.database.getLastModified(SQLiteTables.Floors),
          }),
          areas: new SyncAreaCategory({
            from: await engine.database.getLastModified(SQLiteTables.Areas),
          }),
          rooms: new SyncRoomCategory({
            from: await engine.database.getLastModified(SQLiteTables.Rooms),
          }),
          zoneGroups: new SyncZoneGroupCategory({
            from: await engine.database.getLastModified(SQLiteTables.ZoneGroups),
          }),
          zones: new SyncZoneCategory({
            from: await engine.database.getLastModified(SQLiteTables.Zones),
          }),
          users: new SyncUserCategory({
            from: await engine.database.getLastModified(SQLiteTables.Users),
          }),
        });

        try {
          changes = await engine.api.getChanges(projectId, changeRequest).toPromise();
        } catch {
          stepOfFetchChanges.addError({
            topic: 'offline.activitySteps.errors.topic.fetch',
            description: 'offline.activitySteps.errors.reasons.fetchtryagain',
          });
          return false;
        }
      } catch (e) {
        stepOfUpdateDatabase.addError(UNEXPECTED_ERROR);
        return false;
      } finally {
        // console.info('fetchChanges done');
        stepOfFetchChanges.complete();
      }

      const filesToPreview = [];
      stepOfUpdateDatabase.start();
      try {
        statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
          name: MetadataKey.updateDate,
          data: startDate.toString(),
        });

        if (changes.tenantSettings) {
          // todo refactor - consider moving to designated fetch function for global data
          // hint - this is not the only global data (e.g. organizations which is weirdly saved with project id)
          const settings = changes.tenantSettings;

          statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
            name: MetadataKey.tenantSettings,
            data: JSON.stringify(settings),
          });
        }

        if (changes.emptyEntities) {
          const { defectModel } = changes.emptyEntities;

          statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
            name: getProjectMetadataKey(MetadataKey.emptyDefect, projectId),
            data: JSON.stringify(defectModel),
          });
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('tenant settings done');

        if (changes.resources) {
          statements.remove(SQLiteTables.Resources, { project: projectId });

          for (const resource of changes.resources.entries) {
            statements.insertOrReplace<IPSResource>(SQLiteTables.Resources, {
              id: resource.key.name,
              project: projectId,
              json: JSON.stringify(resource),
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('resources done');

        if (changes.project) {
          if (changes.project.full) {
            statements.remove(SQLiteTables.Projects, { id: projectId });
          }

          for (const project of changes.project.entries) {
            statements.insertOrReplace<IPSProject>(SQLiteTables.Projects, {
              id: project.id,
              json: JSON.stringify(project),
              offline: syncInfo.offline,
              modifiedOn: startDate,
              sync_checksum: changes.syncFingerprint,
              sync_datetime: startDate,
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('projects done');

        if (changes.projectPrivileges) {
          if (changes.projectPrivileges.full) {
            statements.remove(SQLiteTables.ProjectPrivileges, { project: projectId });
          }

          for (const projectPrivilege of changes.projectPrivileges.entries) {
            statements.insertOrReplace<IPSProjectPrivilege>(SQLiteTables.ProjectPrivileges, {
              id: projectPrivilege.name,
              project: projectId,

              json: JSON.stringify(projectPrivilege),

              deleted: false,
              modifiedOn: startDate,
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('project privileges done');

        if (changes.crafts.entries) {
          if (changes.crafts.full) {
            statements.remove(SQLiteTables.Crafts, { project: projectId });
          }

          for (const craft of changes.crafts.entries) {
            if (craft.isDeleted) {
              statements.remove(SQLiteTables.Crafts, { id: craft.id });
              continue;
            }

            statements.insertOrReplace<IPSCraft>(SQLiteTables.Crafts, {
              id: craft.id,
              project: projectId,

              json: JSON.stringify(craft),

              deleted: craft.isDeleted,
              modifiedOn: craft.modifiedOn,
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('crafts done');

        if (changes.areas.entries) {
          if (changes.areas.full) {
            statements.remove(SQLiteTables.Areas, { project: projectId });
          }

          for (const area of changes.areas.entries) {
            if (area.isDeleted) {
              statements.remove(SQLiteTables.Areas, { id: area.id });
              continue;
            }

            statements.insertOrReplace<IPSArea>(SQLiteTables.Areas, {
              id: area.id,
              project: projectId,

              json: JSON.stringify(area),

              deleted: area.isDeleted,
              modifiedOn: area.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('areas done');

        if (changes.defects.entries) {
          if (changes.defects.full) {
            statements.remove(SQLiteTables.Defects, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const defect of changes.defects.entries) {
            if (defect.isDeleted) {
              statements.remove(SQLiteTables.Defects, { id: defect.id });
              continue;
            }

            statements.insertOrReplace<IPSDefect>(SQLiteTables.Defects, {
              id: defect.id,
              project: projectId,

              json: JSON.stringify(defect),

              mode: 'EXISTS',
              deleted: defect.isDeleted,
              modifiedOn: defect.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('defects done');

        if (changes.defectTypes.entries) {
          if (changes.defectTypes.full) {
            statements.remove(SQLiteTables.DefectTypes, { project: projectId });
          }

          for (const defectType of changes.defectTypes.entries) {
            if (defectType.isDeleted) {
              statements.remove(SQLiteTables.DefectTypes, { id: defectType.id });
              continue;
            }

            statements.insertOrReplace<IPSDefectType>(SQLiteTables.DefectTypes, {
              id: defectType.id,
              project: projectId,

              code: defectType.code,

              json: JSON.stringify(defectType),

              deleted: defectType.isDeleted,
              modifiedOn: defectType.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('defectTypes done');

        if (changes.defectComments.entries) {
          if (changes.defectComments.full) {
            statements.remove(SQLiteTables.DefectComments, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const defectComment of changes.defectComments.entries) {
            if (defectComment.isDeleted) {
              statements.remove(SQLiteTables.DefectComments, { id: defectComment.id });
              continue;
            }

            statements.insertOrReplace<IPSDefectComment>(SQLiteTables.DefectComments, {
              id: defectComment.id,
              project: projectId,

              defectId: defectComment.defectId,
              commentId: defectComment.commentId,

              mode: 'EXISTS',
              deleted: defectComment.isDeleted,
              modifiedOn: defectComment.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('defectComments done');

        if (changes.comments.entries) {
          if (changes.comments.full) {
            statements.remove(SQLiteTables.Comments, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const comment of changes.comments.entries) {
            if (comment.isDeleted) {
              statements.remove(SQLiteTables.Comments, { id: comment.id });
              continue;
            }

            statements.insertOrReplace<IPSComment>(SQLiteTables.Comments, {
              id: comment.id,
              project: projectId,

              text: comment.text,

              editorId: comment.editorId,
              replyToCommentId: comment.replyToCommentId,

              mode: 'EXISTS',
              deleted: comment.isDeleted,
              modifiedOn: comment.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('comments done');

        if (changes.floors.entries) {
          if (changes.floors.full) {
            statements.remove(SQLiteTables.Floors, { project: projectId });
          }

          for (const floor of changes.floors.entries) {
            if (floor.isDeleted) {
              statements.remove(SQLiteTables.Floors, { id: floor.id });
              continue;
            }

            statements.insertOrReplace<IPSFloor>(SQLiteTables.Floors, {
              id: floor.id,
              project: projectId,

              areaId: floor.areaId,

              json: JSON.stringify(floor),

              deleted: floor.isDeleted,
              modifiedOn: floor.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('floors done');

        if (changes.regions.entries) {
          if (changes.regions.full) {
            statements.remove(SQLiteTables.Regions, { project: projectId });
          }

          for (const region of changes.regions.entries) {
            if (region.isDeleted) {
              statements.remove(SQLiteTables.Regions, { id: region.id });
              continue;
            }

            statements.insertOrReplace<IPSRegion>(SQLiteTables.Regions, {
              id: region.id,
              project: projectId,

              areaId: region.areaId,

              json: JSON.stringify(region),

              deleted: region.isDeleted,
              modifiedOn: region.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('regions done');

        if (changes.zoneGroups.entries) {
          if (changes.zoneGroups.full) {
            statements.remove(SQLiteTables.ZoneGroups, { project: projectId });
          }

          for (const zoneGroup of changes.zoneGroups.entries) {
            if (zoneGroup.isDeleted) {
              statements.remove(SQLiteTables.ZoneGroups, { id: zoneGroup.id });
              continue;
            }

            statements.insertOrReplace<IPSZoneGroup>(SQLiteTables.ZoneGroups, {
              id: zoneGroup.id,
              project: projectId,

              json: JSON.stringify(zoneGroup),

              deleted: zoneGroup.isDeleted,
              modifiedOn: zoneGroup.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        if (changes.zones.entries) {
          if (changes.zones.full) {
            statements.remove(SQLiteTables.Zones, { project: projectId });
          }

          for (const zone of changes.zones.entries) {
            if (zone.isDeleted) {
              statements.remove(SQLiteTables.Zones, { id: zone.id });
              continue;
            }

            statements.insertOrReplace<IPSZone>(SQLiteTables.Zones, {
              id: zone.id,
              project: projectId,

              zoneGroupId: zone.zoneGroupId,

              json: JSON.stringify(zone),

              deleted: zone.isDeleted,
              modifiedOn: zone.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        if (changes.reasons.entries) {
          if (changes.reasons.full) {
            statements.remove(SQLiteTables.DefectReasons, { project: projectId });
          }

          for (const reason of changes.reasons.entries) {
            if (reason.isDeleted) {
              statements.remove(SQLiteTables.DefectReasons, { id: reason.id });
              continue;
            }

            statements.insertOrReplace<IPSReason>(SQLiteTables.DefectReasons, {
              id: reason.id,
              project: projectId,

              code: reason.code,

              json: JSON.stringify(reason),

              deleted: reason.isDeleted,
              modifiedOn: reason.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('reasons done');

        if (changes.rooms.entries) {
          if (changes.rooms.full) {
            statements.remove(SQLiteTables.Rooms, { project: projectId });
          }

          for (const room of changes.rooms.entries) {
            if (room.isDeleted) {
              statements.remove(SQLiteTables.Rooms, { id: room.id });
              continue;
            }

            statements.insertOrReplace<IPSRoom>(SQLiteTables.Rooms, {
              id: room.id,
              project: projectId,

              floorId: room.floorId,

              json: JSON.stringify(room),

              deleted: room.isDeleted,
              modifiedOn: room.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('rooms done');

        if (changes.schema.entries) {
          if (changes.schema.full) {
            statements.remove(SQLiteTables.Schema, { project: projectId });
          }

          for (const schema of changes.schema.entries) {
            if (schema.isDeleted) {
              statements.remove(SQLiteTables.Schema, { id: schema.id });
              continue;
            }

            const data = schema.plan || schema.team;
            const json = data ? JSON.stringify(data) : null;

            statements.insertOrReplace<IPSSchema>(SQLiteTables.Schema, {
              id: schema.id,
              project: projectId,

              type: schema.type,
              json,

              deleted: false,
              modifiedOn: schema.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('schema done');

        if (changes.driveItems.entries) {
          if (changes.driveItems.full) {
            for (const driveItem of await engine.database.getDriveItemsWhereMode(MigrationStatus.EXISTS, projectId)) {
              const directory = getDirectoryPath(projectId, driveItem.resource, driveItem.path, driveItem.name);

              try {
                Filesystem.rmdir({
                  path: directory,
                  directory: Directory.Data,
                  recursive: true,
                });
              } catch {
                // ignore
              }
            }

            statements.remove(SQLiteTables.DriveItems, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const driveItem of changes.driveItems.entries) {
            statements.insertOrReplace<IPSDriveItem>(SQLiteTables.DriveItems, {
              id: driveItem.id,
              project: projectId,
              relatedEntityId: driveItem.relatedEntityId,

              type: driveItem.type,
              path: driveItem.path,
              name: driveItem.name,
              mimeType: driveItem.mimeType || 'application/octet-stream',
              resource: driveItem.resourceIdentifier.key.name,

              json: JSON.stringify(driveItem),
              mode: 'EXISTS',
              deleted: false,
              modifiedOn: driveItem.lastModifiedDateTime,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('driveItems done');

        if (changes.organizations.entries) {
          if (changes.organizations.full) {
            statements.remove(SQLiteTables.Organizations, { project: projectId });
          }

          for (const organization of changes.organizations.entries) {
            if (organization.isDeleted) {
              statements.remove(SQLiteTables.Organizations, { id: organization.id });
              continue;
            }

            statements.insertOrReplace<IPSOrganization>(SQLiteTables.Organizations, {
              id: organization.id,
              project: projectId,

              json: JSON.stringify(organization),

              deleted: organization.isDeleted,
              modifiedOn: organization.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('organizations done');

        if (changes.users.entries) {
          if (changes.users.full) {
            statements.remove(SQLiteTables.Users, { project: projectId });
          }

          for (const user of changes.users.entries) {
            if (user.isDeleted) {
              statements.remove(SQLiteTables.Users, { id: user.id });
              continue;
            }

            statements.insertOrReplace<IPSUser>(SQLiteTables.Users, {
              id: user.id,
              project: projectId,

              json: JSON.stringify(user),

              deleted: user.isDeleted,
              modifiedOn: user.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('users done');

        if (changes.projectUsers.entries) {
          if (changes.projectUsers.full) {
            statements.remove(SQLiteTables.ProjectUsers, { project: projectId });
          }

          for (const projectUser of changes.projectUsers.entries) {
            if (projectUser.isDeleted) {
              statements.remove(SQLiteTables.ProjectUsers, { id: projectUser.id });
              continue;
            }

            statements.insertOrReplace<IPSProjectUser>(SQLiteTables.ProjectUsers, {
              id: projectUser.id,
              project: projectId,

              userId: projectUser.userId,

              json: JSON.stringify(projectUser),

              deleted: projectUser.isDeleted,
              modifiedOn: projectUser.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('projectUsers done');

        if (changes.projectOrganizations.entries) {
          if (changes.projectOrganizations.full) {
            statements.remove(SQLiteTables.ProjectOrganizations, { project: projectId });
          }

          for (const projectOrganization of changes.projectOrganizations.entries) {
            if (projectOrganization.isDeleted) {
              statements.remove(SQLiteTables.ProjectOrganizations, { id: projectOrganization.id });
              continue;
            }

            statements.insertOrReplace<IPSProjectOrganization>(SQLiteTables.ProjectOrganizations, {
              id: projectOrganization.id,
              project: projectId,

              organizationId: projectOrganization.organizationId,

              json: JSON.stringify(projectOrganization),

              deleted: projectOrganization.isDeleted,
              modifiedOn: projectOrganization.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('projectOrganization done');

        if (changes.projectOrganizationCrafts.entries) {
          if (changes.projectOrganizationCrafts.full) {
            statements.remove(SQLiteTables.ProjectOrganizationCrafts, { project: projectId });
          }

          for (const projectOrganizationCraft of changes.projectOrganizationCrafts.entries) {
            if (projectOrganizationCraft.isDeleted) {
              statements.remove(SQLiteTables.ProjectOrganizationCrafts, { id: projectOrganizationCraft.id });
              continue;
            }

            statements.insertOrReplace<IPSProjectOrganizationCraft>(SQLiteTables.ProjectOrganizationCrafts, {
              id: projectOrganizationCraft.id,
              project: projectId,

              projectOrganizationId: projectOrganizationCraft.projectOrganizationId,

              json: JSON.stringify(projectOrganizationCraft),

              deleted: projectOrganizationCraft.isDeleted,
              modifiedOn: projectOrganizationCraft.modifiedOn,
            });
          }

          await statements.execute();

          // console.info('projectOrganizationCrafts done');
        }
      } catch (e) {
        stepOfUpdateDatabase.addError(UNEXPECTED_ERROR);
        return false;
      } finally {
        stepOfUpdateDatabase.complete();
      }

      try {
        for (const driveItem of await engine.database.getDriveItems(projectId)) {
          const directory = getDirectoryPath(projectId, driveItem.resource, driveItem.path, driveItem.name);

          if (driveItem.mimeType && MIME_TYPES_WITH_PREVIEW.indexOf(driveItem.mimeType) >= 0) {
            const files: string[] = [];
            try {
              const dir = await Filesystem.readdir({
                path: directory,
                directory: Directory.Data,
              });

              files.push(...dir.files.map(x => x.name));
            } catch {}

            for (const spec of [FilePreviewSpec.Preview, FilePreviewSpec.Thumbnail]) {
              if (files.every(x => x != `${spec}.bin`)) {
                filesToPreview.push({
                  driveItem,
                  fileSpec: spec,
                });
              }
            }
          }
        }

        stepOfDownloadFiles.maxProgress = filesToPreview.length;
        stepOfDownloadFiles.start();

        const token = `Bearer ${await engine.authenticationService.getCurrentAccessToken()}`;

        const directories = {};

        let failedToDownloadFiles = 0;
        for (const o of filesToPreview) {
          const fileSpec: FilePreviewSpec = o.fileSpec;
          const driveItem: IPSDriveItem = o.driveItem;

          const directory = getDirectoryPath(projectId, driveItem.resource, driveItem.path, driveItem.name);

          if (!(directory in directories)) {
            try {
              await Filesystem.mkdir({
                path: directory,
                directory: Directory.Data,
                recursive: true,
              });

              directories[directory] = true;
            } catch ($err) {
              if ($err && $err.message == 'Directory exists') {
                directories[directory] = true;
              }

              console.error('mkdir', $err.message);
              continue;
            }
          }

          try {
            await Filesystem.downloadFile({
              headers: {
                resource: driveItem.resource,

                Accept: 'application/json',
                Authorization: token,
              },
              url: `${engine.apiUrlService.apiUrl}/projects/${projectId}/items/${driveItem.id}/preview?spec=${fileSpec}`,
              path: `${directory}/${fileSpec}.bin`,
              directory: Directory.Data,
            });

            statements.execute();
          } catch ($err) {
            ++failedToDownloadFiles;
            console.warn('Unable to download / store file', driveItem.id, fileSpec, driveItem.resource, $err);
          } finally {
            stepOfDownloadFiles.advance();
          }
        }

        // Dont report failed files as warning - requested by Vollack
        // if (failedToDownloadFiles > 0) {
        //   stepOfDownloadFiles.addWarning({
        //     topic: 'offline.activitySteps.errors.topic.file',
        //     description: `offline.activitySteps.errors.reasons.failedDownloadFiles`,
        //     translationParams: {
        //       count: failedToDownloadFiles,
        //     },
        //   });
        // }

        stepOfDownloadFiles.complete();
      } catch (e) {
        stepOfUpdateDatabase.addError(UNEXPECTED_ERROR);
        return false;
      } finally {
        stepOfUpdateDatabase.complete();
      }

      return true;
    },
  };
}

export function uploadProjectFactory(projectId: string, engine: UploadEngine): SyncFactoryResult {
  const fileHandler = engine.fileHandler;

  const stepGetChanges = new ActivityStepWithProgress('offline.activitySteps.computeChanges');
  const stepUploadDefects = new ActivityStepWithProgress(
    'offline.activitySteps.uploadDefects',
    'offline.activitySteps.uploadedDefects'
  );
  const stepUploadFiles = new ActivityStepWithProgress(
    'offline.activitySteps.uploadFiles',
    'offline.activitySteps.uploadedFiles'
  );

  return {
    steps: [stepGetChanges, stepUploadDefects, stepUploadFiles],
    executor: async (cancellationToken: CancellationToken) => {
      const statements = await engine.database.getStatements();

      stepGetChanges.start();
      try {
        engine.database.getProjectById(projectId);
      } catch (e) {}

      // Get Changed Defects
      let defects: IPSDefect[] = [];
      try {
        defects = await engine.database.getDefectsWhereModified(projectId);
      } catch (e) {
        stepGetChanges.addError(UNEXPECTED_ERROR);
        stepGetChanges.complete();
        return;
      }

      let files = [];
      try {
        const resoureIdentifiers = await getResourceIdentifiers(projectId, engine.database);
        const resource = resoureIdentifiers.find(i => i.moduleType === ModuleType.Defect)?.key?.name;
        files = await engine.database.getDriveItemsWhereModeIsInsertAndResource(projectId, resource);
      } catch (e) {
        stepGetChanges.addError(UNEXPECTED_ERROR);
        stepGetChanges.complete();
        return;
      }

      stepGetChanges.complete();

      // Upload Defects
      stepUploadDefects.maxProgress = defects.length;
      stepUploadDefects.start();

      const sortedDefects = defects
        .map(defect => ({ defect, model: SyncDefectModel.fromJS(JSON.parse(defect.json)) }))
        .sort(({ model: d1 }, { model: d2 }) => d1.createdOn.getTime() - d2.createdOn.getTime());

      for (const { defect, model } of sortedDefects) {
        try {
          const fileHandler = engine.fileHandler;
          const addOrUpdateDefect = new AddOrUpdateDefectModel();

          if (defect.mode == MigrationStatus.UPDATE) {
            addOrUpdateDefect.id = model.id;
          } else if (defect.mode == MigrationStatus.INSERT) {
            addOrUpdateDefect.offlineId = model.id;
            addOrUpdateDefect.offlineCreatedOn = model.createdOn;
          }

          const userAssignments = model.userAssignments.map(x => new UserModel({ id: x.userId }));

          addOrUpdateDefect.areaId = model.areaId;
          addOrUpdateDefect.roomId = model.roomId;
          addOrUpdateDefect.typeId = model.typeId;
          addOrUpdateDefect.stateId = model.stateId;
          addOrUpdateDefect.craftId = model.craftId;
          addOrUpdateDefect.floorId = model.floorId;
          addOrUpdateDefect.reasonId = model.reasonId;
          addOrUpdateDefect.regionId = model.regionId;
          addOrUpdateDefect.organizationId = model.organizationId;
          addOrUpdateDefect.complainerUserId = model.complainerUserId;
          addOrUpdateDefect.complainerOrganizationId = model.complainerOrganizationId;
          addOrUpdateDefect.userAssignment = userAssignments;
          addOrUpdateDefect.zones = model.zonesIds;
          addOrUpdateDefect.defectImageMetadataId = model.defectImageMetadataId;

          if (!addOrUpdateDefect.stateId || addOrUpdateDefect.stateId == '') {
            addOrUpdateDefect.stateId = offlineServiceStateId;
            addOrUpdateDefect.stateType = model.stateType;
          }

          addOrUpdateDefect.title = model.title;
          addOrUpdateDefect.retention = model.retention;
          addOrUpdateDefect.reduction = model.reduction;
          addOrUpdateDefect.description = model.description;

          addOrUpdateDefect.grace = safeDate(model.grace);
          addOrUpdateDefect.deadline = safeDate(model.deadline);
          addOrUpdateDefect.approvalOfBuilder = safeDate(model.approvalOfBuilder);
          addOrUpdateDefect.approvalExtern = safeDate(model.approvalExtern);
          addOrUpdateDefect.approvalIntern = safeDate(model.approvalIntern);

          const comments = [];

          addOrUpdateDefect.comments = comments;

          const oldDefectId = model.id;

          const createdCommends = await engine.database.getCommentsWhereModeAndDefect(MigrationStatus.INSERT, oldDefectId);

          for (const comment of createdCommends) {
            const commentModel = new CommentModel({
              text: comment.text,
              editorId: comment.editorId,
              // createdBy: comment.createdBy,
              // createdOn: safeDate(comment.createdOn),
              modifiedOn: safeDate(comment.modifiedOn),
            });

            comments.push(commentModel);

            statements.remove(SQLiteTables.Comments, { id: comment.id, project: projectId });
            statements.remove(SQLiteTables.DefectComments, { commentId: comment.id, project: projectId });
          }

          const fileParameters = new Array<FileParameter>();

          const resourceIdentifiers = await getResourceIdentifiers(projectId, engine.database);
          const resource = resourceIdentifiers.find(i => i.moduleType === ModuleType.Defect).key.name;

          if (defect.mode == MigrationStatus.INSERT) {
            const files = await engine.database.getDriveItemsWhereModeIsInsertAndPath(
              projectId,
              '/' + (model.number || oldDefectId),
              resource
            );

            for (const file of files) {
              const directory = getDirectoryPath(projectId, resource, file.path, file.name);
              const array = await fileHandler.readFile(directory + OfflineService.OriginalSuffix, Directory.Data);

              const blob = new Blob([array], { type: file.mimeType });

              fileParameters.push({
                data: blob,
                fileName: file.name,
              });

              statements.remove(SQLiteTables.DriveItems, { id: file.id, project: projectId, resource: resource });
            }
          }

          statements.remove(SQLiteTables.Defects, { id: oldDefectId, project: projectId });

          let wasDefectSaved = false;
          try {
            await engine.defectClient.save(projectId, addOrUpdateDefect, fileParameters).toPromise();
            wasDefectSaved = true;
          } catch (e) {
            const stepError: ActivityStepError = {
              topic: 'offline.activitySteps.errors.topic.defect',
              description: 'offline.activitySteps.errors.reasons.tryagain',
              routerLink: '/defects',
              queryParams: { edit: true, id: model.id },
              linkText: model.title,
            };

            if (e instanceof ProblemDetails) {
              switch (e.type) {
                case ProblemDetailsErrorType.InconsistentModelState:
                case ProblemDetailsErrorType.MissingEntity:
                  stepError.description = 'offline.activitySteps.errors.reasons.defectreferences';
                  break;
                case ProblemDetailsErrorType.BlocklistedName:
                  stepError.description = 'offline.activitySteps.errors.reasons.nameinvalid';
                  break;
                case ProblemDetailsErrorType.PayloadTooBig:
                  stepError.description = 'offline.activitySteps.errors.reasons.filetoobig';
                  break;
              }
            }

            stepUploadDefects.addError(stepError);
          }

          if (wasDefectSaved) await statements.execute();
          else statements.discard();
        } catch (e) {
          stepUploadDefects.addError(UNEXPECTED_ERROR);
          stepUploadDefects.complete();
          return;
        }

        stepUploadDefects.advance();
      }

      stepUploadDefects.complete();

      // Upload Files
      stepUploadFiles.maxProgress = files.length;
      stepUploadFiles.start();

      for (const file of files) {
        try {
          const directory = getDirectoryPath(projectId, file.resource, file.path, file.name);

          const array = await fileHandler.readFile(directory + OfflineService.OriginalSuffix, Directory.Data);

          const blob = new Blob([array], { type: file.mimeType });

          let wasFileUploaded = false;
          try {
            await engine.driveClient.uploadWithProgress(
              projectId,
              { data: blob, fileName: file.name },
              file.path.substring(1),
              file.resource,
              ConflictBehavior.Fail,
              new DriveActionMetadata({
                relatedEntityId: file.relatedEntityId,
              }),
              null
            );

            wasFileUploaded = true;
          } catch (e) {
            const stepError: ActivityStepError = {
              topic: 'offline.activitySteps.errors.topic.file',
              description: 'offline.activitySteps.errors.reasons.tryagain',
              routerLink: '/defects',
              queryParams: { edit: true, id: file.relatedEntityId, files: true },
            };

            const error = e.error;
            if (error?.type) {
              switch (error.type) {
                case ProblemDetailsErrorType.BlocklistedName:
                  stepError.description = 'offline.activitySteps.errors.reasons.nameinvalid';
                  break;
                case ProblemDetailsErrorType.PayloadTooBig:
                  stepError.description = 'offline.activitySteps.errors.reasons.filetoobig';
                  break;
                case ProblemDetailsErrorType.Conflict:
                  stepError.description = 'offline.activitySteps.errors.reasons.alreadyexists';
              }
            }

            stepUploadFiles.addError(stepError);
          }

          if (wasFileUploaded) {
            const resourceIdentifiers = await getResourceIdentifiers(projectId, engine.database);
            const resourceIdentifier = resourceIdentifiers.find(r => r.moduleType === ModuleType.Defect);

            statements.remove(SQLiteTables.DriveItems, {
              id: file.id,
              project: projectId,
              resource: resourceIdentifier.key.name,
            });
            await statements.execute();
          } else {
            statements.discard();
          }
        } catch (e) {
          stepUploadFiles.addError(UNEXPECTED_ERROR);
          stepUploadFiles.complete();
          return;
        }

        stepUploadFiles.advance();
      }

      stepUploadFiles.complete();

      return (
        stepUploadDefects.currentState == ActivityStepState.success && stepUploadFiles.currentState == ActivityStepState.success
      );
    },
  };
}

export function getDirectoryPath(projectId: string, resource: string, filePath: string, fileName: string) {
  return [projectId, resource, filePath, fileName].join('/');
}

export async function getResourceIdentifiers(projectId: string, database: IQueryWrapper): Promise<ResourceIdentifier[]> {
  const items = await database.getResourceIdentifiers(projectId);
  return items?.map(i => ResourceIdentifier.fromJS(JSON.parse(i.json))) ?? [];
}

export function safeDate(s?: string | Moment | Date | null) {
  if (!s || s == null) {
    return null;
  }

  if (typeof s == 'string') {
    return new Date(s);
  }

  if (typeof s == 'number') {
    return new Date(s);
  }

  if (typeof s == 'object') {
    if (s instanceof Date) {
      return s;
    }

    if (isMoment(s)) {
      return s.toDate();
    }
  }

  console.error(s);

  throw new Error('Cannot convert from object to date');
}
