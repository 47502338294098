<form *ngIf="form" class="config-content-container metadata-config" [formGroup]="form">
	<div class="config-content">
		<div class="config-group slim">
			<div class="config-title">{{ 'projectConfig.general.projectDetails' | translate }}</div>

			<mat-form-field class="gap-top">
				<mat-label>{{ 'projectConfig.general.period.title' | translate }}</mat-label>
				<mat-date-range-input [rangePicker]="periodPicker">
					<input matStartDate [placeholder]="'projectConfig.general.period.start' | translate" formControlName="start">
					<input matEndDate [placeholder]="'projectConfig.general.period.end' | translate" formControlName="end">
				</mat-date-range-input>
				<mat-datepicker-toggle matSuffix [for]="periodPicker"></mat-datepicker-toggle>
				<mat-date-range-picker #periodPicker></mat-date-range-picker>
			</mat-form-field>

			<app-search-select *ngIf="isExistingProject" [selectList]="projectOrganizations" [selectFormGroup]="form"
												 [addSelectNone]="true" [title]="'projectConfig.metadata.mainClient' | translate"
												 selectFormControlName="mainClient">
			</app-search-select>
		</div>

		<div class="config-group slim">
			<div class="config-title">{{ 'dialogs.editCompany.addressTitle' | translate }}</div>

			<div class="config-group gap-top">
				<app-address-form class="address-control" formControlName="address"></app-address-form>
				<app-geo-location-form formControlName="location"></app-geo-location-form>
			</div>
		</div>

		<div class="config-group slim">
			<div class="config-title">{{ 'projectConfig.metadata.thirdParties' | translate }}</div>

			<mat-form-field class="gap-top">
				<input matInput formControlName="administration"
							 [placeholder]="'projectConfig.metadata.administration' | translate">
			</mat-form-field>

			<mat-form-field>
				<input matInput formControlName="externalId" [placeholder]="'projectConfig.metadata.externalId' | translate">
			</mat-form-field>

			<mat-form-field>
				<input matInput formControlName="externalSystemName"
							 [placeholder]="'projectConfig.metadata.externalSystemName' | translate">
			</mat-form-field>
		</div>
	</div>
</form>