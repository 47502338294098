import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PipeTransform,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PropertyBagModel } from '@app/api';
import { GlobalsService, Utils } from '@app/core';
import { HeaderTab, HeaderTabData, ITabFilterService, IViewManager, TAB_SERVICE, TableQuery } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { FilterMetadata } from 'primeng/api';
import { SortMeta } from 'primeng/api/sortmeta';
import { Table, TableFilterEvent } from 'primeng/table';
import { Subscription } from 'rxjs';
import { EditGridColumn, EditGridDialogComponent } from '../dialogs/edit-grid-dialog/edit-grid-dialog.component';
import {
  C4Grid,
  C4GridColumn,
  C4GridDef,
  C4GridFilterType,
  C4GridMatchMode,
  C4GridRow,
  C4GridSelectOptions,
  C4SubCol,
} from './grid.interfaces';

export enum GridSelectionMode {
  none = '',
  single = 'single',
  multiple = 'multiple',
}

export enum C4GridUnitType {
  px = 'px',
  em = 'em',
  relative = '*',
}

class GridSize {
  value: number;
  type: C4GridUnitType;
}

class GridColumnModel {
  field: string;
  header?: string;
  noTranslate?: boolean;
  sub?: Array<C4SubCol>;
  sortable?: boolean;
  priority?: number;
  cssClass?: string;
  filterType?: C4GridFilterType;
  filterMatchMode?: C4GridMatchMode;
  filterVal?: any;
  options?: Array<C4GridSelectOptions>;
  currentOptions?: Array<C4GridSelectOptions>;
  pipe?: PipeTransform;
  pipeArg?: string;
  isRange?: boolean;
  template?: TemplateRef<any>;
  headerTemplate?: TemplateRef<any>;
  mobileTemplate?: TemplateRef<any>;
  hidden?: boolean;
  // internal only
  weight: number;
  unit: C4GridUnitType;
  minWidth: number;
  renderer?: string;
  headerRenderer?: string;
  mobileRenderer?: string;
  calcWidth?: string;
  absWidth?: number;
  show?: boolean;
  css?: any;

  constructor(column: C4GridColumn) {
    const ignorePoperties = ['width', 'minWidth'];
    for (const property in column) {
      if (column.hasOwnProperty(property) && !ignorePoperties.contains(property)) {
        this[property] = column[property];
      }
    }
    const parsedWidth = this.parseSize(column.width);
    if (parsedWidth.type === C4GridUnitType.relative) {
      // relative width
      this.weight = parsedWidth.value;
      if (column.minWidth !== null && column.minWidth !== undefined) {
        const parsedMinWidth = this.parseSize(column.minWidth);
        if (parsedMinWidth.type !== C4GridUnitType.relative) {
          this.minWidth = parsedMinWidth.value;
          this.unit = parsedMinWidth.type;
        } else {
          throw new Error('relative values are not allowed in "minWidth"');
        }
      } else {
        this.minWidth = 0;
        this.unit = C4GridUnitType.px;
      }
    } else {
      // absolute width
      this.weight = 0;
      this.minWidth = parsedWidth.value;
      this.unit = parsedWidth.type;
    }
  }

  private parseSize(size: string, defaultType: C4GridUnitType = C4GridUnitType.px): GridSize {
    const regex = /^([0-9\.]+)(px$|em$|\*|\b)/g;
    const match = regex.exec(size);
    const result = new GridSize();
    result.value = Number.parseFloat(match[1]);
    if (match.length === 3 && match[2] !== undefined && match[2] !== null) {
      const unit = match[2];
      result.type = unit as C4GridUnitType;
    } else {
      result.type = defaultType;
    }
    return result;
  }
}

class GridDevModel {
  grid: C4Grid;
  row: C4GridRow;
  cols: GridColumnModel[];
  hiddenVal?: boolean;
  initialSorting: SortMeta[];

  constructor(gridDev: C4GridDef) {
    this.grid = gridDev.grid;
    this.row = gridDev.row;
    this.initialSorting = gridDev.initialSorting;
    this.cols = gridDev.cols.select(column => new GridColumnModel(column));
  }
}

interface IGridComponent {
  readonly grid: GridComponent;
}

export class GridViewManager implements IViewManager {
  constructor(private gridComponent: IGridComponent) {}

  get grid(): GridComponent {
    return this.gridComponent.grid;
  }

  getCurrentHeaderData(): HeaderTabData {
    return {
      tableQuery: {
        sorting: this.grid.dataTable.multiSortMeta ? [...this.grid.dataTable.multiSortMeta] : null,
        filters: this.grid.dataTable.filters ? { ...this.grid.dataTable.filters } : null,
        columns: this.grid.currentFields,
      },
    };
  }

  getViewContent(tab: HeaderTab): string {
    const tabToSave = Object.assign({}, tab);
    delete tabToSave.id;
    delete tabToSave.projectId;
    return JSON.stringify(tabToSave);
  }

  parseView(view: PropertyBagModel): HeaderTab {
    const tab = JSON.parse(view.content) as HeaderTab;
    tab.id = view.id;
    tab.projectId = view.projectId;
    return tab;
  }
}

@Component({
  selector: 'c4-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {
  C4GridFilterType = C4GridFilterType;
  hasData: boolean;
  isBusy: boolean = false;
  afterInitRefine = true;
  multiSortMeta: SortMeta[];
  resourcePrefix: string = 'grid.';
  multiselectLangRes: string = null;
  langSubscription: Subscription;
  rangePrefix: string = Utils.rangePrefix;
  currentFields: string[];
  hideFlicker: boolean = false;
  @Input() selectionMode: GridSelectionMode = GridSelectionMode.none;
  @Input() initready: boolean = true;
  @Input() container: ElementRef<HTMLDivElement>;
  pageStart: number = 0;
  @Input() globalFilterPlaceholder = 'global filter';
  @Input() noDataText = 'no data found';

  private _source: any[];
  get source(): any[] {
    return this._source;
  }
  @Input() set source(value: any[]) {
    this._source = value;
    this.updateSelection(this.selection);
  }

  private _selection: any = null;
  get selection(): any {
    return this._selection;
  }
  @Input() set selection(value: any) {
    this._selection = value;
    this.updateSelection(value);
    this.selectionChange.emit(value);
  }
  @Output() selectionChange = new EventEmitter<any>();

  @Output() action = new EventEmitter();
  @Output() lazy = new EventEmitter();
  @Output() filter = new EventEmitter<Record<string, FilterMetadata>>();
  @ViewChild('gridcontainer', { static: true }) c4Grid: ElementRef<HTMLDivElement>;
  @ViewChild('dt', { static: true }) dataTable: Table;

  @Input()
  set gridDef(gridDev: C4GridDef) {
    this.gridDefModel = new GridDevModel(gridDev);
    this.initialGridDefColumnOrder = gridDev?.cols?.map(col => col.field) ?? [];
    this.updateDef();
  }
  gridDefModel: GridDevModel;
  GridSelectionMode = GridSelectionMode;
  initialGridDefColumnOrder: string[] = [];

  // grid responsive helper & cached vars
  resizeTimer: any;
  hiddenCols: GridColumnModel[] = [];
  visibleCols: number;
  gridWidth: number;
  emPx: number;
  ctrlWidth: string;
  absoluteUnits: boolean = false;
  minGridWidth: number = 0;
  idlerows: number[];

  constructor(
    public globals: GlobalsService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    @Optional() @Inject(TAB_SERVICE) private tabService: ITabFilterService
  ) {
    this.idlerows = Array(5).fill(1); // for template iteration only
    window.addEventListener('resize', e => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(t => {
        this.updateDef();
      }, 250);
    });
  }

  get responsiveColumns(): boolean {
    return this.gridDefModel.grid.mobileExpanded;
  }

  set responsiveColumns(value: boolean) {
    this.gridDefModel.grid.mobileExpanded = value;
    this.updateDef();
  }

  ngOnInit() {
    this.tabService?.filter$.subscribe(headerData => {
      this.filterTable(headerData?.tableQuery);
    });

    this.langSubscription = this.translateService.onLangChange.subscribe((event: any) => {
      this.multiselectLangRes = this.translateService.instant('grid.filter.selectedItems');
      this.updateDef();
    });
    this.resourcePrefix = this.gridDefModel.grid.prefix ? this.gridDefModel.grid.prefix : this.resourcePrefix;

    // timeout needed if grid container width not rendered (e.g. angular material tabs body ...)
    setTimeout(() => {
      this.gridWidth = parseFloat(window.getComputedStyle(this.c4Grid.nativeElement, null).getPropertyValue('width'));
      this.emPx = parseFloat(window.getComputedStyle(this.c4Grid.nativeElement, null).getPropertyValue('font-size'));
      this.gridDefModel.grid.rows = this.gridDefModel.grid.rows ? this.gridDefModel.grid.rows : 10;
      this.gridDefModel.grid.lazy = this.gridDefModel.grid.lazy ? this.gridDefModel.grid.lazy : false;
      this.calcColWidth(this.gridDefModel.cols);
      this.refineData(this.source, this.gridDefModel.cols);
      this.multiSortMeta =
        !this.multiSortMeta && this.gridDefModel.initialSorting && this.gridDefModel.initialSorting.length > 0
          ? this.gridDefModel.initialSorting
          : this.multiSortMeta;
    }, 0);
  }

  get multistring() {
    const response = this.multiselectLangRes
      ? this.multiselectLangRes
      : this.translateService.instant('grid.filter.selectedItems');
    return response;
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
      this.langSubscription = undefined;
    }
  }

  c4Sort({ multisortmeta }: { multisortmeta: SortMeta[] }) {
    if (multisortmeta.length == 0) return;

    const sortVal = multisortmeta[0];
    const data =
      this.dataTable.filteredValue && this.dataTable.filteredValue.length > 0
        ? this.dataTable.filteredValue
        : this.dataTable.value;
    data.sort((a, b) => {
      if (a.type === b.type) {
        const rKey = Utils.rangePrefix + sortVal.field; // sort range fields by value ( not range)
        if (a[rKey] && b[rKey]) {
          return (~~a[rKey] < ~~b[rKey] ? -1 : ~~a[rKey] > ~~b[rKey] ? 1 : 0) * sortVal.order;
        } else {
          return (a[sortVal.field] < b[sortVal.field] ? -1 : a[sortVal.field] > b[sortVal.field] ? 1 : 0) * sortVal.order;
        }
      } else {
        return a.type > b.type ? -1 : 1;
      }
    });
  }

  updateDef() {
    this.currentFields = [];
    const grid = this.c4Grid.nativeElement;
    this.gridWidth = parseFloat(window.getComputedStyle(grid, null).getPropertyValue('width'));
    this.emPx = parseFloat(window.getComputedStyle(grid, null).getPropertyValue('font-size'));
    this.calcColWidth(this.gridDefModel.cols);
    this.refineData(this.source, this.gridDefModel.cols);
    this.gridDefModel.cols.forEach(c => {
      if (!c.hidden) this.currentFields.push(c.field);
    });
  }

  ngOnChanges() {
    if (!this.source) {
      this.hasData = false;
    } else {
      this.hasData = this.source.length > 0;
      if (this.hasData) {
        this.reduceOptions();
        this.refineOptions(this.source);
      }
    }
  }

  setCurrentOptions() {
    const data =
      this.dataTable.filteredValue && this.dataTable.filteredValue.length > 0 ? this.dataTable.filteredValue : this.source;
    this.refineOptions(data);
  }

  goToFirstPage() {
    this.dataTable.first = 0;
  }

  onFilter(event: TableFilterEvent) {
    if (this.gridDefModel.grid.lazy) return;
    this.hasData = event.filteredValue.length > 0;
    this.dataTable.totalRecords = event.filteredValue.length;
    this.dataTable.paginator = this.dataTable.totalRecords > this.gridDefModel.grid.rows;
    this.dataTable.pageLinks = Math.min(5, Math.ceil(event.filteredValue.length / this.gridDefModel.grid.rows));
    // if (this.container) this.container.nativeElement.setAttribute('paginator', this.dataTable.paginator ? 'shown' : 'hidden');
    this.isBusy = false;
    this.refineOptions(this.dataTable.filteredValue);
    for (const [field, filter] of Object.entries(event.filters)) {
      const column = this.gridDefModel.cols.find(c => c.field === field);
      if (column) column.filterVal = filter.value;
    }
    this.pageStart =
      this.dataTable.totalRecords > this.dataTable.rows
        ? this.pageStart
        : Math.floor(this.dataTable.totalRecords / this.dataTable.rows) * this.dataTable.rows;
    this.dataTable.first = this.pageStart;
    this.filter.emit(event.filters);
  }

  refineData(data: any[], cols: GridColumnModel[]) {
    cols.forEach(c => {
      if (c.hidden) return;
      // header
      if (c.header === null || c.header === undefined) {
        c.header = c.field;
      }
      // rendertype
      c.renderer = c.template ? 'template' : 'default';
      c.headerRenderer = c.headerTemplate ? 'template' : 'default';
      c.mobileRenderer = c.mobileTemplate ? 'template' : 'default';
      // filterType
      if (c.filterType && !c.options) {
        if (c.filterType === 'select' || c.filterType === 'multiselect') {
          const selects = {};
          data.forEach(d => {
            if (d[c.field]) {
              const key = '' + d[c.field];
              selects[key] = true;
            }
          });
          c.options = [];
          Object.keys(selects).forEach(k => {
            c.options.push({ label: k, value: k });
          });
        }
      }
    });
    this.refineOptions(data);
  }

  reduceOptions() {
    this.gridDefModel.cols.forEach(c => {
      if (c.options && !c.hidden) {
        const selects = {};
        this.source.forEach(d => {
          if (d[c.field] || d[c.field] === false) {
            const key = '' + d[c.field];
            selects[key] = true;
          }
        });
        c.currentOptions = [];
        c.options.forEach(co => {
          if (selects[co.value] || selects[co.value] === false) c.currentOptions.push(co);
        });
      }
    });
  }

  refineOptions(data: any[], col?: string) {
    if (!data) data = this.source;
    this.gridDefModel.cols.forEach(c => {
      if (c.currentOptions) {
        const selects = {};
        data.forEach(d => {
          if (d[c.field]) {
            const key = '' + d[c.field];
            selects[key] = true;
          }
        });
        c.currentOptions.forEach(co => {
          co.visible = selects[co.value];
        });
      }
    });
  }

  calcColWidth(cols: GridColumnModel[]) {
    let colCount = 0;
    this.absoluteUnits = false;
    let fits = false; // current table fits in container
    let priorities = []; // lowest possible priority
    const showAllCols =
      this.gridDefModel.grid.mobileExpanded || (!this.gridDefModel.grid.responsive && this.globals.viewport === 'desk');
    cols.forEach(c => {
      if (c.hidden) return;
      c.css = c.css ? c.css : '';
      c.show = true; // cell visbility state
      const prio = showAllCols ? 1 : c.priority;
      priorities.push(prio); // get all possible priorities
      priorities = this.orderPriorities(priorities);
      c.absWidth = !c.unit || c.unit === 'px' ? c.minWidth : c.minWidth * this.emPx;
    });
    let iteration = 0;
    // iterate till fits stays true
    while (!fits && priorities.length > 0) {
      this.hiddenCols = [];
      this.minGridWidth = 0;
      fits = true;
      colCount = 0;
      this.visibleCols = 0;
      cols.forEach(c => {
        if (c.hidden) return;
        const prio = showAllCols ? 1 : c.priority;
        if (prio && prio <= priorities[0]) {
          colCount += c.weight; // update colCount
          this.minGridWidth += c.absWidth;
          this.visibleCols++;
        }
      });
      let remainingWidth = this.gridWidth - this.minGridWidth;

      if (remainingWidth < 0 && priorities.length === 1) {
        remainingWidth = 0;
        this.absoluteUnits = true;
      }
      if (this.minGridWidth <= this.gridWidth || priorities.length === 1) {
        cols.forEach(c => {
          if (c.hidden) return;
          const prio = showAllCols ? 1 : c.priority;
          if (prio && prio <= priorities[0]) {
            const colPxWidth = c.absWidth + (remainingWidth * c.weight) / colCount;
            c.calcWidth = this.absoluteUnits || c.weight === 0 ? c.absWidth + 'px' : (colPxWidth / this.gridWidth) * 100 + '%';
          } else {
            c.show = false;
            c.calcWidth = '0';
            this.hiddenCols.push(c);
          }
        });
      } else {
        fits = false;
        if (iteration === 0 && this.gridDefModel.grid.rowExpand) {
          this.gridWidth = this.gridWidth - 2 * this.emPx;
          iteration++;
        }
      }
      this.ctrlWidth = 2 * this.emPx + 'px';
      if (priorities.length > 1) {
        priorities.shift();
      }
      this.gridDefModel.hiddenVal = this.hiddenCols.length > 0;
    }
  }

  orderPriorities(prio: number[]) {
    return prio.sort((a, b) => b - a).filter((p, i, arr) => arr.indexOf(p) === i); // sorted distinct array of all 'known' priorities ;)
  }

  canExpand(row: any): boolean {
    //TODO: quick fix (need to add property to actual data row), think of a more generic approach in future
    return !row.c4_grid_disable_expand;
  }

  showHide(row: any, event: MouseEvent) {
    event.stopPropagation();
    if (row.expand) {
      row.expand = false;
    } else {
      row.expand = true;
    }
  }
  gridAction(action: string, dataObj: any) {
    const gridEvent = { event: action, data: dataObj };
    this.action.emit(gridEvent);
  }
  fetch(obj: any) {
    this.lazy.emit(obj);
  }

  async editGridColumns(dlgTitle: string) {
    let editColumns: EditGridColumn[] = [];

    const prefixConst = this.gridDefModel.grid.prefix ? this.gridDefModel.grid.prefix : 'grid.';
    this.gridDefModel.cols.forEach(c => {
      const prefix = c.noTranslate ? '' : prefixConst;
      const col = {
        field: c.field,
        header: c.header && c.header !== 'none' ? prefix + c.header : null,
        hidden: c.hidden ? true : false,
      };
      editColumns.push(col);
    });

    editColumns = await this.dialog
      .open(EditGridDialogComponent, {
        panelClass: 'right-dlg',
        data: { title: dlgTitle, columns: editColumns },
      })
      .afterClosed()
      .toPromise();

    if (editColumns) {
      const visibleColumnFields = editColumns.filter(c => !c.hidden).map(c => c.field);
      this.updateGridCols(visibleColumnFields);
    }
  }

  updateGridCols(visibleColumnFields: string[], showAllColumns: boolean = false) {
    for (const column of this.gridDefModel.cols)
      column.hidden = !showAllColumns && visibleColumnFields.indexOf(column.field) < 0;

    const correctOrderedColumns = !showAllColumns ? visibleColumnFields : this.initialGridDefColumnOrder;
    for (const column of correctOrderedColumns) {
      const index = this.gridDefModel.cols.findIndex(col => col.field === column);
      this.gridDefModel.cols.push(this.gridDefModel.cols.splice(index, 1)[0]);
    }

    this.updateDef();
  }

  toggleSelectionOnAllVisibleRows() {
    const data = this.dataTable.filteredValue ?? this.dataTable.value ?? [];
    const visibleData = data.slice(this.dataTable.first, this.dataTable.first + this.dataTable.rows);

    const areAllVisibleSelected = visibleData.every(r => r.selected);
    const select = !areAllVisibleSelected;
    visibleData.forEach(row => {
      row.selected = select;
    });

    return data.filter(r => r.selected);
  }

  private updateSelection(value: any) {
    for (const row of this.source ?? []) {
      row.selected = false;
    }

    if (value) {
      if (value instanceof Array) {
        for (const item of value) {
          item.selected = true;
        }
      } else {
        value.selected = true;
      }
    }
  }

  filterChanged() {}

  filterTable(tableQuery: TableQuery) {
    if (!tableQuery) return;

    this.pageStart = this.dataTable.first;

    // show / hide columns
    if (tableQuery.columns || tableQuery.standardCols) {
      this.updateGridCols(tableQuery.columns, tableQuery.standardCols);
    }

    // set table filter
    const filters = tableQuery.filters ?? {};
    this.gridDefModel.cols.forEach(column => {
      const filter = filters[column.field] as FilterMetadata;
      let filterValue = Array.isArray(filter?.value) ? [...filter.value] : filter?.value;
      filterValue = filterValue ?? (column.filterType === C4GridFilterType.multiselect ? [] : '');

      if (!this.isFilterEqual(column.filterVal, filterValue)) {
        column.filterVal = filterValue;

        let matchMode: string = column.filterMatchMode;

        switch (column.filterType) {
          case C4GridFilterType.date:
            matchMode = 'dateIs';
            break;
          case C4GridFilterType.multiselect:
            matchMode = C4GridMatchMode.in;
            break;
          case C4GridFilterType.select:
            matchMode = C4GridMatchMode.equals;
            break;
        }

        this.dataTable.filter(column.filterVal, column.field, matchMode);
      }
    });

    this.multiSortMeta = tableQuery.sorting;
    this.dataTable.pageLinks = 5;
    this.gridDefModel.grid.rowCount = this.dataTable.filteredValue ? this.dataTable.filteredValue.length : this.source.length;
  }

  private isFilterEqual<T extends string | string[]>(filter: T, other: T) {
    if (typeof filter === 'string') return filter === other;
    if (typeof other === 'string') return false;

    if (!filter || !other) return filter === other;

    return filter.length === other.length && filter.every(value => other.some(otherValue => value === otherValue));
  }
}
