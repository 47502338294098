import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  MatLegacyTab as MatTab,
  MatLegacyTabChangeEvent as MatTabChangeEvent,
  MatLegacyTabGroup as MatTabGroup,
} from '@angular/material/legacy-tabs';
import {
  ChangeContentType,
  ChangeModel,
  ChangeSetModel,
  CommentModel,
  CraftModel,
  DriveActionMetadata,
  LeanPhaseModel,
  LeanSwimlaneModel,
  LeanWorkpackageDependencyModel,
  LeanWorkpackageModel,
  LeanWorkpackageSwimlaneModel,
  LeanWorkpackageTemplateModel,
  ModuleType,
  OrganizationModel,
  ProblemDetails,
  UserSessionModel,
  WorkpackageState,
  WorkpackageStateProjectEntityState,
  LeanStatePermission,
  PrivilegeEnum,
} from '@app/api';
import {
  ApiService,
  AppRoutingData,
  CustomValidators,
  getStatesFromModel,
  GlobalFormStateTrackerService,
  LogService,
  nameof,
  pathFragmentsTo,
  ProjectService,
  Utils,
} from '@app/core';
import { EntityDetailDialogTabType } from '@app/core/enumerations';
import {
  EntityDialogTitle,
  HistoryChangeType,
  HistoryComponent,
  HistoryStateChange,
  HistoryStateTemplateType,
  WorkpackageModel,
  WorkpackageStateOption,
} from '@app/shared/components';
import {
  CommentsService,
  DocumentsService,
  ICommentsService,
  UserNotificationService,
  WorkpackageCommentsApiService,
} from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import * as moment from 'moment';
import { DependencyType } from '../interfaces';
import { AddDependencyDialogComponent } from '../add-dependency-dialog/add-dependency-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { skip } from 'rxjs/operators';
import { BaseTasklikeDialogComponent } from '@app/core/utils/base-tasklike-dialog-component';

const boolFieldNames: string[] = [
  nameof<LeanWorkpackageModel>('constraintClarificationNeeded'),
  nameof<LeanWorkpackageModel>('constraintEquipment'),
  nameof<LeanWorkpackageModel>('constraintExternalFactors'),
  nameof<LeanWorkpackageModel>('constraintInformation'),
  nameof<LeanWorkpackageModel>('constraintLabor'),
  nameof<LeanWorkpackageModel>('constraintMaterial'),
  nameof<LeanWorkpackageModel>('constraintPreliminary'),
  nameof<LeanWorkpackageModel>('constraintSafety'),
  nameof<LeanWorkpackageModel>('disturbanceOverestimationPerformance'),
  nameof<LeanWorkpackageModel>('disturbanceReductionEmployees'),
  nameof<LeanWorkpackageModel>('disturbanceIncorrectInfo'),
  nameof<LeanWorkpackageModel>('disturbanceDelayedMaterial'),
  nameof<LeanWorkpackageModel>('disturbanceMissingPreliminary'),
  nameof<LeanWorkpackageModel>('disturbanceInterfaceWorkpackages'),
  nameof<LeanWorkpackageModel>('disturbanceExternalConditions'),
  nameof<LeanWorkpackageModel>('disturbancePriorityChange'),
  nameof<LeanWorkpackageModel>('disturbanceChangeWorkScope'),
  nameof<LeanWorkpackageModel>('disturbanceRemainingWork'),
  nameof<LeanWorkpackageModel>('disturbanceOther'),
  nameof<LeanWorkpackageModel>('disturbanceDelayedEquipment'),
];
const dateFieldNames: string[] = [nameof<LeanWorkpackageModel>('startDate'), nameof<LeanWorkpackageModel>('endDate')];
const translationFieldNames: Record<string, string> = {
  state: 'workpackages.state', // legacy translations
};

interface DependencyItem {
  dependencyType: DependencyType;
  entityId: string;
  dependencyId?: string;
  number?: number;
  name?: string;
  stateTitle?: string;
  stateIconClass?: string;
  createdOn?: Date;
  createdBy?: string;
  route: {
    routerLink: string[];
    queryParams?: Record<string, string | number | boolean>;
  };
}

export interface WorkpackageEditParams {
  editWorkpackage: boolean;
  workpackageId: string;
}

@Component({
  selector: 'app-workpackage-edit-dialog',
  templateUrl: './workpackage-edit-dialog.component.html',
  styleUrls: ['./workpackage-edit-dialog.component.scss'],
  providers: [forwardRef(() => DocumentsService)],
})
export class WorkpackageEditDialogComponent
  extends BaseTasklikeDialogComponent<WorkpackageEditDialogComponent>
  implements OnInit, OnDestroy, AfterViewInit, Busy
{
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('detailsTab') detailsTab: MatTab;
  @ViewChild('historyTab') historyTab: MatTab;
  @ViewChild('fileTab') fileTab: MatTab;

  isBusy: boolean;
  dialogTitle: EntityDialogTitle;

  isTemplate: boolean = false;
  modelId: string;
  model: LeanWorkpackageModel | LeanWorkpackageTemplateModel;
  commentsService: ICommentsService;

  user: UserSessionModel;
  organizations: OrganizationModel[] = [];
  crafts: CraftModel[] = [];
  phases: LeanPhaseModel[] = [];
  phasesWithSwimlanes: LeanPhaseModel[] = [];
  stateOptions: WorkpackageStateOption[] = [];

  stateChanges: HistoryStateChange[] = [];
  changeSets: ChangeSetModel[] = [];
  showHistory = false;

  selectedTabIndex: number = 0;
  wasFileTabActivatedWithWorkpackage: boolean = false;

  private resource: string;
  private initialTabType: EntityDetailDialogTabType;
  private allOrganizations: OrganizationModel[] = [];
  private projectId: string;
  private updatedIds: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private commentsApiService: WorkpackageCommentsApiService,
    private documentsService: DocumentsService,
    private formBuilder: UntypedFormBuilder,
    private log: LogService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: GlobalFormStateTrackerService,
    private userNotification: UserNotificationService,
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkpackageEditDialogComponent>
  ) {
    super(dialog, dialogRef);

    if (data?.isTemplate == undefined) {
      this.log.error('Need to specify if work package is template or not in detail dialog!');
      this.closeDialog();
    }

    this.modelId = data.id;
    this.initialTabType = data.initialTabType ?? EntityDetailDialogTabType.details;
    this.isTemplate = !!data.isTemplate;
  }

  get isNew(): boolean {
    return !this.modelId;
  }

  get number(): string {
    const workpackage = this.model as LeanWorkpackageModel;
    return workpackage?.number ? workpackage.number.toString() : '';
  }

  get fConstraints() {
    const constraints = this.f?.constraints as UntypedFormGroup;
    return constraints?.controls;
  }

  get fDisturbances() {
    const disturbances = this.f?.disturbances as UntypedFormGroup;
    return disturbances?.controls;
  }

  async ngOnInit() {
    const queryParams: WorkpackageEditParams = {
      editWorkpackage: true,
      workpackageId: this.modelId,
    };

    this.dialogTitle = {
      icon: 'mdi-clipboard-outline',
      labelKey: (this.isTemplate ? 'workpackageTemplates' : 'workpackages') + '.element',
      routerLink: [], // relative to component
      queryParams: this.isTemplate ? null : { ...queryParams },
    };

    this.stateService.trackFormState(this);
    this.commentsService = new CommentsService(this.commentsApiService, this.userNotification);
    this.commentsService.isBusy = true;

    this.initForm();

    await using(new BusyScope(this), async _ => {
      if (!this.isTemplate) {
        try {
          const resourceIdentifiers = await this.apiService.getResourceIdentifiers();

          this.resource = resourceIdentifiers.find(i => i.moduleType === ModuleType.Lean).key.name;
        } catch (e) {
          this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.settings', e);

          this.closeDialog();
        }

        const driveActionMetadata: DriveActionMetadata = this.isNew
          ? null
          : new DriveActionMetadata({
              relatedEntityId: this.modelId,
            });

        await this.documentsService.initialize(ModuleType.Lean, {
          isAddFileAllowed: true,
          isAddFolderAllowed: false,
          driveActionMetadata: driveActionMetadata,
        });
      }
      // await this.getDefaultValues();
      await this.loadSelections();
    });

    this.subscribe(this.projectService.projectId$, async projectId => {
      let privileges: PrivilegeEnum[] = [];
      // let project: ProjectModel;
      this.projectId = projectId;
      if (projectId) {
        privileges = await this.apiService.getUserPrivileges();
        // project = await this.apiService.getProject(projectId);
      }

      this.showHistory = privileges.includes(PrivilegeEnum.LeanProtocol);
      // this.isIntern = privileges.includes(PrivilegeEnum.ReadWriteDefects);
      // this.currentProjectId = projectId;
      // this.externalProjectId = project?.externalId;

      await using(new BusyScope(this), async _ => {
        await this.loadWorkpackage();
        // this.updateFileNameFormat();
        await this.resetFormGroup();
        // this.setDefaultValues();
      });
    });

    this.subscribe(this.route.queryParams.pipe(skip(1)), async params => {
      const isWorkpackageEdit = params[nameof<WorkpackageEditParams>('editWorkpackage')] === 'true';
      const workpackageId = params[nameof<WorkpackageEditParams>('workpackageId')];
      if (isWorkpackageEdit && workpackageId) {
        if (workpackageId != this.modelId) {
          const canLeave = await this.canLeave();
          if (canLeave) {
            this.dialogTitle.queryParams[nameof<WorkpackageEditParams>('workpackageId')] = workpackageId;
            this.modelId = workpackageId;
            await using(new BusyScope(this), async _ => {
              if (!this.isTemplate) {
                this.documentsService.updateDriveActionMetadata(
                  new DriveActionMetadata({
                    relatedEntityId: this.modelId,
                  })
                );
              }
              await this.loadWorkpackage();
              await this.resetFormGroup();
            });
          } else {
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                ...params,
                [nameof<WorkpackageEditParams>('workpackageId')]: this.modelId,
              },
            });
          }
        }
      } else {
        const canLeave = await this.canLeave();
        if (canLeave) {
          this.closeDialog();
        } else {
          const queryParams: WorkpackageEditParams = {
            editWorkpackage: true,
            workpackageId: this.modelId,
          };

          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              ...params,
              ...queryParams,
            },
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.documentsService.dispose();
    this.stateService.untrackFormState(this);
    return super.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.setSelectedTab(this.initialTabType);
  }

  async tabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    document.getElementById('dialogScrollContentPhone').scrollTop = 0;

    // needed to load grid in tab after enough space is aquired
    // else it would be mobile view
    if (!this.wasFileTabActivatedWithWorkpackage && event.tab === this.fileTab && event.tab.isActive) {
      this.wasFileTabActivatedWithWorkpackage = true;
    } else if (this.isNew && event.tab !== this.fileTab) {
      this.wasFileTabActivatedWithWorkpackage = false;
    }
  }

  async save(closeAfterSave: boolean = true) {
    if (!this.form.valid) return;

    const result = this.parseForm();

    await using(new BusyScope(this), async busy => {
      try {
        if (this.isTemplate) {
          this.modelId = await this.apiService.saveWorkpackageTemplate(result as LeanWorkpackageTemplateModel);
        } else {
          this.modelId = await this.apiService.saveWorkpackage(
            result as LeanWorkpackageModel
            // ,
            // this.attachedFiles.map(f => ({ fileName: f.name, data: f }))
          );

          await this.setCommentService(this.modelId);

          this.documentsService.updateDriveActionMetadata(
            new DriveActionMetadata({
              relatedEntityId: this.modelId,
            })
          );
        }

        this.modelUpdated(this.modelId);
        this.form.markAsPristine();
        this.f.comment.setValue('');

        this.userNotification.notify('general.successMsg.save');

        if (closeAfterSave) this.closeDialog();
      } catch (e) {
        if (e instanceof ProblemDetails) {
          this.userNotification.notify('workpackages.error.cyclicDependency', { error: e });
        } else {
          this.userNotification.notify('workpackages.error.' + (!this.modelId ? 'create' : 'edit'), { error: e });
        }
      }
    });
  }

  async reset() {
    if (await this.canLeave()) {
      await using(new BusyScope(this), async _ => {
        await this.loadWorkpackage();
        await this.resetFormGroup();
        this.form.markAsPristine();
      });
    }
  }

  async cancel() {
    if (await this.canLeave()) {
      this.closeDialog();
    }
  }

  async addDependency() {
    const predecessors = this.f.predecessors.value ?? [];
    const successors = this.f.successors.value ?? [];
    const foreignSwimlanes = this.f.foreignSwimlanes.value ?? [];
    const dialogResult = await this.dialog
      .open(AddDependencyDialogComponent, {
        data: {
          existingWorkpackageIds: [this.modelId, ...predecessors.map(p => p.entityId), ...successors.map(p => p.entityId)],
          existingSwimlaneIds: [this.f.mainSwimlaneId.value, ...foreignSwimlanes.map(p => p.entityId)],
        },
      })
      .afterClosed()
      .toPromise();

    const type = dialogResult?.dependencyType;
    switch (type) {
      case DependencyType.predecessor:
        this.f.predecessors.value.push(this.getWorkpackageDependencyItem(type, dialogResult.dependency));
        this.f.predecessors.markAsDirty();
        break;
      case DependencyType.successor:
        this.f.successors.value.push(this.getWorkpackageDependencyItem(type, dialogResult.dependency));
        this.f.successors.markAsDirty();
        break;
      case DependencyType.foreignSwimlane:
        this.f.foreignSwimlanes.value.push(this.getSwimlaneDependencyItem(dialogResult.dependency));
        this.f.foreignSwimlanes.markAsDirty();
        break;
    }
  }

  removeDependency(item: DependencyItem) {
    switch (item.dependencyType) {
      case DependencyType.predecessor:
        this.f.predecessors.value.remove(item);
        this.f.predecessors.markAsDirty();
        break;
      case DependencyType.successor:
        this.f.successors.value.remove(item);
        this.f.successors.markAsDirty();
        break;
      case DependencyType.foreignSwimlane:
        this.f.foreignSwimlanes.value.remove(item);
        this.f.foreignSwimlanes.markAsDirty();
        break;
    }
  }

  uploadFiles(files: File[]) {
    this.documentsService.uploadFiles(files);
  }

  private closeDialog() {
    using(new BusyScope(this), async _ => {
      await this.commentsService.disposeObservables();
    }).then(() => this.dialogRef.close(this.updatedIds));
  }

  private initForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      state: [],
      organization: [],
      craft: [],
      phase: [],
      code: [],
      startdate: [{ value: null, disabled: this.isTemplate }, Validators.required],
      enddate: [{ value: null, disabled: this.isTemplate }],
      description: [],
      cost: [],
      costValue: [],
      costFactor: [],
      capacity: [],
      comment: [],
      predecessors: [],
      successors: [],
      foreignSwimlanes: [],

      mainSwimlaneId: [{ value: null, disabled: this.isTemplate }, [Validators.required]],

      constraints: this.formBuilder.group({
        labor: [false],
        information: [false],
        equipment: [false],
        material: [false],
        preliminary: [false],
        safety: [false],
        externalfactors: [false],
        clarificationneeded: [false],
      }),
      disturbances: this.formBuilder.group({
        overestimationperformance: [false],
        reductionemployees: [false],
        incorrectinfo: [false],
        delayedmaterial: [false],
        missingpreliminary: [false],
        interfaceworkpackages: [false],
        externalconditions: [false],
        prioritychange: [false],
        changeworkscope: [false],
        remainingwork: [false],
        other: [false],
        delayedequipment: [false],
      }),
    });

    this.f.enddate.setValidators([Validators.required, CustomValidators.afterDate(this.f.startdate, true)]);
    this.f.startdate.valueChanges.subscribe(_ => this.f.enddate.updateValueAndValidity());

    this.f.craft.valueChanges.subscribe(craft => this.craftChanged(craft));
    this.f.mainSwimlaneId.valueChanges.subscribe(id => this.mainSwimlaneChanged(id));

    this.f.state.valueChanges.subscribe((state: WorkpackageStateProjectEntityState) => {
      if (state.stateType != WorkpackageState.Open)
        this.f.constraints.setValue({
          labor: false,
          information: false,
          equipment: false,
          material: false,
          preliminary: false,
          safety: false,
          externalfactors: false,
          clarificationneeded: false,
        });
    });

    this.f.constraints.valueChanges.subscribe(constraints => {
      if (Object.values(constraints).some(c => !!c)) {
        const firstOpenStateOption = this.stateOptions.find(s => s.state.stateType == WorkpackageState.Open);
        if (firstOpenStateOption) this.f.state.setValue(firstOpenStateOption.state);
      }
    });
  }

  private parseForm(): LeanWorkpackageModel | LeanWorkpackageTemplateModel {
    const propertiesInCommon = {
      id: this.modelId,
      name: this.f.name.value,
      craftId: this.f.craft.value?.id,
      code: this.f.code.value,
      description: this.f.description.value,
      costValue: this.f.costValue.value,
      costFactor: this.f.costFactor.value,
      capacity: this.f.capacity.value,
      comments: this.f.comment.value ? [new CommentModel({ text: this.f.comment.value })] : [],
    };

    if (this.isTemplate) {
      return new LeanWorkpackageTemplateModel({
        ...propertiesInCommon,
        phaseId: this.f.phase.value?.id,
      });
    } else {
      const workpackage = this.model as LeanWorkpackageModel;
      const mainSwimlaneDependency = workpackage.swimlanes.find(swimlane => swimlane.isMain);
      const foreignSwimlanes: DependencyItem[] = this.f.foreignSwimlanes.value;
      const swimlanes = [
        new LeanWorkpackageSwimlaneModel({
          isMain: true,
          swimlaneId: this.f.mainSwimlaneId.value,
          workpackageSwimlaneId: mainSwimlaneDependency?.workpackageSwimlaneId,
        }),
        ...foreignSwimlanes.map(
          dependency =>
            new LeanWorkpackageSwimlaneModel({
              isMain: false,
              swimlaneId: dependency.entityId,
              workpackageSwimlaneId: dependency?.dependencyId,
            })
        ),
      ];

      const dependencyItemToModel = (existingDependencies: LeanWorkpackageDependencyModel[]) => {
        return (item: DependencyItem) => {
          const workpackageDependency = existingDependencies?.find(d => d.workpackageDependencyId == item.dependencyId);
          return new LeanWorkpackageDependencyModel({
            workpackageDependencyId: item.dependencyId,
            otherId: item.entityId,
            fromSide: workpackageDependency?.fromSide ?? 'end',
            toSide: workpackageDependency?.toSide ?? 'start',
          });
        };
      };

      const predecessors: DependencyItem[] = this.f.predecessors.value;
      const precedingDependencies = [...predecessors.map(dependencyItemToModel(workpackage.precedingDependencies))];

      const successors: DependencyItem[] = this.f.successors.value;
      const successiveDependencies = [...successors.map(dependencyItemToModel(workpackage.successiveDependencies))];

      let endDate = moment(this.f.enddate.value);
      let startDate = moment(this.f.startdate.value);
      if (!endDate.isSame(startDate)) {
        endDate = endDate.startOf('day').add(1, 'day');
      }

      return new LeanWorkpackageModel({
        ...propertiesInCommon,
        stateId: this.f.state.value.key,
        organizationId: this.f.organization.value?.id,
        startDate: startDate.toLocalDate(),
        endDate: endDate.toLocalDate(),
        cost: this.f.cost.value,
        comments: this.f.comment.value ? [new CommentModel({ text: this.f.comment.value })] : [],
        swimlanes,
        precedingDependencies,
        successiveDependencies,
        constraintLabor: this.fConstraints.labor.value,
        constraintInformation: this.fConstraints.information.value,
        constraintEquipment: this.fConstraints.equipment.value,
        constraintMaterial: this.fConstraints.material.value,
        constraintPreliminary: this.fConstraints.preliminary.value,
        constraintSafety: this.fConstraints.safety.value,
        constraintExternalFactors: this.fConstraints.externalfactors.value,
        constraintClarificationNeeded: this.fConstraints.clarificationneeded.value,
        disturbanceOverestimationPerformance: this.fDisturbances.overestimationperformance.value,
        disturbanceReductionEmployees: this.fDisturbances.reductionemployees.value,
        disturbanceIncorrectInfo: this.fDisturbances.incorrectinfo.value,
        disturbanceDelayedMaterial: this.fDisturbances.delayedmaterial.value,
        disturbanceMissingPreliminary: this.fDisturbances.missingpreliminary.value,
        disturbanceInterfaceWorkpackages: this.fDisturbances.interfaceworkpackages.value,
        disturbanceExternalConditions: this.fDisturbances.externalconditions.value,
        disturbancePriorityChange: this.fDisturbances.prioritychange.value,
        disturbanceChangeWorkScope: this.fDisturbances.changeworkscope.value,
        disturbanceRemainingWork: this.fDisturbances.remainingwork.value,
        disturbanceOther: this.fDisturbances.other.value,
        disturbanceDelayedEquipment: this.fDisturbances.delayedequipment.value,
      });
    }
  }

  private async loadSelections() {
    try {
      let [user, organizations, crafts, phases] = await Promise.all([
        Utils.enhanceException(this.apiService.getUserSession(), 'userData'),
        this.isTemplate
          ? Promise.resolve([] as OrganizationModel[])
          : Utils.enhanceException(this.apiService.getOrganizationsForProject(), 'organizations'),
        Utils.enhanceException(this.apiService.getCrafts(), 'crafts'),
        Utils.enhanceException(this.apiService.getPhases(), 'phases'),
      ]);

      this.user = user;
      this.allOrganizations = this.organizations = organizations;
      this.crafts = crafts;
      this.phases = phases;
      this.phasesWithSwimlanes = phases.filter(phase => !!phase.swimlanes?.length);
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + (e.translationKey ?? 'userData'),
        e
      );
      this.closeDialog();
    }
  }

  private getWorkpackageDependencyItem(
    type: DependencyType,
    workpackage: LeanWorkpackageModel,
    dependencyId: string = null
  ): DependencyItem {
    const queryParams: WorkpackageEditParams = {
      editWorkpackage: true,
      workpackageId: workpackage.id,
    };

    return {
      dependencyType: type,
      entityId: workpackage.id,
      dependencyId,
      number: workpackage.number,
      name: workpackage.name,
      stateTitle: workpackage.currentState.title,
      stateIconClass: WorkpackageModel.getIconClass(workpackage.currentState.stateType),
      createdOn: workpackage.createdOn,
      createdBy: workpackage.createdBy,
      route: {
        routerLink: [], // relative to component
        queryParams: { ...queryParams },
      },
    };
  }

  private getSwimlaneDependencyItem(swimlane: LeanSwimlaneModel, dependencyId: string = null): DependencyItem {
    return {
      dependencyType: DependencyType.foreignSwimlane,
      entityId: swimlane.id,
      dependencyId,
      name: `${swimlane.phaseName} / ${swimlane.name}`,
      route: {
        routerLink: pathFragmentsTo(this.projectId, AppRoutingData.scheduler.path),
      },
    };
  }

  private async loadDependencies() {
    const workpackage = this.model as LeanWorkpackageModel;
    const predecessors: DependencyItem[] = [],
      successors: DependencyItem[] = [],
      foreignSwimlanes: DependencyItem[] = [];

    try {
      const dependencies = await this.apiService.getWorkpackageDependencies(workpackage.id);
      for (const dependency of workpackage?.precedingDependencies ?? []) {
        const predecessor = dependencies.find(d => d.id == dependency.otherId);

        if (predecessor) {
          predecessors.push(
            this.getWorkpackageDependencyItem(DependencyType.predecessor, predecessor, dependency.workpackageDependencyId)
          );
        }
      }

      for (const dependency of workpackage?.successiveDependencies ?? []) {
        const successor = dependencies.find(d => d.id == dependency.otherId);

        if (successor) {
          successors.push(
            this.getWorkpackageDependencyItem(DependencyType.successor, successor, dependency.workpackageDependencyId)
          );
        }
      }
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog(`general.errorFailedToLoadDataKeys.dependencies`, e);
      this.closeDialog();
    }

    const allSwimlanes = this.phases.flatMap(phase => phase.swimlanes);
    for (const dependency of workpackage?.swimlanes ?? []) {
      if (dependency.isMain) continue;

      try {
        const swimlane = allSwimlanes.find(swimlane => swimlane.id == dependency.swimlaneId);

        foreignSwimlanes.push(this.getSwimlaneDependencyItem(swimlane, dependency.workpackageSwimlaneId));
      } catch (e) {
        this.userNotification.notifyFailedToLoadDataAndLog(`general.errorFailedToLoadDataKeys.dependencies`, e);
        this.closeDialog();
      }
    }

    return { predecessors, successors, foreignSwimlanes };
  }

  private modelUpdated(id: string) {
    if (!this.updatedIds.contains(id)) this.updatedIds.push(id);
  }

  private async setCommentService(modelId: string) {
    if (modelId == this.commentsService.entityId) return;

    await this.commentsService.disposeObservables();

    this.commentsService = new CommentsService(this.commentsApiService, this.userNotification, modelId);

    if (modelId) {
      this.commentsService.updated.subscribe(() => {
        this.modelUpdated(modelId);
      });

      this.commentsService.removed.subscribe(() => {
        this.modelUpdated(modelId);
      });
    }
  }

  private async loadWorkpackage() {
    await this.setCommentService(this.modelId);

    await using(new BusyScope(this.commentsService), async _ => {
      if (this.modelId) {
        this.model = this.isTemplate
          ? await this.apiService.getWorkpackageTemplate(this.modelId)
          : await this.apiService.getWorkpackage(this.modelId);

        // this.updateFileNameFormat();
      } else if (!this.model) {
        this.model = this.isTemplate
          ? new LeanWorkpackageTemplateModel()
          : new LeanWorkpackageModel({
              startDate: moment().toLocalDate(),
              endDate: moment().add(1, 'week').toLocalDate(),
              // privilege: UIPrivilegeEnum.Write,
            });
      }

      if (!this.isTemplate) {
        const workpackage = this.model as LeanWorkpackageModel;

        this.commentsService.comments = workpackage.comments;
        this.stateOptions = getStatesFromModel(workpackage).map(state => ({
          iconClass: WorkpackageModel.getIconClass(state.stateType),
          state,
        }));
      }
    }).catch(e => {
      const errorKey = this.isTemplate ? 'workpackageTemplate' : 'workpackage';
      this.userNotification.notifyFailedToLoadDataAndLog(`general.errorFailedToLoadDataKeys.${errorKey}`, e);
      this.closeDialog();
    });

    if (!this.isTemplate) {
      const workpackage = this.model as LeanWorkpackageModel;

      if (!moment(workpackage.endDate).isSame(workpackage.startDate)) {
        workpackage.endDate = moment(workpackage.endDate).add(-1, 'ms').toLocalDate();
      }

      this.processChangeSets(workpackage.changeSets);

      const directory = workpackage.number.toString();
      await this.documentsService.navigateToPath(directory, this.resource);
    }
  }

  private async resetFormGroup() {
    const workpackage = this.model as LeanWorkpackageModel;

    let craft: CraftModel, organization: OrganizationModel;

    const craftId = workpackage?.craftId;
    craft = craftId ? this.crafts.filter(o => o.id === craftId)[0] : null;

    const organizationId = workpackage?.organizationId;
    organization = organizationId ? this.organizations.filter(o => o.id === organizationId)[0] : null;

    const mainSwimlaneId = workpackage.swimlanes?.find(swimlane => swimlane.isMain)?.swimlaneId;

    // this.updateFileNameFormat(room);

    // await this.setImageFormValues();

    this.f.name.setValue(workpackage?.name);
    this.f.state.setValue(workpackage?.currentState);
    this.f.craft.setValue(craft);
    this.f.organization.setValue(organization);
    // this.f.organization.patchValue(organization, { emitEvent: false });
    this.f.mainSwimlaneId.setValue(mainSwimlaneId);

    this.f.code.setValue(workpackage?.code);
    this.f.startdate.setValue(workpackage?.startDate);
    this.f.enddate.setValue(workpackage?.endDate);
    this.f.description.setValue(workpackage?.description);
    this.f.cost.setValue(workpackage?.cost);
    this.f.costFactor.setValue(workpackage?.costFactor);
    this.f.costValue.setValue(workpackage?.costValue);
    this.f.capacity.setValue(workpackage?.capacity);
    this.f.comment.setValue('');

    if (this.isTemplate) {
      const workpackageTemplate = this.model as LeanWorkpackageTemplateModel;

      const phaseId = workpackageTemplate?.phaseId;
      const phase = phaseId ? this.phases.filter(o => o.id === phaseId)[0] : null;

      this.f.phase.setValue(phase);
    } else {
      const { predecessors, successors, foreignSwimlanes } = await this.loadDependencies();

      this.f.predecessors.setValue(predecessors);
      this.f.successors.setValue(successors);
      this.f.foreignSwimlanes.setValue(foreignSwimlanes);

      this.f.constraints.setValue({
        labor: workpackage?.constraintLabor,
        information: workpackage?.constraintInformation,
        equipment: workpackage?.constraintEquipment,
        material: workpackage?.constraintMaterial,
        preliminary: workpackage?.constraintPreliminary,
        safety: workpackage?.constraintSafety,
        externalfactors: workpackage?.constraintExternalFactors,
        clarificationneeded: workpackage?.constraintClarificationNeeded,
      });

      this.f.disturbances.setValue({
        overestimationperformance: workpackage?.disturbanceOverestimationPerformance,
        reductionemployees: workpackage?.disturbanceReductionEmployees,
        incorrectinfo: workpackage?.disturbanceIncorrectInfo,
        delayedmaterial: workpackage?.disturbanceDelayedMaterial,
        missingpreliminary: workpackage?.disturbanceMissingPreliminary,
        interfaceworkpackages: workpackage?.disturbanceInterfaceWorkpackages,
        externalconditions: workpackage?.disturbanceExternalConditions,
        prioritychange: workpackage?.disturbancePriorityChange,
        changeworkscope: workpackage?.disturbanceChangeWorkScope,
        remainingwork: workpackage?.disturbanceRemainingWork,
        other: workpackage?.disturbanceOther,
        delayedequipment: workpackage?.disturbanceDelayedEquipment,
      });

      this.updateFormState();
    }

    if (this.isNew) this.form.markAsDirty();
    this.form.markAllAsTouched();
  }

  private updateFormState() {
    if (!this.isTemplate) return;

    const workpackage = this.model as LeanWorkpackageModel;
    const workpackagePermissions = workpackage?.allowedPermissions ?? [];
    if (workpackagePermissions.includes(LeanStatePermission.Lean_edit)) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
  }

  private craftChanged(craft: CraftModel) {
    this.organizations = craft
      ? this.allOrganizations.filter(o => o.crafts.some(c => c.id == craft.id))
      : this.allOrganizations;

    const currentOrganization = this.f.organization.value;
    if (this.organizations.length == 1) {
      this.f.organization.setValue(this.organizations[0]);
    } else if (currentOrganization && this.organizations.all(o => o.id != currentOrganization.id)) {
      this.f.organization.setValue(null);
    }
  }

  private mainSwimlaneChanged(id: string) {
    const foreignSwimlanes = this.f.foreignSwimlanes.value;
    if (foreignSwimlanes?.length) {
      const foreignSwimlane = foreignSwimlanes.find(s => s.entityId == id);
      if (foreignSwimlane) foreignSwimlanes.remove(foreignSwimlane);
    }
  }

  private setSelectedTab(tabType: EntityDetailDialogTabType) {
    const getTabIndex = (tab: MatTab) => Math.max(this.tabGroup._tabs.toArray().indexOf(tab), 0);

    let tabIndex = 0;
    switch (tabType) {
      case EntityDetailDialogTabType.details:
        tabIndex = getTabIndex(this.detailsTab);
        break;
      case EntityDetailDialogTabType.history:
        tabIndex = getTabIndex(this.historyTab);
        break;
      // case EntityDetailDialogTabType.files:
      //   tabIndex = getTabIndex(this.fileTab);
      //   break;
    }

    this.selectedTabIndex = tabIndex;
  }

  private processChangeSets(changeSets: ChangeSetModel[]) {
    this.stateChanges = [];
    this.changeSets = [];

    if (!changeSets?.length) return;

    for (const changeSet of changeSets.reverse()) {
      const changeSetCopy = new ChangeSetModel({ ...changeSet });
      changeSetCopy.changes = [];
      for (const c of changeSet.changes) {
        const change = new ChangeModel({ ...c });
        const fieldName = change.fieldName.toLowerCase();
        // fill state changes
        // todo + other entities
        if (fieldName === nameof<LeanWorkpackageModel>('stateId').toLowerCase()) {
          const state = change.newValue;
          const icon = null; //fix
          this.stateChanges.push({
            username: changeSet.createdBy,
            date: changeSet.createdOn,
            iconClass: icon,
            labelKey: state,
            type: icon ? HistoryStateTemplateType.icon : HistoryStateTemplateType.default,
          });
        } else if (fieldName === 'state') {
          const state =
            change.dataType == ChangeContentType.Object
              ? Object.values(WorkpackageState)[change.newValue?.StateType]
              : WorkpackageState[change.newValue];
          const icon = WorkpackageModel.getIconClass(state);
          this.stateChanges.push({
            username: changeSet.createdBy,
            date: changeSet.createdOn,
            iconClass: icon,
            labelKey:
              change.dataType == ChangeContentType.Object
                ? change.newValue?.Title
                : `${translationFieldNames[fieldName]}.${state}`,
            type: icon ? HistoryStateTemplateType.icon : HistoryStateTemplateType.default,
          });
        }

        // custom format
        if (change.fieldName.toLowerCase() === 'state' && change.dataType === ChangeContentType.Object) {
          HistoryComponent.setType(change, HistoryChangeType.state_translate);
        } else if (boolFieldNames.some(f => f.toLowerCase() === fieldName)) {
          HistoryComponent.setType(change, HistoryChangeType.bool);
        } else if (dateFieldNames.some(f => f.toLowerCase() === fieldName)) {
          HistoryComponent.setType(change, HistoryChangeType.date);
        } else if (Object.keys(translationFieldNames).some(f => f.toLowerCase() === fieldName)) {
          change.oldValue = change.oldValue ? `${translationFieldNames[fieldName]}.${change.oldValue}` : null;
          change.newValue = change.newValue ? `${translationFieldNames[fieldName]}.${change.newValue}` : null;
          HistoryComponent.setType(change, HistoryChangeType.translate);
        }

        change.fieldName = `workpackages.fieldName.${fieldName}`;
        changeSetCopy.changes.push(change);
      }
      this.changeSets.push(changeSetCopy);
    }
  }
}
