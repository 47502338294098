import { Directive } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormStateComponent } from './form-state-component';

@Directive()
export abstract class BaseTasklikeDialogComponent<T> extends FormStateComponent {
  constructor(protected dialog: MatDialog, protected dialogRef: MatDialogRef<T>) {
    super(dialog);

    this.initKeybindings();
  }

  private initKeybindings() {
    this.subscribe(this.dialogRef.keydownEvents(), event => {
      if (event.key === 'Escape') {
        this.cancel();
      } else if (event.ctrlKey && event.key === 's' && this.isValid && this.isDirty) {
        event.preventDefault();
        this.save(false);
      }
    });
  }

  abstract cancel(): void;
  abstract save(closeAfterSave: boolean): void;
}
